import React from "react";
import "./Section2.css";

function Section2() {
  return (
    <div className="section2-body">
      <div className="row">
        <div className="col-md-1"></div>

        {/* Content goes in here */}
        <div className="col-md-10 section2-container">
          <div className="row margin-top-small">
            <div className="col-md-2"></div>
            <div className="col-md-8">
              <h2 className="section2-title">FIND STRUGGLING STUDENTS EARLY AND DIRECT THEM TO THE RIGHT RESOURCES</h2>
            </div>
          </div>
          <div className="col-md-2"></div>

          <div className="row">
            <div className="col-md-2"></div>
            <div className="col-md-8 margin-top-small">
              <p className="section2-text h4">
              We offer the first LMS and Video Conferencing tools compatible platform that reports and alerts your program of students at-risk of dropping out through reliable analytics and actions to improve student teams, offline and online classrooms behavior 
              </p>
            </div>
            <div className="col-md-2"></div>
          </div>

          <div className="row margin-top-small">
            <div className="col-md-12">
              <div className="row">
                <div className="col-md-1"></div>

                <div className="col-md-2">
                  <div className="icon-container">
                    <i
                      className="fas fa-exclamation-triangle  gradient-icon"
                      alt="clock icon"
                    ></i>
                  </div>
                  <div className="row">
                    <div className="col-md-12 icon-text">
                      <p className="s2-text">
                      Early Warning Alerts and Reporting
                      </p>
                    </div>
                  </div>
                </div>

                <div className="col-md-2">
                  <div className="icon-container">
                    <i
                      className="fas fa-video gradient-icon"
                      alt="video icon"
                    ></i>
                  </div>
                  <div className="row">
                    <div className="col-md-12 icon-text">
                      <p className="s2-text">Zoom, G Suite & LMS Integration</p>
                    </div>
                  </div>
                </div>
                
              

                <div className="col-md-2">
                  <div className="icon-container">
                    <i
                      className="fas fa-calendar gradient-icon"
                      alt="clipboard icon"
                    ></i>
                  </div>
                  <div className="row">
                    <div className="col-md-12 icon-text">
                      <p className="s2-text">
                      Automated team scheduling
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-2">
                  <div className="icon-container">
                    <i
                      className="fas fa-users-cog gradient-icon"
                      alt="clipboard icon"
                    ></i>
                  </div>
                  <div className="row">
                    <div className="col-md-12 icon-text">
                      <p className="s2-text">
                      Create and maintain student teams
                      </p>
                    </div>
                  </div>
                </div>

                <div className="col-md-2">
                  <div className="icon-container">
                    <i
                      className="fas fa-chart-line gradient-icon"
                      alt="chart line icon"
                    ></i>
                  </div>
                  <div className="row">
                    <div className="col-md-12 icon-text">
                      <p className="s2-text">DFW Analytics & Predictions</p>
                    </div>
                  </div>
                </div>

                <div className="col-md-1"></div>
              </div>
            </div>
          </div>

          {/* End of content */}
        </div>

        <div className="col-md-1"></div>
      </div>
    </div>
  );
}

export default Section2;
