import React, { useState, useEffect, Fragment } from 'react';

import Table from '../components/Table/NeoboardTable';

// Link
import { Link } from 'react-router-dom';

// For the modal
import Modal from '../components/UploadCompleteModal';

// Dummy Data for the course info
const courseData = {
  name: 'Astronomy 101',
  tutor: 'Marcus Azure',
  email: 'mazure@neocollege.edu',
};

const CourseTab = ({ data, uploadState }) => {
  const [tableData, setTableData] = useState(data);

  const [courseInfo, setCourseInfo] = useState(courseData);
  const [students] = useState(data.length - 1);

  /*   useEffect(() => {
    let course = {
      name: data[0].course_name,
      tutor: data[0].tutor_name,
      email: data[0].tutor_email,
    };
    setCourseInfo(course);
  }, [tableRows]); */

  const [uploaded, setUploaded] = useState(uploadState);

  // testing set to false
  const [modalVisible, setModalVisible] = useState(false);

  const modalState = () => {
    setModalVisible(false);
  };

  return (
    <div className='dashboard transparent-bg'>
      {modalVisible ? <Modal func={modalState} /> : null}

      <div className='welcome-box top-border width-full'>
        <div className='heading'>{courseInfo.name}</div>
        <div className='description-instruction'>
          <span className='green-bold'>{courseInfo.tutor}</span> (
          {courseInfo.email}) has been assigned as the tutor for the course by
          the learning center
        </div>
        <div onClick={''} className='dashboard-button small-margin-top'>
          Request Change
        </div>

        {uploaded ? (
          <Fragment>
            <div className='description margin-top'>
              Your courses that you have input are shown below:
            </div>

            <div className='description-instruction font18'>
              {students} Students
            </div>

            {/* Table */}
            <div className='neoboard-table-container'>
              <Table
                headers={['Instructor', 'Email Address', 'Course Name']}
                items={tableData}
                addButton={'+ Add Course'}
                delButton={true}
                bgColor={'bg-color-blue'}
                onTableUpdate={(event) => {
                  let dataCopy = tableData; // Copy of the table data

                  // item is the name of the variable for an item
                  console.log(event.target);
                  let item = {
                    id: event.target.id,
                    name: event.target.name,
                    value: event.target.value,
                  };
                  let rows = dataCopy.slice();
                  let newRows = rows.map((row) => {
                    // for each key in row
                    for (let key in row) {
                      // if key is the same as item name AND id is the same as item id, then set the key to the item value
                      if (key === item.name && row.id === item.id) {
                        row[key] = item.value;
                      }
                    }
                    return row;
                  });
                  setTableData([...newRows]);
                  console.log(tableData);
                }}
                onRowAdd={(event) => {
                  let dataCopy = tableData; // Copy of the table data
                  let id = (
                    new Date() + Math.floor(Math.random() * 999999)
                  ).toString(36); // Generate random id to associate to each item (this should be unnessecary and removable once there is a database that automatically assigns unique ID's)

                  // Array of the keys of each key/value pair we want for each row
                  // Dynamically based off the data we put into the table
                  let itemKeys = Object.keys(dataCopy[0]);

                  // Empty container that will become the object a new row is based on
                  let itemToAdd = {};

                  // For each key (in a key/value pair) for a rows data...
                  itemKeys.map((key, index) => {
                    // For the first key which is always id, set it to a random id
                    if (index === 0) {
                      itemToAdd[`${key}`] = id;
                    } else {
                      // Else, dynamically generate a key with an empty value, as a new row will not have values input yet
                      itemToAdd[`${key}`] = '';
                    }
                  });
                  // Push the object of keys with empty values to our data copy
                  dataCopy.push(itemToAdd);
                  // Set state so that it updates on the front end with an empty row
                  setTableData([...dataCopy]);
                }}
                onRowDel={(event) => {
                  let dataCopy = tableData; // Copy of the table data
                  let rowDataCopy = tableData[0]; // Copy of the table data

                  let index = dataCopy
                    .map((item) => {
                      return item.id;
                    })
                    .indexOf(event.target.name); // Index of the row to delete

                  console.log(rowDataCopy);
                  dataCopy.splice(index, 1); // Splice it from the array of table rows

                  // If array would not exist, is not an array, or is empty
                  if (!Array.isArray(dataCopy) || !dataCopy.length) {
                    ////////////////////////////////////////////////////////////////////
                    console.log('Non array case');
                    console.log(rowDataCopy);

                    let id = (
                      new Date() + Math.floor(Math.random() * 999999)
                    ).toString(36); // Generate random id to associate to each item (this should be unnessecary and removable once there is a database that automatically assigns unique ID's)

                    // Array of the keys of each key/value pair we want for each row
                    // Dynamically based off the data we put into the table
                    let itemKeys = Object.keys(rowDataCopy);
                    console.log(itemKeys);

                    // Empty container that will become the object a new row is based on
                    let itemToAdd = {};

                    // For each key (in a key/value pair) for a rows data...
                    itemKeys.map((key, index) => {
                      // For the first key which is always id, set it to a random id
                      if (index === 0) {
                        itemToAdd[`${key}`] = id;
                      } else {
                        // Else, dynamically generate a key with an empty value, as a new row will not have values input yet
                        itemToAdd[`${key}`] = '';
                      }
                    });
                    console.log(itemToAdd);
                    // Push the object of keys with empty values to our data copy
                    /*                     rowDataCopy.push(itemToAdd); */
                    // Set state so that it updates on the front end with an empty row
                    setTableData([itemToAdd]);

                    ////////////////////////////////////////////////////////////////////
                  } else {
                    setTableData([...dataCopy]); // Set the state to the new current table
                  }
                }}
              />
            </div>
            {/*  */}

            <div
              onClick={''}
              className='dashboard-button-update double-margin-buffer'
            >
              Submit
            </div>
          </Fragment>
        ) : (
          <Fragment>
            <div className='description margin-top'>
              Upload roster through CSV or via Canvas below
            </div>
            <Link
              to='/canvas/permission'
              className='dashboard-button-update double-margin-buffer min-width-200'
            >
              Update via Canvas
            </Link>
          </Fragment>
        )}
        <div
          onClick={''}
          className='dashboard-button-outline double-margin-buffer'
        >
          Replace
        </div>
      </div>
    </div>
  );
};

export default CourseTab;
