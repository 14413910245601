import React, { useState, useEffect } from "react";
import swal from "sweetalert";

function StuChild(props) {
  let [stuChildCounter, setStuChildCounter] = useState(0);

  useEffect(() => {
    setStuChildCounter(stuChildCounter);
  }, []);

  function checked(e) {
    if (e.target.checked === true) {
      addSelected(e.target.name);
      setStuChildCounter(stuChildCounter + 1);
    }
    if (e.target.checked === false) {
      removeSelected(e.target.name);
      setStuChildCounter(stuChildCounter - 1);
    }
  }

  function validate(e) {
    let counter = 0;

    let chosenTimes = [];
    for (let i = 0; i < props.stuParentData.length; i++) {
      if (props.stuParentData[i].selected === true) {
        counter++;
        chosenTimes.push(props.stuParentData[i]);
      }
    }
    if (counter > 3) {
      swal(
        `Too many sessions selected for: \n${props.stuParentData[0].classTitle}`,
        "Please select only 3 to prevent an error",
        "warning"
      );
    }

    if (counter === 3) {
      //send data to parent'
      console.log(counter);
      handleChange(chosenTimes);
    }
  }

  function addSelected(e) {
    props.stuParentData[e].selected = true;
    validate(e);
  }

  function removeSelected(e) {
    props.stuParentData[e].selected = false;
    validate(e);
  }

  function handleChange(chosenTimes) {
    // Here, we invoke the callback with the new value

    props.onChange(chosenTimes);
  }

  return (
    <div className="row">
      <div className="col-md-2"></div>
      <div className="col-md-8">
        <div className="poll-card padding">
          <div className="row">
            <div className="col-md-2">
              <div className="icon-circle">
                <i className="fas fa-user session-icon"></i>
              </div>
            </div>
            <div className="col-md-8">
              <div className="session-title">
                <h4>Available Sessions for</h4>
                <h4>{props.stuParentData[0].classTitle}</h4>
              </div>
            </div>
            <div className="col-md-2"></div>
          </div>

          <br />
          <div className="row">
            <div className="col-md-12">
              {/* DESKTOP VIEW */}

              <table className="session__table session-desktop">
                <thead className="session__table-header">
                  <tr>
                    <th></th>
                    <th>Available Time</th>
                    <th>SI Leader</th>
                    <th>Spots Available</th>
                  </tr>
                </thead>

                <tbody>
                  {props.stuParentData.map((data, i) =>
                    props.stuParentData[i].disabled === true ? (
                      <tr
                        key={`${data.id}`}
                        className="session__table-data disabled"
                      >
                        <th scope="row">
                          <input
                            disabled
                            type="checkbox"
                            name={data.id}
                            onChange={(event) => {
                              checked(event);
                            }}
                          />
                        </th>
                        <td>{data.availableTime}</td>
                        <td>{data.siLeader}</td>
                        <td>{data.spotsAvailable}</td>
                      </tr>
                    ) : (
                      <tr key={`${data.id}`} className="session__table-data">
                        <th scope="row">
                          <input
                            type="checkbox"
                            name={data.id}
                            onChange={(event) => {
                              checked(event);
                            }}
                          />
                        </th>
                        <td>{data.availableTime}</td>
                        <td>{data.siLeader}</td>
                        <td>{data.spotsAvailable}</td>
                      </tr>
                    )
                  )}
                </tbody>
              </table>

              {/* END DESKTOP VIEW */}

              {/* MOBILE VIEW */}

              <div className="session__table session-mobile">
                {props.stuParentData.map((data, i) =>
                  props.stuParentData[i].disabled === true ? (
                    <div
                      className="mobile-table disabled"
                      key={`${data.id}_${data.studentAvailability}_mobile`}
                    >
                      <div className="table-left">
                        <input
                          disabled
                          type="checkbox"
                          className="table-checkbox"
                          name={data.id}
                          onChange={(event) => {
                            checked(event);
                          }}
                        />
                      </div>
                      <div className="table-right">
                        <div>
                          {" "}
                          <strong>Available Time: </strong> <br />
                          {data.availableTime}
                        </div>
                        <div>
                          {" "}
                          <strong>SI Leader: </strong> <br /> {data.siLeader}
                        </div>
                        <div>
                          {" "}
                          <strong>Spots Available: </strong> <br />{" "}
                          {data.spotsAvailable}
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div
                      className="mobile-table"
                      key={`${data.id}_${data.studentAvailability}_mobile`}
                    >
                      <div className="table-left">
                        <input
                          type="checkbox"
                          className="table-checkbox"
                          name={data.id}
                          onChange={(event) => {
                            checked(event);
                          }}
                        />
                      </div>
                      <div className="table-right">
                        <div>
                          {" "}
                          <strong>Available Time: </strong> <br />
                          {data.availableTime}
                        </div>
                        <div>
                          {" "}
                          <strong>SI Leader: </strong> <br /> {data.siLeader}
                        </div>
                        <div>
                          {" "}
                          <strong>Spots Available: </strong> <br />{" "}
                          {data.spotsAvailable}
                        </div>
                      </div>
                    </div>
                  )
                )}
              </div>
              {/* END MOBILE VIEW  */}
              <br />
              <div className="row">
                <div className="col-md-4"></div>
                <div className="col-md-4">
                  {" "}
                  <div className="session-text h5">
                    {stuChildCounter === 3 ? (
                      <i className="fas fa-check-circle session-checks"></i>
                    ) : (
                      <i className="fas fa-times-circle session-x"></i>
                    )}
                    Sessions selected: {stuChildCounter}/3
                  </div>
                </div>
                <div className="col-md-4"></div>
              </div>
              <div className="row">
                <div className="col-md-2"></div>
                <div className="col-md-8">
                  <div className="session-text h6">
                    * Sessions in grey cannot be selected, either because you
                    have an event scheduled at the time, the session is full, or
                    outside your awake hours
                  </div>
                </div>
                <div className="col-md-2"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-2"></div>
    </div>
  );
}

export default StuChild;
