import React from "react";
import { TagList } from "./TagList";
import "./tagStyle.css";
import { WithContext as ReactTags } from "react-tag-input";

const suggestions = TagList.map((tag) => {
  return {
    id: tag,
    text: tag,
  };
});

// const KeyCodes = {
//   comma: 188,
//   enter: 13,
// };

const delimiters = [];

export default class Tags2 extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      tags: [
        // { id: "Thailand", text: "Thailand" },
        // { id: "India", text: "India" },
      ],
      suggestions: suggestions,
    };
    this.handleDelete = this.handleDelete.bind(this);
    this.handleAddition = this.handleAddition.bind(this);
    this.handleDrag = this.handleDrag.bind(this);
    this.handleTagClick = this.handleTagClick.bind(this);
  }

  sendData = () => {
      console.log(this.state.tags)
      
    this.props.parentCallback(this.state.tags);
    
    
    
  };

  
  
  handleDelete(i) {
    const { tags } = this.state;
    this.setState({
      tags: tags.filter((tag, index) => index !== i),
    });
    this.sendData(this.state.tags);
  }

  handleAddition(tag) {
    for (let i = 0; i < this.state.tags.length; i++) {
      if (tag.text === this.state.tags[i].text) {
        console.log("Duplicate Entry");
        return;
      }
    }

    this.setState((state) => ({ tags: [...state.tags, tag] }), ()=> this.sendData(this.state.tags))
    // this.sendData(this.state.tags);

    
    
    
  }

  handleDrag(tag, currPos, newPos) {
    const tags = [...this.state.tags];
    const newTags = tags.slice();

    newTags.splice(currPos, 1);
    newTags.splice(newPos, 0, tag);

    // re-render
    this.setState({ tags: newTags });
  }

  handleTagClick(index) {
    console.log("The tag at index " + index + " was clicked");
    this.sendData(this.state.tags);
  }

  render() {
    const { tags, suggestions } = this.state;
    return (
       
      <div className="tagComponent">
        <ReactTags
          tags={tags}
          suggestions={suggestions}
          delimiters={delimiters}
          handleDelete={this.handleDelete}
          handleAddition={this.handleAddition}
          handleDrag={this.handleDrag}
          handleTagClick={this.handleTagClick}
          minQueryLength={1}
          allowDragDrop={false}
          allowDeleteFromEmptyInput={false}
          inputFieldPosition="top"
          allowUnique={false}
          autocomplete={true}
          renderSuggestion={({ text }) => (
            <div style={{ fontFamily: "Quicksand" }}>{text}</div>
          )}
          
        />
      </div>
      
    );
  }
}
