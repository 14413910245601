import React from "react";
import "../StudentPollSI.css";

export default class Personality extends React.Component {
  state = {
    personality: "3",
    personalityText: "Neutral",
    dangerBG: "",
    dangerIcon: "",
    dangerSlider: "",
    dangerTrack: "",
    greenSlider: "",
    greenBG: "",
    greenIcon: "",
    greenTrack: "",
    logo: "fa-meh"
  };

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });

    //START -> Personality , Agree/Disagee Type Text
    if (event.target.name === "personality" && event.target.value === "1") {
      this.setState({ personalityText: "Strongly Disagree" });
      this.setState({ dangerBG: "dangerBG" });
      this.setState({ dangerIcon: "dangerIcon" });
      this.setState({ dangerSlider: "dangerSlider" });
      this.setState({ dangerTrack: "dangerTrack" });
      this.setState({ logo: "fa-frown" });
    }
    if (event.target.name === "personality" && event.target.value === "2") {
      this.setState({ personalityText: "Disagree" });
      this.setState({ dangerBG: "" });
      this.setState({ dangerIcon: "" });
      this.setState({ dangerSlider: "" });
      this.setState({ dangerTrack: "" });
      this.setState({ logo: "fa-frown" });
    }
    if (event.target.name === "personality" && event.target.value === "3") {
      this.setState({ personalityText: "Neutral" });
      this.setState({ logo: "fa-meh" });
    }
    if (event.target.name === "personality" && event.target.value === "4") {
      this.setState({ personalityText: "Agree" });
      this.setState({ greenBG: "" });
      this.setState({ greenIcon: "" });
      this.setState({ greenSlider: "" });
      this.setState({ greenTrack: "" });
      this.setState({ logo: "fa-smile" });
    }
    if (event.target.name === "personality" && event.target.value === "5") {
      this.setState({ personalityText: "Strongly Agree" });
      this.setState({ greenBG: "greenBG" });
      this.setState({ greenIcon: "greenIcon" });
      this.setState({ greenSlider: "greenSlider" });
      this.setState({ greenTrack: "greenTrack" });
      this.setState({ logo: "fa-smile" });
    }
    //END -> Personality , Agree/Disagee Type Text
    this.sendData(this.state.personality);
  };

  sendData = () => {
    let newInt = parseInt(this.state.personality);
    this.props.parentCallback(newInt);
  };

  render() {
    return (
      <div className="si-sliders">
        {/* Start Range Slider */}
        <div className="row">
          <div className="col-md-1"></div>
          <div className="col-md-10">
            <div className="row">
              <div className="col-md-12 mb3">
                <div
                  className={`poll-icon-container ${this.state.dangerBG} ${this.state.greenBG}`}
                >
                  <i
                    className={`icon-img far ${this.state.logo}  ${this.state.dangerIcon} ${this.state.greenIcon}`}
                  ></i>
                </div>

                <div className="poll-title-container">
                  <p className="si-slider-title h6">
                    My SI had a positive personality
                  </p>
                </div>
              </div>
            </div>

            <div
              className={`${this.state.dangerSlider} ${this.state.greenSlider} ${this.state.dangerTrack} ${this.state.greenTrack} `}
            >
              <input
                min="1"
                max="5"
                step="1"
                name="personality"
                type="range"
                onChange={this.handleChange}
                value={this.props.personalityRating}
                id="personality"
              />
            </div>

            <label htmlFor="personality">{this.state.personality}</label>
            <br />
            <div className="importance-label">{this.state.personalityText}</div>
            <br />
          </div>
          <div className="col-md-1"></div>
        </div>
        {/* End Range Slider */}
      </div>
    );
  }
}
