import React from 'react';

function Search() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='34'
      height='35'
      viewBox='0 0 34 35'
      className='navbar__icon icon-search'
    >
      <path d='M23.426 14.857c0 4.962-4.038 9-9 9s-9-4.038-9-9 4.038-9 9-9 9 4.038 9 9zm10.286 16.714c0-.683-.282-1.346-.744-1.808l-6.89-6.89a14.11 14.11 0 002.49-8.016c0-7.815-6.327-14.143-14.142-14.143C6.611.714.283 7.042.283 14.857S6.611 29 14.426 29a14.11 14.11 0 008.016-2.491l6.89 6.87a2.521 2.521 0 001.808.764 2.59 2.59 0 002.572-2.572z'></path>
      <path d='M23.426 14.857c0 4.962-4.038 9-9 9s-9-4.038-9-9 4.038-9 9-9 9 4.038 9 9zm10.286 16.714c0-.683-.282-1.346-.744-1.808l-6.89-6.89a14.11 14.11 0 002.49-8.016c0-7.815-6.327-14.143-14.142-14.143C6.611.714.283 7.042.283 14.857S6.611 29 14.426 29a14.11 14.11 0 008.016-2.491l6.89 6.87a2.521 2.521 0 001.808.764 2.59 2.59 0 002.572-2.572z'></path>
    </svg>
  );
}

export default Search;
