import React, { Fragment, useState, useEffect } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import LinkPage from "./pages/CanvasLinkPage";
import PermissionsPage from "./pages/CanvasPermissionsPage";
import CompletePage from "./pages/CanvasCompletePage";
import AdminDashboard from "./pages/AdminDashboard";
import UploadInstructorDashboard from "./pages/InstructorDashboardUpload";
import SIRecommendations from "./pages/SIRecommendation";

// Dummy Data

// For admin dashboard component (courses)
import courseData from "../../dummyData/adminCourseData.json";
// For admin dashboard component (session)
import sessionCourseData from "../../dummyData/adminSessionClasses.json";
import sessionData1 from "../../dummyData/adminSessionDataDay1.json";
import sessionData2 from "../../dummyData/adminSessionDataDay2.json";
import sessionData3 from "../../dummyData/adminSessionDataDay3.json";
import sessionData4 from "../../dummyData/adminSessionDataDay4.json";
// For admin dashboard component (settings)
import settingsData from "../../dummyData/adminSettingsData.json";
import transferData from "../../dummyData/adminSettingsTransferData.json";

// For dashboard to upload potential roster
import astronomy101 from "../../dummyData/astronomy101.json";
import astronomy135 from "../../dummyData/astronomy135.json";
import recommendations from "../../dummyData/siRecommendations.json";

const Canvas = () => {
  const [canvasLogin, setCanvasLogin] = useState(null);
  const [dimensions, setDimensions] = React.useState({
    height: window.innerHeight,
    width: window.innerWidth,
  });

  React.useEffect(() => {
    function handleResize() {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth,
      });
    }
    window.addEventListener("resize", handleResize);
    return (_) => {
      window.removeEventListener("resize", handleResize);
    };
  });

  return (
    <div className="test canvas blur-box">
      <Switch>
        <Route exact path="/canvas" component={LinkPage} />
        <Route
          exact
          path="/canvas/permission"
          render={(props) => <PermissionsPage {...props} />}
        />
        <Route exact path="/canvas/complete" component={CompletePage} />
        <Route
          exact
          path="/canvas/dashboard"
          render={(props) => (
            <AdminDashboard
              {...props}
              courseData={courseData}
              sessionCourseData={sessionCourseData}
              sessionData1={sessionData1}
              sessionData2={sessionData2}
              sessionData3={sessionData3}
              sessionData4={sessionData4}
              settingsData={settingsData}
              transferData={transferData}
            />
          )}
        />
        <Route
          exact
          path="/canvas/dashboard/preupload"
          render={(props) => (
            <UploadInstructorDashboard
              {...props}
              courseData={astronomy101}
              recommendationData={recommendations}
            />
          )}
        />
        <Route
          exact
          path="/canvas/dashboard/uploaded"
          render={(props) => (
            <UploadInstructorDashboard
              {...props}
              uploadState={true}
              courseData={astronomy101}
              recommendationData={recommendations}
            />
          )}
        />
        <Route
          exact
          path="/canvas/recommendations"
          render={(props) => (
            <SIRecommendations {...props} students={recommendations} />
          )}
        />
      </Switch>
    </div>
  );
};

export default Canvas;
