import React from "react";
import "./PollwithCalendar.css";
import {
  Form,
  Popover,
  OverlayTrigger,
  Button,
  ButtonGroup,
} from "react-bootstrap";
import swal from "sweetalert";

import Double from "./PollComponents/Double";
import Map from "./PollComponents/Map";
import Map2 from "./PollComponents/Map2";
import Clock from "./PollComponents/Clock";
import Clock2 from "./PollComponents/Clock2";

import HeaderWithSubmit from "./PollComponents/HeaderWithSubmit";

export default class PollwithCalendar extends React.Component {
  state = {
    armedForces: false,
    workAtNight: false,
    parent: false,
    library: false,
    deptRoomsLabs: false,
    coffeeLounge: false,
    home: false,
    erraticSleep: false,
    useLocation: false,
    blur: false,
    googleCalendar: false,
    appleCalendar: false,
    microsoftOutlook: false,
    myStudyLife: false,
    studyWithPeopleKnow: "3",
    studyWithPeoplePatient: "3",
    studyWithPeopleUnderstand: "3",
    studyWithPeopleOrganized: "3",
    studyWithPeopleInterests: "3",
    studyWithPeoplePunctual: "3",
    importanceKnow: "Important",
    importancePatient: "Important",
    importanceOrganized: "Important",
    importanceInterests: "Important",
    importanceUnderstand: "Important",
    importancePunctual: "Important",
    map1: "none",
    map2: "none",
    clock1: "initial",
    clock2: "none",
    doNotDisturbStartTime: { value: "12:00 AM", writable: true },
    doNotDisturbEndTime: { value: "8:00AM", writable: true },
    zipObj: "",
    locObj: "",
    minStudents: { value: "2", writable: true },
    maxStudents: { value: "6", writable: true },
    hideCards: "initial",
    pronoun: "",
    pronounNone: false,
    pronounShe: false,
    pronounHe: false,
    pronounThey: false,
    pronounZe: false,
    circumstancesText: "",
    disability: false,
    academicPrograms_biology: true,
    academicPrograms_calculus: false,
    academicPrograms_britishLiterature: false,
    academicPrograms_business105: false,
  };

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  handleChange = (event) => {
    const isCheckbox = event.target.type === "checkbox";

    this.setState({
      [event.target.name]: isCheckbox
        ? event.target.checked
        : event.target.value,
    });

    //RANGE SLIDERS
    if (
      event.target.name === "studyWithPeopleKnow" ||
      "studyWithPeoplePatient" ||
      "studyWithPeopleUnderstand" ||
      "studyWithPeopleOrganized" ||
      "studyWithPeopleInterests" ||
      "studyWithPeoplePunctual"
    ) {
      if (
        event.target.name === "studyWithPeopleKnow" &&
        event.target.value === "1"
      ) {
        this.setState({ importanceKnow: "Not Important" });
      }
      if (
        event.target.name === "studyWithPeopleKnow" &&
        event.target.value === "2"
      ) {
        this.setState({ importanceKnow: "Slightly Important" });
      }
      if (
        event.target.name === "studyWithPeopleKnow" &&
        event.target.value === "3"
      ) {
        this.setState({ importanceKnow: "Important" });
      }
      if (
        event.target.name === "studyWithPeopleKnow" &&
        event.target.value === "4"
      ) {
        this.setState({ importanceKnow: "Very Important" });
      }
      if (
        event.target.name === "studyWithPeopleKnow" &&
        event.target.value === "5"
      ) {
        this.setState({ importanceKnow: "Essential" });
      }

      //PATIENT
      if (
        event.target.name === "studyWithPeoplePatient" &&
        event.target.value === "1"
      ) {
        this.setState({ importancePatient: "Not Important" });
      }
      if (
        event.target.name === "studyWithPeoplePatient" &&
        event.target.value === "2"
      ) {
        this.setState({ importancePatient: "Slightly Important" });
      }
      if (
        event.target.name === "studyWithPeoplePatient" &&
        event.target.value === "3"
      ) {
        this.setState({ importancePatient: "Important" });
      }
      if (
        event.target.name === "studyWithPeoplePatient" &&
        event.target.value === "4"
      ) {
        this.setState({ importancePatient: "Very Important" });
      }
      if (
        event.target.name === "studyWithPeoplePatient" &&
        event.target.value === "5"
      ) {
        this.setState({ importancePatient: "Essential" });
      }

      //UNDERSTAND
      if (
        event.target.name === "studyWithPeopleUnderstand" &&
        event.target.value === "1"
      ) {
        this.setState({ importanceUnderstand: "Not Important" });
      }
      if (
        event.target.name === "studyWithPeopleUnderstand" &&
        event.target.value === "2"
      ) {
        this.setState({ importanceUnderstand: "Slightly Important" });
      }
      if (
        event.target.name === "studyWithPeopleUnderstand" &&
        event.target.value === "3"
      ) {
        this.setState({ importanceUnderstand: "Important" });
      }
      if (
        event.target.name === "studyWithPeopleUnderstand" &&
        event.target.value === "4"
      ) {
        this.setState({ importanceUnderstand: "Very Important" });
      }
      if (
        event.target.name === "studyWithPeopleUnderstand" &&
        event.target.value === "5"
      ) {
        this.setState({ importanceUnderstand: "Essential" });
      }

      //ORGANIZED

      if (
        event.target.name === "studyWithPeopleOrganized" &&
        event.target.value === "1"
      ) {
        this.setState({ importanceOrganized: "Not Important" });
      }
      if (
        event.target.name === "studyWithPeopleOrganized" &&
        event.target.value === "2"
      ) {
        this.setState({ importanceOrganized: "Slightly Important" });
      }
      if (
        event.target.name === "studyWithPeopleOrganized" &&
        event.target.value === "3"
      ) {
        this.setState({ importanceOrganized: "Important" });
      }
      if (
        event.target.name === "studyWithPeopleOrganized" &&
        event.target.value === "4"
      ) {
        this.setState({ importanceOrganized: "Very Important" });
      }
      if (
        event.target.name === "studyWithPeopleOrganized" &&
        event.target.value === "5"
      ) {
        this.setState({ importanceOrganized: "Essential" });
      }

      //INTERESTS

      if (
        event.target.name === "studyWithPeopleInterests" &&
        event.target.value === "1"
      ) {
        this.setState({ importanceInterests: "Not Important" });
      }
      if (
        event.target.name === "studyWithPeopleInterests" &&
        event.target.value === "2"
      ) {
        this.setState({ importanceInterests: "Slightly Important" });
      }
      if (
        event.target.name === "studyWithPeopleInterests" &&
        event.target.value === "3"
      ) {
        this.setState({ importanceInterests: "Important" });
      }
      if (
        event.target.name === "studyWithPeopleInterests" &&
        event.target.value === "4"
      ) {
        this.setState({ importanceInterests: "Very Important" });
      }
      if (
        event.target.name === "studyWithPeopleInterests" &&
        event.target.value === "5"
      ) {
        this.setState({ importanceInterests: "Essential" });
      }
      //PUNCTUAL

      if (
        event.target.name === "studyWithPeoplePunctual" &&
        event.target.value === "1"
      ) {
        this.setState({ importancePunctual: "Not Important" });
      }
      if (
        event.target.name === "studyWithPeoplePunctual" &&
        event.target.value === "2"
      ) {
        this.setState({ importancePunctual: "Slightly Important" });
      }
      if (
        event.target.name === "studyWithPeoplePunctual" &&
        event.target.value === "3"
      ) {
        this.setState({ importancePunctual: "Important" });
      }
      if (
        event.target.name === "studyWithPeoplePunctual" &&
        event.target.value === "4"
      ) {
        this.setState({ importancePunctual: "Very Important" });
      }
      if (
        event.target.name === "studyWithPeoplePunctual" &&
        event.target.value === "5"
      ) {
        this.setState({ importancePunctual: "Essential" });
      }
    }
  };

  handleSubmit = (event) => {
    //after ok in swal is linked open this url (change to whatever page we want)
    function linkTrigger() {
      window.open("http://neoboardapp.com", "_top");
    }

    event.preventDefault();

    if (this.state.zipObj === "" && this.state.locObj === "") {
      swal("No Location Provided!", "Please select your location", "error");
    } else {
      swal("Thanks!", "You have completed the poll!", "success").then(
        (value) => {
          setTimeout(linkTrigger, 500);
        }
      );
      console.log(this.state);
    }
  };

  zipCallbackFunction = (childData) => {
    this.setState({ zipObj: childData });
  };

  locCallbackFunction = (childData) => {
    this.setState({ locObj: childData });
  };

  toggle = (event) => {
    event.preventDefault();

    if (event.target.name === "locBtn") {
      //show Map 1

      this.setState({ map1: "initial" });
      this.setState({ map2: "none" });
    }
    if (event.target.name === "zipBtn") {
      //show Map2

      this.setState({ map1: "none" });
      this.setState({ map2: "initial" });
    }

    if (event.target.name === "startBtn") {
      //show Clock 1

      this.setState({ clock1: "initial" });
      this.setState({ clock2: "none" });
    }
    if (event.target.name === "endBtn") {
      //show Clock2

      this.setState({ clock1: "none" });
      this.setState({ clock2: "initial" });
    }
  };

  render() {
    const popover = (
      <Popover id="popover-basic">
        <Popover.Title as="h3">
          Link your Apple Calendar to Google Calendar
        </Popover.Title>
        <Popover.Content>
          <p>
            Current version of Neoboard requires the user to import Apple
            Calendar into Google Calendar.
          </p>
          <p>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://help.practicebetter.io/hc/en-us/articles/115000669508-Linking-your-Apple-iCloud-Calendar-to-Google-Calendar"
            >
              Here is how to get started
            </a>
          </p>
        </Popover.Content>
      </Popover>
    );

    const Apple = (props) => (
      <OverlayTrigger trigger="click" placement="top" overlay={popover}>
        <Form.Check
          type={props.type}
          id={`default-${props.type}`}
          label={" Apple Calendar"}
          className="mb-10"
        ></Form.Check>
      </OverlayTrigger>
    );

    return (
      <div className="blur-box study-poll">
        <form onSubmit={this.handleSubmit} className="poll-body">
          {/* HEADER WITH SUBMIT */}
          <HeaderWithSubmit />

          {/* End Header with submit */}

          {/* Second Row */}
          <div className="row">
            {/* Left Margin */}
            <div className="col-md-2"></div>

            {/* Left Column */}
            <div className="col-md-4">
              {/* CURFEW START */}

              <div className="poll-card">
                <div className="row">
                  <div className="col-md-1"></div>
                  <div className="col-md-10">
                    <p className="poll-card-title h3">Do Not Disturb Hours</p>
                  </div>
                  <div className="col-md-1"></div>
                </div>
                {/* End of Curfew Header */}

                <div className="row">
                  <div className="col-md-12">
                    <ButtonGroup aria-label="Basic example">
                      <Button
                        variant="secondary"
                        size="lg"
                        name="startBtn"
                        onClick={this.toggle}
                        className="toggle-gradient"
                      >
                        Start Time
                      </Button>
                      <Button
                        variant="secondary"
                        size="lg"
                        name="endBtn"
                        onClick={this.toggle}
                        className="toggle-gradient"
                      >
                        End Time
                      </Button>
                    </ButtonGroup>

                    <div style={{ display: this.state.clock1 }} name="clock1">
                      <Clock
                        doNotDisturbStartTime={this.state.doNotDisturbStartTime}
                      />
                    </div>

                    <div style={{ display: this.state.clock2 }} name="clock2">
                      <Clock2
                        doNotDisturbEndTime={this.state.doNotDisturbEndTime}
                      />
                    </div>
                  </div>
                </div>

                <br />

                <div className="row">
                  <div className="col-md-1"></div>
                  <div className="col-md-10">
                    <p className="checkbox-list">
                      <input
                        name="erraticSleep"
                        type="checkbox"
                        checked={this.state.erraticSleep}
                        onChange={this.handleChange}
                        className="checkbox-label h6"
                        id="erratic"
                      />
                      <label htmlFor="erratic">
                        I have an erratic sleep schedule due to work
                      </label>
                    </p>
                  </div>
                  <div className="col-md-1"></div>
                </div>
              </div>

              {/* END CURFEW */}

              {/* START STUDY PREFERENCES */}
              <div className="poll-card">
                <div className="row">
                  <div className="col-md-1"></div>
                  <div className="col-md-10">
                    <p className="poll-card-title h3">Study Preferences</p>
                  </div>
                  <div className="col-md-1"></div>
                </div>

                <div className="row">
                  <div className="col-md-1"></div>
                  <div className="col-md-10">
                    <p className="slider-title h6 mb1">
                      Number of students in study group:
                    </p>

                    <Double
                      minStudents={this.state.minStudents}
                      maxStudents={this.state.maxStudents}
                    />
                  </div>
                  <div className="col-md-1"></div>
                </div>

                <br />
                <br />
                <div className="row">
                  <div className="col-md-1"></div>
                  <div className="col-md-10">
                    <p className="slider-title h6">
                      Study with people who I already know
                    </p>

                    <input
                      min="1"
                      max="5"
                      step="1"
                      name="studyWithPeopleKnow"
                      type="range"
                      onChange={this.handleChange}
                      id="studyWithPeopleKnow"
                    />

                    <br />
                    <label htmlFor="studyWithPeopleKnow">
                      {this.state.studyWithPeopleKnow}
                    </label>
                    <br />
                    <div className="importance-label">
                      {this.state.importanceKnow}
                    </div>
                    <br />
                  </div>
                  <div className="col-md-1"></div>
                </div>
                <br />
                <div className="row">
                  <div className="col-md-1"></div>
                  <div className="col-md-10">
                    <p className="slider-title h6">
                      Study with people who are patient
                    </p>

                    <input
                      min="1"
                      max="5"
                      step="1"
                      name="studyWithPeoplePatient"
                      type="range"
                      onChange={this.handleChange}
                      id="studyWithPeoplePatient"
                    />
                    <br />
                    <label htmlFor="studyWithPeoplePatient">
                      {this.state.studyWithPeoplePatient}
                    </label>
                    <br />
                    <div className="importance-label">
                      {this.state.importancePatient}
                    </div>
                    <br />
                  </div>
                  <div className="col-md-1"></div>
                </div>
                <br />
                <div className="row">
                  <div className="col-md-1"></div>
                  <div className="col-md-10">
                    <p className="slider-title h6">
                      Study with people who understand the material well
                    </p>

                    <input
                      min="1"
                      max="5"
                      step="1"
                      name="studyWithPeopleUnderstand"
                      type="range"
                      onChange={this.handleChange}
                      id="studyWithPeopleUnderstand"
                    />
                    <br />
                    <label htmlFor="studyWithPeopleUnderstand">
                      {this.state.studyWithPeopleUnderstand}
                    </label>
                    <br />
                    <div className="importance-label">
                      {this.state.importanceUnderstand}
                    </div>
                    <br />
                  </div>
                  <div className="col-md-1"></div>
                </div>
                <br />
                <div className="row">
                  <div className="col-md-1"></div>
                  <div className="col-md-10">
                    <p className="slider-title h6">
                      Study with people who are organized
                    </p>

                    <input
                      min="1"
                      max="5"
                      step="1"
                      name="studyWithPeopleOrganized"
                      type="range"
                      onChange={this.handleChange}
                      id="studyWithPeopleOrganized"
                    />
                    <br />
                    <label htmlFor="studyWithPeopleOrganized">
                      {this.state.studyWithPeopleOrganized}
                    </label>
                    <br />
                    <div className="importance-label">
                      {this.state.importanceOrganized}
                    </div>
                    <br />
                  </div>
                  <div className="col-md-1"></div>
                </div>

                <div className="row">
                  <div className="col-md-1"></div>
                  <div className="col-md-10">
                    <p className="slider-title h6">
                      Study with people who I have common interests with
                    </p>
                    <input
                      min="1"
                      max="5"
                      step="1"
                      name="studyWithPeopleInterests"
                      type="range"
                      onChange={this.handleChange}
                      id="studyWithPeopleInterests"
                    />
                    <br />
                    <label htmlFor="studyWithPeopleInterests">
                      {this.state.studyWithPeopleInterests}
                    </label>
                    <br />
                    <div className="importance-label">
                      {this.state.importanceInterests}
                    </div>
                    <br />
                  </div>
                  <div className="col-md-1"></div>
                </div>
                <br />
                <div className="row">
                  <div className="col-md-1"></div>
                  <div className="col-md-10">
                    <p className="slider-title h6">
                      Study with people who are punctual
                    </p>

                    <input
                      min="1"
                      max="5"
                      step="1"
                      name="studyWithPeoplePunctual"
                      type="range"
                      onChange={this.handleChange}
                      id="studyWithPeoplePunctual"
                    />
                    <br />
                    <label htmlFor="studyWithPeoplePunctual">
                      {this.state.studyWithPeoplePunctual}
                    </label>
                    <br />
                    <div className="importance-label">
                      {this.state.importancePunctual}
                    </div>
                    <br />
                  </div>
                  <div className="col-md-1"></div>
                </div>
              </div>

              {/* END STUDY PREFERENCES */}
            </div>

            {/* Right Column */}
            <div className="col-md-4">
              {/* START PRONOUNS */}

              <div className="poll-card pronouns">
                <div className="row">
                  <div className="col-md-1"></div>
                  <div className="col-md-10">
                    <p className="poll-card-title h3">Names & Pronouns</p>
                  </div>
                  <div className="col-md-1"></div>
                </div>

                <div className="row">
                  <div className="col-md-1"></div>
                  <div className="col-md-10">
                    <p>
                      Your name is listed as <strong>Student</strong>. If you
                      prefer to go by a different name, please type it here.
                      Letters only (sorry Elon Musk)
                    </p>
                  </div>
                  <div className="col-md-1"></div>
                </div>

                <div className="row">
                  <div className="col-md-1"></div>
                  <div className="col-md-10">
                    <input
                      value={this.state.pronoun}
                      type="text"
                      name="pronoun"
                      onChange={this.handleChange}
                      className="pronounInput"
                    />
                  </div>
                  <div className="col-md-1"></div>
                </div>

                <div className="row mt-3">
                  <div className="col-md-1"></div>
                  <div className="col-md-10">
                    <p>Note this does not change your name in school records</p>
                  </div>
                  <div className="col-md-1"></div>
                </div>

                <div className="row">
                  <div className="col-md-1"></div>
                  <div className="col-md-10 checkboxLeft">
                    <label>
                      <input
                        name="pronounNone"
                        type="checkbox"
                        checked={this.state.pronounNone}
                        onChange={this.handleChange}
                        className="checkbox-label"
                      />
                      None
                    </label>
                    <br />
                    <label>
                      <input
                        name="pronounShe"
                        type="checkbox"
                        checked={this.state.pronounShe}
                        onChange={this.handleChange}
                        className="checkbox-label"
                      />
                      She/her/hers
                    </label>
                    <br />
                    <label>
                      <input
                        name="pronounHe"
                        type="checkbox"
                        checked={this.state.pronounHe}
                        onChange={this.handleChange}
                        className="checkbox-label"
                      />
                      He/him/his
                    </label>
                    <br />
                    <label>
                      <input
                        name="pronounThey"
                        type="checkbox"
                        checked={this.state.pronounThey}
                        onChange={this.handleChange}
                        className="checkbox-label"
                      />
                      They/them/theirs
                    </label>
                    <br />
                    <label>
                      <input
                        name="pronounZe"
                        type="checkbox"
                        checked={this.state.pronounZe}
                        onChange={this.handleChange}
                        className="checkbox-label"
                      />
                      Ze/hir/hir
                    </label>
                  </div>
                  <div className="col-md-1"></div>
                </div>
              </div>
              {/* END PRONOUNS */}

              {/* START CALENDAR  */}

              <div className="poll-card">
                <div className="row">
                  <div className="col-md-1"></div>
                  <div className="col-md-10">
                    <p className="poll-card-title h3">Import Your Calendar</p>
                  </div>
                  <div className="col-md-1"></div>
                </div>

                <div className="row">
                  <div className="col-md-1"></div>
                  <div className="col-md-10 checkboxLeft">
                    <label>
                      <input
                        name="googleCalendar"
                        type="checkbox"
                        checked={this.state.googleCalendar}
                        onChange={this.handleChange}
                        className="checkbox-label"
                        required
                      />
                      Google Calendar
                    </label>
                    <br />
                    <Apple />

                    <label className="grayedOut">
                      <input
                        disabled
                        name="parent"
                        type="checkbox"
                        checked={this.state.microsoftOutlook}
                        onChange={this.handleChange}
                        className="checkbox-label"
                      />
                      Microsoft Outlook (future version)
                    </label>
                    <br />
                    <label className="grayedOut">
                      <input
                        disabled
                        name="parent"
                        type="checkbox"
                        checked={this.state.myStudyLife}
                        onChange={this.handleChange}
                        className="checkbox-label"
                      />
                      MyStudyLife (future version)
                    </label>
                  </div>
                  <div className="col-md-1"></div>
                </div>
                <br />
                <div className="row">
                  <div className="col-md-1"></div>
                  <div className="col-md-10">
                    <p className="asterisk">
                      * One active calendar required to be grouped into study
                      sessions
                    </p>
                  </div>
                  <div className="col-md-1"></div>
                </div>
              </div>

              {/* END CALENDAR */}

              {/* START CIRCUMSTANCES */}

              <div className="poll-card">
                <div className="row">
                  <div className="col-md-1"></div>
                  <div className="col-md-10">
                    <p className="poll-card-title h3">Special Circumstances</p>
                  </div>
                  <div className="col-md-1"></div>
                </div>

                <div className="row">
                  <div className="col-md-1"></div>
                  <div className="col-md-10 checkboxLeft">
                    <label>
                      <input
                        name="armedForces"
                        type="checkbox"
                        checked={this.state.armedForces}
                        onChange={this.handleChange}
                        className="checkbox-label"
                      />
                      I am a member of the Armed Forces
                    </label>

                    <br />

                    <label>
                      <input
                        name="workAtNight"
                        type="checkbox"
                        checked={this.state.workAtNight}
                        onChange={this.handleChange}
                        className="checkbox-label"
                      />
                      I work nightshifts on some/all nights
                    </label>
                    <br />

                    <label>
                      <input
                        name="parent"
                        type="checkbox"
                        checked={this.state.parent}
                        onChange={this.handleChange}
                        className="checkbox-label"
                      />
                      I am a Parent
                    </label>
                    <br />
                    <label>
                      <input
                        name="disability"
                        type="checkbox"
                        checked={this.state.disability}
                        onChange={this.handleChange}
                        className="checkbox-label"
                      />
                      I have a learning disability
                    </label>
                  </div>
                  <div className="col-md-1"></div>
                </div>

                <div className="row">
                  <div className="col-md-1"></div>
                  <div className="col-md-10">
                    <textarea
                      className="circumstancesText"
                      name="circumstancesText"
                      onChange={this.handleChange}
                      style={{
                        minHeight: 80,
                        maxHeight: 150,
                        width: "100%",
                        resize: "none",
                        background: "#daf2f5",
                        fontFamily: "Quicksand",
                        border: "none",
                        borderRadius: 5,
                      }}
                      value={this.state.circumstancesText}
                      placeholder="If you are comfortable telling us, please specify your special circumstances here."
                      id="improvementsText"
                    />
                  </div>
                  <div className="col-md-1"></div>
                </div>
              </div>

              {/* END CIRCUMSTANCES */}

              {/* START LOCATION> */}

              <div className="poll-card">
                <div className="row">
                  <div className="col-md-1"></div>
                  <div className="col-md-10">
                    <p className="poll-card-title h3">
                      Location for Study Groups
                    </p>
                  </div>
                  <div className="col-md-1"></div>
                </div>

                <div className="row">
                  <div className="col-md-1"></div>
                  <div className="col-md-10">
                    <ButtonGroup aria-label="Basic example">
                      <Button
                        variant="secondary"
                        size="lg"
                        name="locBtn"
                        onClick={this.toggle}
                        className="toggle-gradient"
                      >
                        Use My Location
                      </Button>
                      <Button
                        variant="secondary"
                        size="lg"
                        name="zipBtn"
                        onClick={this.toggle}
                        className="toggle-gradient"
                      >
                        Enter Zip Code
                      </Button>
                    </ButtonGroup>
                    <div style={{ display: this.state.map1 }} name="map1">
                      <Map
                        locObj={this.state.locObj}
                        parentCallback={this.locCallbackFunction}
                      />
                    </div>

                    <div style={{ display: this.state.map2 }} name="map2">
                      <Map2
                        zipObj={this.state.zipObj}
                        parentCallback={this.zipCallbackFunction}
                      />
                    </div>

                    <div id="map"></div>

                    {/* Blur feature not yet functional */}

                    {/* <label>
                    <input
                      name="blur"
                      type="checkbox"
                      checked={this.state.blur}
                      onChange={this.initMap}
                      className="checkbox-label"
                    />
                    Increase blur on my location
                  </label> */}

                    <div className="row">
                      <div className="col-md-12">
                        <p className="poll-card-title h4">
                          Study Session Location Preferences
                        </p>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-12 checkboxLeft">
                        <label>
                          <input
                            name="library"
                            type="checkbox"
                            checked={this.state.library}
                            onChange={this.handleChange}
                            className="checkbox-label"
                          />
                          Campus Library
                        </label>
                        <br />

                        <label>
                          <input
                            name="deptRoomsLabs"
                            type="checkbox"
                            checked={this.state.deptRoomsLabs}
                            onChange={this.handleChange}
                            className="checkbox-label"
                          />
                          Department Rooms/Labs
                        </label>
                        <br />

                        <label>
                          <input
                            name="coffeeLounge"
                            type="checkbox"
                            checked={this.state.coffeeLounge}
                            onChange={this.handleChange}
                            className="checkbox-label"
                          />
                          Coffee Shop/Lounge
                        </label>
                        <br />

                        <label>
                          <input
                            name="home"
                            type="checkbox"
                            checked={this.state.home}
                            onChange={this.handleChange}
                            className="checkbox-label"
                          />
                          Home
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-1"></div>
                </div>

                <div className="col-md-1"></div>
              </div>

              <div className="poll-card">
                <div className="row">
                  <div className="col-md-1"></div>
                  <div className="col-md-10">
                    <p className="poll-card-title h3">
                      Select Academic Programs
                    </p>
                  </div>
                  <div className="col-md-1"></div>
                </div>

                <div className="row">
                  <div className="col-md-1"></div>
                  <div className="col-md-10 checkboxLeft">
                    <label className="grayed-out">
                      <input
                        name="academicPrograms_biology"
                        type="checkbox"
                        checked={this.state.academicPrograms_biology}
                        onChange={this.handleChange}
                        className="checkbox-label"
                        disabled
                        // eslint-disable-next-line react/jsx-no-duplicate-props
                        checked
                      />
                      Biology 110 - Supplemental Instruction (required)
                    </label>
                    <br />

                    <label>
                      <input
                        name="academicPrograms_calculus"
                        type="checkbox"
                        checked={this.state.academicPrograms_calculus}
                        onChange={this.handleChange}
                        className="checkbox-label"
                      />
                      Calculus AB - Embedded Tutoring
                    </label>
                    <br />

                    <label>
                      <input
                        name="academicPrograms_britishLiterature"
                        type="checkbox"
                        checked={this.state.academicPrograms_britishLiterature}
                        onChange={this.handleChange}
                        className="checkbox-label"
                      />
                      British Literature - Writing Tutor Program
                    </label>
                    <br />
                    <label>
                      <input
                        name="academicPrograms_business105"
                        type="checkbox"
                        checked={this.state.academicPrograms_business105}
                        onChange={this.handleChange}
                        className="checkbox-label"
                      />
                      Business 105 - Study Group Program
                    </label>
                    <br />
                  </div>
                  <div className="col-md-1"></div>
                </div>
                <br />
                <div className="row">
                  <div className="col-md-1"></div>
                  <div className="col-md-10">
                    <p className="asterisk">
                      * You will be included in the class messaging regardless
                      of your above preferences
                    </p>
                  </div>
                  <div className="col-md-1"></div>
                </div>
              </div>
            </div>

            {/* END LOCATION */}
          </div>

          {/* Right Margin */}
          <div className="col-md-2"></div>

          <br />

          <br />
        </form>
      </div>
    );
  }
}
