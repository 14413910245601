import React from "react";
import Select from "react-select";

const options = [
  { value: 5, label: "5 min" },
  { value: 10, label: "10 min" },
  { value: 15, label: "15 min" },
  { value: 20, label: "20 min" },
  { value: 25, label: "25 min" },
  { value: 30, label: "30 min" },
  { value: 35, label: "35 min" },
  { value: 40, label: "40 min" },
  { value: 45, label: "45 min" },
  { value: 50, label: "50 min" },
];

export default class Selection extends React.Component {
  state = {
    selectedOption: 5,
    id: this.props.id,
  };

  sendData = () => {
    // this.props.parentCallback(this.state.selectedOption.value);
    this.props.parentCallback(this.state);
  };

  handleChange = (selectedOption) => {
    this.setState(
      (prevState) => ({ selectedOption }),
      function () {
        console.log(this.state);
      }
    );

    this.sendData(this.state.value);
  };
  render() {
    const { selectedOption } = this.state;

    return (
      <Select
        value={selectedOption}
        onChange={this.handleChange}
        options={options}
        isSearchable={false}
        placeholder={selectedOption + " min"}
        className={this.props.id}
      />
    );
  }
}
