import React from "react";

import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";

import AdminCarousel from "./AdminDemo/AdminCarousel";
import StuCarousel from "./StudentDemo/StuCarousel";

function Demo() {
  return (
    <div className="demo">
      {/*
      <Tabs defaultActiveKey="adminView" id="uncontrolled-tab-example">
  <Tab eventKey="adminView" title="Admin View">*/}
          <AdminCarousel />
        {/*</Tab>
        <Tab eventKey="stuView" title="Student View">
          <StuCarousel />
        </Tab>
      </Tabs>*/}
    </div>
  );
}

export default Demo;
