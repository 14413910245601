import React from "react";
import Prepared from "./Sliders/Prepared";
import Punctual from "./Sliders/Punctual";
import Spoke from "./Sliders/Spoke";
import Wrote from "./Sliders/Wrote";
import Personality from "./Sliders/Personality";
import Explaining from "./Sliders/Explaining";
import Cared from "./Sliders/Cared";
import Responsive from "./Sliders/Responsive";
import Effective from "./Sliders/Effective";
import Objectives from "./Sliders/Objectives";
import Conducive from "./Sliders/Conducive";
import Step from "./Sliders/Step";
import Help from "./Sliders/Help";
import Motivated from "./Sliders/Motivated";
import Tags from "./TextAreas/Tags";
import Tags2 from "./TextAreas/Tags2";

import "./StudentPollSI.css";

export default class StudentPollSI extends React.Component {
  state = {
    preparedRating: 3,
    punctualRating: 3,
    personalityRating: 3,
    caredRating: 3,
    spokeRating: 3,
    wroteRating: 3,
    responsiveRating: 3,
    explainingRating: 3,
    effectiveRating: 3,
    objectivesRating: 3,
    conduciveRating: 3,
    stepRating: 3,
    helpRating: 3,
    motivatedRating: 3,
    improvementsText: "",
    improvementTags: "",
    addtlComments: "",
    addtlCommentsTags: "",
  };

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  onKeyPress(event) {
    if (event.which === 13 /* Enter */) {
      event.preventDefault();
    }
  }

  preparedCallbackFunction = (childData) => {
    this.setState({ preparedRating: childData });
  };

  punctualCallbackFunction = (childData) => {
    this.setState({ punctualRating: childData });
  };

  personalityCallbackFunction = (childData) => {
    this.setState({ personalityRating: childData });
  };

  caredCallbackFunction = (childData) => {
    this.setState({ caredRating: childData });
  };

  spokeCallbackFunction = (childData) => {
    this.setState({ spokeRating: childData });
  };

  wroteCallbackFunction = (childData) => {
    this.setState({ wroteRating: childData });
  };

  responsiveCallbackFunction = (childData) => {
    this.setState({ responsiveRating: childData });
  };

  explainingCallbackFunction = (childData) => {
    this.setState({ explainingRating: childData });
  };

  effectiveCallbackFunction = (childData) => {
    this.setState({ effectiveRating: childData });
  };

  objectivesCallbackFunction = (childData) => {
    this.setState({ objectivesRating: childData });
  };

  conduciveCallbackFunction = (childData) => {
    this.setState({ conduciveRating: childData });
  };

  stepCallbackFunction = (childData) => {
    this.setState({ stepRating: childData });
  };

  helpCallbackFunction = (childData) => {
    this.setState({ helpRating: childData });
  };

  motivatedCallbackFunction = (childData) => {
    this.setState({ motivatedRating: childData });
  };

  improvementTagsCallbackFunction = (childData) => {
    this.setState({ improvementTags: childData });
  };

  addtlCommentsTagsCallbackFunction = (childData) => {
    this.setState({ addtlCommentsTags: childData });
  };

  handleSubmit = (event) => {
    event.preventDefault();

    console.log(this.state);
  };

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  render() {
    return (
      <div className="blur-box poll-si">
        <form
          onSubmit={this.handleSubmit}
          onKeyPress={this.onKeyPress}
          className="poll-body"
        >
          {/* HEADER WITH SUBMIT */}
          <div className="row">
            <div className="col-md-2"></div>

            {/* Study Group Poll Box */}
            <div className="col-md-8">
              <div className="poll-card border">
                <div className="row">
                  <div className="col-md-12">
                    <div className="poll-title">
                      <h1>Student SI Poll</h1>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-1"></div>
                  <div className="col-md-10">
                    <p className="title-text h3">
                      Student, how was your experience?
                    </p>
                  </div>
                  <div className="col-md-3"></div>
                </div>

                <div className="row">
                  <div className="col-md-4"></div>
                  <div className="col-md-4">
                    <button type="submit" className="btn poll-btn">
                      Submit Poll
                    </button>
                  </div>
                  <div className="col-md-2"></div>
                </div>
              </div>
            </div>

            <div className="col-md-2"></div>
          </div>

          {/* End Header with submit */}

          {/* New row, begin cards */}
          <div className="row">
            {/* Left Margin */}
            <div className="col-md-2"></div>

            {/* Center Area, Display columns */}
            <div className="col-md-8">
              <div className="row">
                {/* Left Column */}
                <div className="col-md-6">
                  <div className="poll-card">
                    <div className="row">
                      <div className="col-md-2"></div>
                      <div className="col-md-8">
                        <p className="center si-title h4">
                          Regarding your SI <b>Marcus Azure</b>, on a scale of 1
                          to 5:
                        </p>
                      </div>
                      <div className="col-md-2"></div>
                    </div>

                    {/* Start Left Sliders */}

                    <Prepared
                      parentCallback={this.preparedCallbackFunction}
                      preparedRating={this.state.preparedRating}
                    />

                    <Punctual
                      parentCallback={this.punctualCallbackFunction}
                      punctualRating={this.state.punctualRating}
                    />

                    <Personality
                      parentCallback={this.personalityCallbackFunction}
                      personalityRating={this.state.personalityRating}
                    />

                    <Cared
                      parentCallback={this.caredCallbackFunction}
                      caredRating={this.state.caredRating}
                    />
                    <Spoke
                      parentCallback={this.spokeCallbackFunction}
                      spokeRating={this.state.spokeRating}
                    />

                    <Wrote
                      parentCallback={this.wroteCallbackFunction}
                      wroteRating={this.state.wroteRating}
                    />

                    <Responsive
                      parentCallback={this.responsiveCallbackFunction}
                      responsiveRating={this.state.responsiveRating}
                    />

                    <Explaining
                      parentCallback={this.explainingCallbackFunction}
                      explainingRating={this.state.explainingRating}
                    />

                    {/* End Sliders */}
                  </div>
                </div>

                {/* Right Column */}
                <div className="col-md-6">
                  <div className="poll-card">
                    <div className="row">
                      <div className="col-md-2"></div>
                      <div className="col-md-8">
                        <p className="center si-title h4">
                          Regarding your SI sessions, on a scale of 1 to 5:
                        </p>
                      </div>
                      <div className="col-md-2"></div>
                    </div>

                    {/* Start Right Sliders */}

                    <Effective
                      parentCallback={this.effectiveCallbackFunction}
                      effectiveRating={this.state.effectiveRating}
                    />

                    <Objectives
                      parentCallback={this.objectivesCallbackFunction}
                      objectivesRating={this.state.objectivesRating}
                    />

                    <Conducive
                      parentCallback={this.conduciveCallbackFunction}
                      conduciveRating={this.state.conduciveRating}
                    />

                    <Step
                      parentCallback={this.stepCallbackFunction}
                      stepRating={this.state.stepRating}
                    />

                    <Help
                      parentCallback={this.helpCallbackFunction}
                      helpRating={this.state.helpRating}
                    />

                    <Motivated
                      parentCallback={this.motivatedCallbackFunction}
                      motivatedRating={this.state.motivatedRating}
                    />

                    {/* End Right Sliders */}
                  </div>
                  {/* Start Program Improvements Card */}
                  {/* <Improvements 
                  parentCallback={this.improvementsCallbackFunction}
                  improvements={this.state.improvements}
                  /> */}
                  <div className="poll-card">
                    <div className="row">
                      <div className="col-md-2"></div>
                      <div className="col-md-8">
                        <br />
                        <label
                          htmlFor="improvementsText"
                          className="center si-title h4"
                        >
                          Program Improvements
                        </label>
                      </div>
                      <div className="col-md-2"></div>
                    </div>

                    <div className="row">
                      <div className="col-md-1"></div>
                      <div className="col-md-10">
                        <br />
                        <textarea
                          className="textBox"
                          name="improvementsText"
                          onChange={this.handleChange}
                          style={{
                            minHeight: 80,
                            maxHeight: 150,
                            width: "100%",
                            resize: "none",
                            background: "#daf2f5",
                            fontFamily: "Quicksand",
                            border: "none",
                            borderRadius: 5,
                          }}
                          value={this.state.improvementsText}
                          placeholder="Tell us what could be improved"
                          id="improvementsText"
                        />
                      </div>
                      <div className="col-md-1"></div>
                    </div>

                    <div className="row">
                      <div className="col-md-1"></div>
                      <div className="col-md-10">
                        <Tags
                          parentCallback={this.improvementTagsCallbackFunction}
                          improvementTags={this.state.improvementTags}
                        />
                      </div>
                      <div className="col-md-1"></div>
                    </div>
                    <br />
                  </div>

                  {/* End Program Improvements Card */}

                  {/* Start Addtl Comments */}
                  <div className="poll-card">
                    <div className="row">
                      <div className="col-md-2"></div>
                      <div className="col-md-8">
                        <br />
                        <label
                          htmlFor="addtlComments"
                          className="center si-title h4"
                        >
                          Additional Comments
                        </label>
                      </div>
                      <div className="col-md-2"></div>
                    </div>

                    <div className="row">
                      <div className="col-md-1"></div>
                      <div className="col-md-10">
                        <br />
                        <textarea
                          className="textBox"
                          name="addtlComments"
                          onChange={this.handleChange}
                          style={{
                            minHeight: 80,
                            maxHeight: 150,
                            width: "100%",
                            resize: "none",
                            background: "#daf2f5",
                            fontFamily: "Quicksand",
                            border: "none",
                            borderRadius: 5,
                          }}
                          value={this.state.addtlComments}
                          placeholder="Any additonal comments?"
                          id="addtlComments"
                        />
                      </div>
                      <div className="col-md-1"></div>
                    </div>

                    <div className="row">
                      <div className="col-md-1"></div>
                      <div className="col-md-10">
                        <Tags2
                          parentCallback={
                            this.addtlCommentsTagsCallbackFunction
                          }
                          addtlCommentsTags={this.state.addtlCommentsTags}
                        />
                      </div>
                      <div className="col-md-1"></div>
                    </div>
                    <br />
                  </div>
                  {/* End Addtl Comments */}
                </div>
              </div>
            </div>

            {/* Right Margin */}
            <div className="col-md-2"></div>
          </div>
        </form>
      </div>
    );
  }
}
