import React from "react";
import "./Section6.css";
// import Mailto from "reactv16-mailto";
import { Link } from "react-router-dom";

function Section6() {
  return (
    <div className="section6-body">
      <div className="row">
        <div className="col-md-2"></div>

        <div className="col-md-8">
          <div className="row">
            <div className="col-md-12">
              <h1 className="section6-title">
                Build a great academic experience for your students today!
              </h1>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12 margin-top-smaller">
              <p className="section6-text h5">
                Find the right and reliable insights to increase student success though the power of study teams and 
                customized academic support!
              </p>
            </div>
          </div>

          <div className="row">
            <div className="col-md-4"></div>
            <div className="col-md-4">
              <div className="section6-button-container">
                <Link to="/contact">
                  <div className="section6-button">REQUEST A QUOTE</div>
                </Link>
                {/* <Mailto email="classroomquestions@neoboardapp.com" obfuscate={true}>
                    <div className="section6-button">REQUEST A QUOTE</div>
                </Mailto> */}
              </div>
            </div>
            <div className="col-md-4"></div>
          </div>
        </div>

        <div className="col-md-2"></div>
      </div>
    </div>
  );
}

export default Section6;
