import React, { useState, useEffect } from "react";
// eslint-disable-next-line no-unused-vars
import { Range, Slider } from "rc-slider"; 
import "rc-slider/assets/index.css";

const marks = {
  2: "2",
  3: "3",
  4: "4",
  5: "5",
  6: "6",
};

const Double = (props) => {
  const [minVal, setMinVal] = useState("2");
  const [maxVal, setMaxVal] = useState("6");

  useEffect(() => {
      
      if(props.minStudents.value === undefined && props.maxStudents.value === undefined) {
          props.minStudents.value = 2;
          props.maxStudents.value = 6;
      }
   else{
    props.minStudents.value = minVal.minVal;
    props.maxStudents.value = maxVal.maxVal;
   }
  });

  function handleChange(event) {
    setMinVal({ minVal: event[0] });
    setMaxVal({ maxVal: event[1] });
  }

  return (
    <div>
      <Range
        defaultValue={[2, 6]}
        min={2}
        max={6}
        allowCross={false}
        step={1}
        dots={true}
        marks={marks}
        className="mySlider"
        ariaLabelGroupForHandles={["rc-slider-handle-1", "rc-slider-handle-2"]}
        handleStyle={[
          {
            background: "#169dbf",
            border: 0,
            height: 25,
            width: 25,
            marginTop: -10,
          },
          {
            background: "#169dbf",
            border: 0,
            height: 25,
            width: 25,
            marginTop: -10,
          },
        ]}
        onAfterChange={handleChange}
      />
    </div>
  );
};

export default Double;
