import React from "react";
// import eval1 from "../../../assets/4 SI Recommendation.png";
// import eval2 from "../../../assets/4b Student Leader Recommendation.png";

import eval1 from "../../../assets/Demo Neo 4.png";

function AdminEvaluations() {
  return (
    <div className="admin">
      <div className="admin__left">
        <h1>Transform students experience</h1>
        <h3>
          Build results! Great students experiences that increase student satisfaction
        </h3>
      </div>

      <div className="admin__center"></div>

      <div className="admin__right">
        <div className="admin__right__container">
          <img src={eval1} alt="" className="eval" />
        </div>
      </div>
    </div>
  );
}

export default AdminEvaluations;
