import React, { Fragment } from "react";
import "../../Canvas.css";

let mq = window.matchMedia("(max-width: 767px)");

const DashboardTableRows = ({ row, keys, func }) => {
  let id;

  const headers = [
    "",
    "",
    "Time",
    "Duration",
    "Tutor",
    "Attending",
    "Group",
    "Location",
  ];

  if (mq.matches) {
    return (
      <tr>
        {/* row is currently a json object of the data */}
        {row.map((cell, index) => {
          if (index === 0) {
            id = cell;
            return;
          } else if (cell === "true" || cell === "false") {
            /*         return <td contenteditable='true' spellcheck='false'></td>; */

            return (
              <Fragment>
                <td>
                  <input
                    type="checkbox"
                    id={row[2]}
                    onChange={func}
                    name={id}
                  />
                  <label for={row[2]} className="checkbox-select">
                    Select
                  </label>
                </td>
              </Fragment>
            );
          } else {
            return (
              <td contenteditable="true" spellcheck="false">
                <div className="canvasCellLeft">
                  <strong>{headers[index]}:&nbsp;</strong>
                </div>
                <div className="canvasCellRight"> {cell}</div>
              </td>
            );
          }
        })}
      </tr>
    );
  } else {
    return (
      <tr>
        {/* row is currently a json object of the data */}
        {row.map((cell, index) => {
          if (index === 0) {
            id = cell;
            return;
          } else if (cell === "true" || cell === "false") {
            /*         return <td contenteditable='true' spellcheck='false'></td>; */

            return (
              <Fragment>
                <td>
                  <input
                    type="checkbox"
                    id={row[2]}
                    onChange={func}
                    name={id}
                  />
                  <label for={row[2]} className="checkbox-select">
                    Select
                  </label>
                </td>
              </Fragment>
            );
          } else {
            return (
              <td contenteditable="true" spellcheck="false">
                {cell}
              </td>
            );
          }
        })}
      </tr>
    );
  }
};

export default DashboardTableRows;
