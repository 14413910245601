import React from "react";
import { PeopleList } from "././People";
import { WithContext as ReactTags } from "react-tag-input";

const suggestions = PeopleList.map((tag) => {
  return {
    id: tag,
    text: tag,
  };
});

const KeyCodes = {
  tab: 9,
  comma: 188,
  enter: 13,
};

const delimiters = [KeyCodes.tab, KeyCodes.comma, KeyCodes.enter];

export default class PeopleInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tags: [
        // { id: "Thailand", text: "Thailand" },
        // { id: "India", text: "India" },
      ],
      suggestions: suggestions,
    };
    this.handleDelete = this.handleDelete.bind(this);
    this.handleAddition = this.handleAddition.bind(this);
    this.handleDrag = this.handleDrag.bind(this);
    this.handleTagClick = this.handleTagClick.bind(this);
  }

  sendData = () => {
    // most recent tag index
    // let index = this.state.tags.length - 1;

    this.props.parentCallback(this.state.tags);
  };

  handleDelete(i) {
    const { tags } = this.state;
    this.setState({
      tags: tags.filter((tag, index) => index !== i),
    });
    this.sendData(this.state.tags);
  }

  handleAddition(tag) {
    for (let i = 0; i < this.state.tags.length; i++) {
      if (tag.text === this.state.tags[i].text) {
        console.log("Duplicate Entry");
        return;
      }
    }

    //PASSES STATE OF VIEW OR EDIT
    let viewOrEdit = this.props.props;
    tag.viewOrEdit = viewOrEdit;

    tag.id = tag.id + " - " + viewOrEdit;

    this.setState(
      (state) => ({ tags: [...state.tags, tag] }),
      () => this.sendData(this.state.tags)
    );

    // this.sendData(this.state.tags);
  }

  handleDrag(tag, currPos, newPos) {
    const tags = [...this.state.tags];
    const newTags = tags.slice();

    newTags.splice(currPos, 1);
    newTags.splice(newPos, 0, tag);

    // re-render
    this.setState({ tags: newTags });
  }

  handleTagClick(index, tag) {
    console.log("The tag at index " + index + " was clicked");

    // on click of a tag, will add icon for first time
    // let elem = document.getElementsByClassName("ReactTags__tag");
    // elem[index].innerHTML.includes("Viewer");

    // if (
    //   !elem[index].innerHTML.includes("Viewer") ||
    //   !elem[index].innerHTML.includes("Edit")
    // ) {
    //   if (this.props.props === "Viewer") {
    //     const words = elem[index].innerHTML.split("ReactTags__remove");
    //     words.splice(1, 0, "ReactTags__remove Viewer");
    //     elem[index].innerHTML = words.join(" ");
    //   } else {
    //     const words = elem[index].innerHTML.split("ReactTags__remove");
    //     words.splice(1, 0, "ReactTags__remove Edit");
    //     elem[index].innerHTML = words.join(" ");
    //   }
    // }

    this.sendData(this.state.tags);
  }

  render() {
    const { tags, suggestions } = this.state;
    // const viewOrEdit = this.props.props;
    return (
      <div className="tagComponent col-md-12">
        <ReactTags
          // classNames={{
          //   remove: viewOrEdit,
          // }}
          labelField={"id"}
          tags={tags}
          suggestions={suggestions}
          delimiters={delimiters}
          placeholder="&nbsp;Add Person"
          handleDelete={this.handleDelete}
          handleAddition={this.handleAddition}
          handleDrag={this.handleDrag}
          handleTagClick={this.handleTagClick}
          minQueryLength={1}
          allowDragDrop={false}
          allowDeleteFromEmptyInput={false}
          inputFieldPosition="top"
          allowUnique={false}
          autocomplete={true}
          renderSuggestion={({ text }) => (
            <div style={{ fontFamily: "Quicksand" }}>
              {text} &nbsp;
              {this.props.props === "Viewer" ? (
                <i className="far fa-eye"></i>
              ) : (
                <i className="fas fa-edit"></i>
              )}
            </div>
          )}
        />
      </div>
    );
  }
}
