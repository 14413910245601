import React from "react";
import "./Table.css";
import Table from "react-bootstrap/Table";

let mq = window.matchMedia("(max-width: 550px)");

const profile = [
  {
    program_director: "Seymour Skinner",
    email_address: "sskinner@neocollege.edu",
    program_name: "Supplemental Instruction",
  },
  {
    program_director: "Dr. Hughes",
    email_address: "drhughes@neocollege.edu",
    program_name: "Astronomy Dept",
  },
  {
    program_director: "Dr. Jones",
    email_address: "indy@neocollege.edu",
    program_name: "Archeology Dept",
  },
  {
    program_director: "James Brown",
    email_address: "brownj@neocollege.edu",
    program_name: "Music Instruction",
  },
];

const renderTable = (profile, index) => {
  // window width is at most 550px
  if (mq.matches) {
    return (
      <div className="mobile-table">
        <div className="table-left">
          <td>
            <input type="checkbox" className="table-checkbox" />
          </td>
        </div>
        <div className="table-right">
          <div>
            {" "}
            <strong>Program Director: </strong> <br />
            {profile.program_director}
          </div>
          <div>
            {" "}
            <strong>Email Address: </strong> <br /> {profile.email_address}
          </div>
          <div>
            {" "}
            <strong>Program Name: </strong> <br /> {profile.program_name}
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <tr key={index}>
        <td>
          <input type="checkbox" className="table-checkbox" />
        </td>

        <td>
          <p className="th-mobile">Program Director: </p>
          {profile.program_director}
        </td>
        <td>
          <p className="th-mobile">Email Address: </p>
          {profile.email_address}
        </td>
        <td>
          <p className="th-mobile">Program Name: </p>
          {profile.program_name}
        </td>
      </tr>
    );
  }
};

function InstituteTable() {
  return (
    <div>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th></th>
            <th>Program Director</th>
            <th>Email Address</th>
            <th>Program Name</th>
          </tr>
        </thead>

        <tbody>{profile.map(renderTable)}</tbody>
      </Table>
      <button className="add-admin">+ Add Admin</button>
    </div>
  );
}

export default InstituteTable;
