import React, { Fragment } from 'react';
import './Tutorial7.css';

function Tutorials() {
  return (
    <Fragment>
      <div className='header-container'>
        <h1 className='header'>Discover New Resources!</h1>
        <div>
          <i class='icon fas fa-globe-americas'></i>
          <i class='icon fas fa-users'></i>
        </div>
        <a href='#' className='btn begin-btn'>
          Set Up Profile
        </a>
        <p className='information'>
          Succeed together! Message TAs, counselors, club organizers, and get
          connected with help and online resources at your campus!
        </p>
      </div>
    </Fragment>
  );
}

export default Tutorials;
