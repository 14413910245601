import React from "react";
import "./Tutorials.css";

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import Tutorial1 from "./Tutorial1/Tutorial1";
import Tutorial2 from "./Tutorial2/Tutorial2";
import Tutorial3 from "./Tutorial3/Tutorial3";
import Tutorial4 from "./Tutorial4/Tutorial4";
import Tutorial5 from "./Tutorial5/Tutorial5";
import Tutorial6 from "./Tutorial6/Tutorial6";
import Tutorial7 from "./Tutorial7/Tutorial7";

function Tutorials() {
  return (
    <Router>
      <div className="tutorial-background blur-box">
        <Tutorial1 />
        <Switch>
          <Route path="/tutorial1" component={Tutorial1} />
          <Route path="/tutorial2" component={Tutorial2} />
          <Route path="/tutorial3" component={Tutorial3} />
          <Route path="/tutorial4" component={Tutorial4} />
          <Route path="/tutorial5" component={Tutorial5} />
          <Route path="/tutorial6" component={Tutorial6} />
          <Route path="/tutorial7" component={Tutorial7} />
        </Switch>
        <div className="buttons"></div>
      </div>
    </Router>
  );
}

export default Tutorials;
