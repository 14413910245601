import React from 'react';
import axios from 'axios';

const zipCodeInvalid = 'Zip Code Not Validated!';
const zipCodeValid = 'Zip Code Validated!';

class Map2 extends React.Component {
  state = {
    postal_code: '',
    lat: '',
    lng: '',
    zipValid: '',
    color: '',
  };

  handleChange = (event) => {
    this.setState({ postal_code: event.target.value });
  };

  sendData = () => {
    this.props.parentCallback(this.state);
  };

  handleClick = (event) => {
    event.preventDefault();

    if (!this.state.postal_code || this.state.postal_code.length < 5) {
      console.log(zipCodeInvalid);
      this.setState({ zipValid: zipCodeInvalid });
      this.setState({ color: '#bb0000' });
    }
    if (this.state.postal_code.length > 5) {
      console.log(zipCodeInvalid);
      this.setState({ zipValid: zipCodeInvalid });
      this.setState({ color: '#bb0000' });
    } else if (this.state.postal_code.length === 5) {
      axios
        .get(
          `https://maps.googleapis.com/maps/api/geocode/json?address=${this.state.postal_code}&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`
        )
        .then((res) => {
          if (res.data.status === 'OK') {
            this.setState({ lat: res.data.results[0].geometry.location.lat });
            this.setState({ lng: res.data.results[0].geometry.location.lng });
            this.setState({ zipValid: zipCodeValid });
            this.setState({ color: 'green' });
            // this.props.callbackFunction(this.state)
            this.sendData(this.state);
          } else {
            this.setState({ zipValid: zipCodeInvalid });
            this.setState({ color: '#bb0000' });
          }
        })
        .then(() => {
          console.log(this.state);
        });
    }
  };

  render() {
    return (
      <div>
        <br />
        <div onClick={this.handleClick}>
          <input
            type='number'
            name='postal_code'
            postal_code='postal_code'
            onChange={this.handleChange}
            maxLength='5'
            placeholder='Enter 5 Digit Zip Code'
            className='zipInput'
          />

          <br />
          <button type='submit' className='login-btn'>
            Validate
          </button>
        </div>
        <div style={{ color: this.state.color }} className="zip-valid-color">{this.state.zipValid}</div>
      </div>
    );
  }
}

export default Map2;
