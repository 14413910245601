import React from "react";
import InstituteTable from "./InstituteTable";

function NewAccount() {
  return (
    <div className="new-account">
      <div className="welcome-box top-border width-full no-padding">
        <br />
        <div className="heading">Institution Information</div>
        <br />
        <div className="description">
          Create accounts here, note that email addresses must match the one on
          their LMS account
        </div>

        <br />
      </div>

      <div className="welcome-box top-border width-full no-padding">
        <br />
        <div className="heading">Admins</div>
        <br />
        <div className="description">
          All program directors that run study session programs are listed
          below. Not that email address must match the one on their LMS account
        </div>
        <br />
        <div className="row">
          <div className="col-md-1"></div>
          <div className="col-md-10">
            <InstituteTable />
          </div>
          <div className="col-md-1"></div>
        </div>
      </div>
    </div>
  );
}

export default NewAccount;
