import React from "react";
import { Line, defaults } from "react-chartjs-2";
import Dropdown from "react-bootstrap/Dropdown";

import "./graph.css";

let mq = window.matchMedia("(max-width: 768px)");

//GLOBAL DEFAULT PROPERTIES
defaults.global.legend.position = "right";
defaults.global.legend.align = "start";
defaults.global.defaultFontSize = 15;
defaults.global.defaultFontFamily = "Nixie One";
defaults.global.defaultFontColor = "black";
defaults.global.defaultFontStyle = "bold";
defaults.global.tooltips.bodySpacing = 10;
defaults.global.tooltips.titleFontSize = 18;
defaults.global.tooltips.yPadding = 10;
defaults.global.legend.labels.boxWidth = 60;
defaults.global.legend.labels.padding = 20;
defaults.global.elements.line.fill = false;
defaults.global.elements.line.tension = 0;

function GraphChild(props) {
  if (mq.matches) {
    defaults.global.legend.position = "bottom";
    defaults.global.legend.align = "start";
    return (
      <div className="graphBody blur-box">
        <div className="graphCard border">
          <div className="graph">
            <Line
              data={{
                labels: [
                  "",
                  "Week 1",
                  "Week 2",
                  "Week 3",
                  "Week 4",
                  "Week 5",
                  "Week 6",
                  "Week 7",
                  "Week 8",
                  "Week 9",
                  "Week 10",
                ],
                datasets: props.currentData,
              }}
              options={{
                title: {
                  display: true,
                  text: "Attendance",
                  fontSize: 50,
                  fontStyle: "normal",
                },
                tooltips: {
                  // Overrides the global setting
                  mode: "label",
                  enabled: true,
                  callbacks: {
                    label: function (tooltipItem, data) {
                      return (
                        data.datasets[tooltipItem.datasetIndex].label +
                        ": " +
                        tooltipItem.yLabel +
                        "% " +
                        `(${
                          data.datasets[tooltipItem.datasetIndex].attended[
                            tooltipItem.index
                          ]
                        }/${
                          data.datasets[tooltipItem.datasetIndex].classSize
                        } Students)`
                      );
                    },
                  },
                },

                maintainAspectRatio: false,
                scales: {
                  yAxes: [
                    {
                      ticks: {
                        beginAtZero: true,
                        stepSize: 20,
                      },
                      scaleLabel: {
                        display: true,
                        labelString: "% of Students",
                      },
                    },
                  ],
                  xAxes: [
                    {
                      scaleLabel: {
                        display: true,
                        labelString: "Week No.",
                      },
                      ticks: {
                        autoSkip: true,
                      },
                    },
                  ],
                },
              }}
            />
          </div>
          <br />

          <div className="row">
            <div className="col-md-6 export">
              {" "}
              <button
                onClick={props.exportGraph}
                className="graphExportBtn session__btn-light"
              >
                Export
              </button>
            </div>
            <div className="col-md-6  graphSelection">
              <br />
              <div className="row">
                <div className="col-md-4">
                  <div onChange={props.handleClick} className="graphRadioBtn">
                    <input
                      type="radio"
                      value="Quarter"
                      name="time"
                      defaultChecked
                    />{" "}
                    Quarter
                    <br />
                    <input type="radio" value="Year" name="time" /> Year
                  </div>
                </div>
                <div className="col-md-4">
                  <div onChange={props.handleClick} className="graphRadioBtn">
                    <input
                      type="radio"
                      value="Quarter"
                      name="filter"
                      defaultChecked
                    />{" "}
                    All
                    <br />
                    <input
                      type="radio"
                      value="Year"
                      name="filter"
                      disabled
                    />{" "}
                    By Course
                    <br />
                    <input
                      type="radio"
                      value="Year"
                      name="filter"
                      disabled
                    />{" "}
                    Custom
                  </div>
                </div>
                <div className="col-md-4">
                  <Dropdown>
                    <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                      &nbsp;
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                      <Dropdown.Item href="#/action-2">
                        Another action
                      </Dropdown.Item>
                      <Dropdown.Item href="#/action-3">
                        Something else
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="graphBody blur-box">
        <div className="graphCard border">
          <div className="graph">
            <Line
              data={{
                labels: [
                  "",
                  "Week 1",
                  "Week 2",
                  "Week 3",
                  "Week 4",
                  "Week 5",
                  "Week 6",
                  "Week 7",
                  "Week 8",
                  "Week 9",
                  "Week 10",
                ],
                datasets: props.currentData,
              }}
              options={{
                title: {
                  display: true,
                  text: "Attendance",
                  fontSize: 50,
                  fontStyle: "normal",
                },
                tooltips: {
                  // Overrides the global setting
                  mode: "label",
                  enabled: true,
                  callbacks: {
                    label: function (tooltipItem, data) {
                      return (
                        data.datasets[tooltipItem.datasetIndex].label +
                        ": " +
                        tooltipItem.yLabel +
                        "% " +
                        `(${
                          data.datasets[tooltipItem.datasetIndex].attended[
                            tooltipItem.index
                          ]
                        }/${
                          data.datasets[tooltipItem.datasetIndex].classSize
                        } Students)`
                      );
                    },
                  },
                },
                maintainAspectRatio: false,
                scales: {
                  yAxes: [
                    {
                      ticks: {
                        beginAtZero: true,
                        stepSize: 20,
                      },
                      scaleLabel: {
                        display: true,
                        labelString: "% of Students",
                      },
                    },
                  ],
                  xAxes: [
                    {
                      scaleLabel: {
                        display: true,
                        labelString: "Week No.",
                      },
                      ticks: {
                        autoSkip: true,
                      },
                    },
                  ],
                },
              }}
            />
          </div>

          <div className="row">
            <div className="col-md-6 export">
              {" "}
              <button
                onClick={props.exportGraph}
                className="graphExportBtn session__btn-light"
              >
                Export
              </button>
            </div>
            <div className="col-md-6  graphSelection">
              <div className="row">
                <div className="col-md-4">
                  <div onChange={props.handleClick} className="graphRadioBtn">
                    <input
                      type="radio"
                      value="Quarter"
                      name="time"
                      defaultChecked
                    />{" "}
                    Quarter
                    <br />
                    <input type="radio" value="Year" name="time" /> Year
                  </div>
                </div>
                <div className="col-md-4">
                  <div onChange={props.handleClick} className="graphRadioBtn">
                    <input
                      type="radio"
                      value="Quarter"
                      name="filter"
                      defaultChecked
                    />{" "}
                    All
                    <br />
                    <input
                      type="radio"
                      value="Year"
                      name="filter"
                      disabled
                    />{" "}
                    By Course
                    <br />
                    <input
                      type="radio"
                      value="Year"
                      name="filter"
                      disabled
                    />{" "}
                    Custom
                  </div>
                </div>
                <div className="col-md-4">
                  <Dropdown>
                    <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                      &nbsp;
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                      <Dropdown.Item href="#/action-2">
                        Another action
                      </Dropdown.Item>
                      <Dropdown.Item href="#/action-3">
                        Something else
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default GraphChild;
