import React from "react";
import "./Section3.css";

import { Link } from "react-router-dom";

function Section3Stu() {
  return (
    <div className="section3-body">
      <div className="row section3-content">
        {/* Image */}
        <div className="col-md-6">
          <img
            src={require("../../assets/3/happy-students@2x.png")}
            alt="happy students"
            className="happy-students"
          ></img>
        </div>
        {/* Right Side Text */}
        <div className="col-md-6">
          <div className="row">
            <div className="col-md-10">
              <h1 className="section3-title">
                Become the STUDENT you were meant to BE
              </h1>
            </div>
            <div className="col-md-2"></div>
          </div>
          <div className="row">
            <div className="col-md-10">
              <section className="list">
                <p className="s3-subs">- Instant access to study groups</p>
                <p className="s3-subs">
                  - Stay connected with academic support
                </p>
                <p className="s3-subs">
                  - Equip your program with powerful scheduling &amp; metrics
                  tools
                </p>
                <p className="margin-top-small s3-subs">
                  Make studying and academic support easier with Neoboard!
                </p>
              </section>
            </div>
            <div className="col-md-2"></div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <Link to="/demo">
                <div className="margin-top-small download-btn">
                  CHECKOUT THE DEMO
                </div>
              </Link>
            </div>
            <div className="col-md-4"></div>
            <div className="col-md-4"></div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Section3Stu;
