import React, { Fragment } from 'react';
import './Tutorial5.css';

function Tutorials() {
  return (
    <Fragment>
      <div className='header-container'>
        <h1 className='header'>Confirm Your Session!</h1>
        <div>
          <i class='icon far fa-calendar-alt'></i>
        </div>
        <p className='information'>
          We find a study group, and best time & place to meet! Just choose your
          preferred time and begin chatting with your classmates 👩‍🎓
        </p>
      </div>
    </Fragment>
  );
}

export default Tutorials;
