import React from "react";
import "./Engineering.css";

import { Link } from "react-router-dom";

const EngineeringWelcome = () => {
  return (
    <div className="engineering blur-box-eng">
      <div className="engineering-container dashboard container-padding">
        <div className="welcome-box top-border">
          <div className="heading">Welcome!</div>
          <div className="description">
            Let's get your students better connected, click below to login to
            Engineering
          </div>
          <label htmlFor="engineeringEmail" className="hidden-label">
            Email
          </label>
          <input
            className="login-input double-margin-buffer-horizontal"
            type="text"
            placeholder="Email"
            id="engineeringEmail"
          />
          <label htmlFor="engineeringPassword" className="hidden-label">
            Password
          </label>
          <input
            className="login-input double-margin-buffer-horizontal"
            type="text"
            placeholder="Password"
            id="engineeringPassword"
          />
          <Link
            to="/engineering/dashboard"
            className="permission-submit double-margin-buffer unselectable no-margin-top"
          >
            Login
          </Link>
        </div>
      </div>
    </div>
  );
};

export default EngineeringWelcome;
