import React from "react";
import Carousel from "react-bootstrap/Carousel";
import "./AdminCarousel.css";
import "../StudentDemo/Mockup.css";
import AdminLogin from "./AdminLogin";
import AdminEnrollment from "./AdminEnrollment";
import AdminSessions from "./AdminSessions";
import AdminEvaluations from "./AdminEvaluations";
import AdminMetrics from "./AdminMetrics";

function AdminCarousel() {
  return (
    <div className="admin__Carousel">
      <Carousel slide={false} fade={true} touch={true}>
        <Carousel.Item>
          <div className="admin__Carousel__mobile__text1">
            <h3>Learn from your systems</h3>
            <h5>
              Integration made easy! Integrate your LMS data (Canvas, Blackboard, etc.), Zoom data and other sources
            </h5>
          </div>
          <AdminLogin />
        </Carousel.Item>
        <Carousel.Item>
          <div className="admin__Carousel__mobile__text1">
            <h3>Measure everything</h3>
            <h5>
            Through the use reliable data such as study team analytics, behavior and detailed participation data, find at-risk students early
            </h5>
          </div>
          <AdminEnrollment />
        </Carousel.Item>
        <Carousel.Item>
          <div className="admin__Carousel__mobile__text1">
            <h3>Automate actions to reduce dropout</h3>
            <h5>
            Automatic scheduling of student team sessions, team assignments, tutor scheduling and more
            </h5>
          </div>
          <AdminSessions />
        </Carousel.Item>
        <Carousel.Item>
          <div className="admin__Carousel__mobile__text1">
            <h3>Transform students experience</h3>
            <h5>
            Build results! Great students experiences that increase student satisfaction
            </h5>
          </div>
          <AdminEvaluations />
        </Carousel.Item>
        <Carousel.Item>
          <div className="admin__Carousel__mobile__text1">
            <h3>Get confident reports</h3>
            <h5>
              Create reports at anytime using BI tools like: Power BI, Excel, Tableau...
            </h5>
          </div>
          <AdminMetrics />
        </Carousel.Item>
      </Carousel>
    </div>
  );
}

export default AdminCarousel;
