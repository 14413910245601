import React from "react";
import "./Download.css";

import { Link } from "react-router-dom";

function Download() {
  return (
    <div className="download blur-box">
      <h1>Neoboard App is currently under construction</h1>
      <br />
      <h3>Please contact us for updated details about it's release.</h3>
      <br />

      <Link to="contact">
        <div className="email-button">GET UPDATES TODAY</div>
      </Link>
    </div>
  );
}

export default Download;
