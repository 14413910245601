import React from "react";
import "./Section4Mobile.css";

function Section4Mobile() {
  return (
    <div className="section4-mobile-body">
      <div className="row">
        <div className="col-md-2"></div>
        <div className="col-md-8">
          <h1 className="section4-mobile-title">How Neoboard works</h1>
        </div>
        <div className="col-md-2"></div>
      </div>
      <div className="container-fluid card-box">
        <div className="row">
          <div className="col-md-6 card-left">
            <div class="exp">
              <i class="fas fa-users icon-lh gradient-icon"></i>
            </div>
          </div>
          <div className="col-md-6 card-right">
            <p className="mobile-icon-text4 h6">
              Determine which classrooms need study teams and generate student teams
            </p>
          </div>
        </div>
      </div>
      

      <div className="container-fluid card-box">
        <div className="row">
          <div className="col-md-6 card-left">
            <div class="exp">
              <i class="fas fa-exclamation-triangle icon-lh gradient-icon"></i>
            </div>
          </div>
          <div className="col-md-6 card-right">
            <p className="mobile-icon-text4 h6">
            Create customizable alerts and define <strong>actions</strong> that help at-risk students


            </p>
          </div>
        </div>
      </div>
      <div className="container-fluid card-box">
        <div className="row">
          <div className="col-md-6 card-left">
            <div class="exp">
              <i class="far fa-chart-bar  icon-lh gradient-icon"></i>
            </div>
          </div>
          <div className="col-md-6 card-right">
            <p className="mobile-icon-text4 h6">
              Analytics across multiple systems and data sources are simplified
            </p>
          </div>
        </div>
      </div>

      
    </div>
  );
}

export default Section4Mobile;
