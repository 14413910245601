import React from "react";
import "./Section3.css";

import { Link } from "react-router-dom";

function Section3() {
  return (
    <div className="section3-body">
      <div className="row section3-content">
        {/* Image */}
        <div className="col-md-6">
          <img
            src={require("../../assets/3/happy-students@2x.png")}
            alt="happy students"
            className="happy-students"
          ></img>
        </div>
        {/* Right Side Text */}
        <div className="col-md-6">
          <div className="row">
            <div className="col-md-10">
              <h1 className="section3-title">
              INTERVENE early and retain STUDENTS
              </h1>
            </div>
            <div className="col-md-2"></div>
          </div>
          <div className="row">
            <div className="col-md-10">
              <section className="list">
                <p className="margin-top-small s3-subs">
                Every student has different needs, and it’s important to find challenges early on.  Find struggling students early with our early-warning system and direct them to the right campus resources at the click of a button!

                </p>
              </section>
            </div>
            <div className="col-md-2"></div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <Link to="/demo">
                <div className="margin-top-small download-btn">
                  CHECKOUT THE DEMO
                </div>
              </Link>
            </div>
            <div className="col-md-4"></div>
            <div className="col-md-4"></div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Section3;
