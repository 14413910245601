import React, { Fragment } from 'react';

const CommentDocument = ({ document, handleDocumentClick }) => {
  return (
    <div className='document-container'>
      <iframe
        src={document?.getAttribute('src')}
        title='title'
        className='iframe document-full'
        id='myiframe'
        onClick={(e) => {
          handleDocumentClick();
        }}
      ></iframe>
      <i
        className='fas fa-times document-full__close'
        onClick={handleDocumentClick}
      ></i>
    </div>
  );
};

export default CommentDocument;
