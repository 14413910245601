// No add rows
const messagingData1 = [
  {
    id: 'nPJ1',
    channel_name: '{Classname} Class',
    type: 'Classroom',
    enabled: true,
    external_file_sharing: true,
    instructor_participant: true,
    anonymous_messaging: true,
    grouping_method: '',
  },
  {
    id: 'nPY1',
    channel_name: 'Study Group',
    type: 'Class Group',
    enabled: '',
    external_file_sharing: true,
    instructor_participant: true,
    anonymous_messaging: '',
    grouping_method: ['Diversity', 'Network', 'Similarity', 'Default'],
  },
];

// Able to add row
const messagingData2 = [
  {
    id: 'nPr1',
    channel_name: 'Midterm Project',
    type: 'Classroom',
    enabled: true,
    external_file_sharing: true,
    instructor_participant: true,
    anonymous_messaging: true,
    grouping_method: ['Default', 'Network', 'Similarity', 'Diversity'],
  },
  {
    id: 'rPY1',
    channel_name: 'Presentation Project',
    type: 'Classroom',
    enabled: true,
    external_file_sharing: true,
    instructor_participant: true,
    anonymous_messaging: true,
    grouping_method: ['Default', 'Network', 'Similarity', 'Diversity'],
  },
];

const messagingData3 = [
  {
    id: 'cSr6',
    channel_name: 'Campus Announcements',
    type: 'Campus',
    admin_email: '',
    enabled: true,
    external_file_sharing: true,
    anonymous_messaging: true,
    __: true,
  },
  {
    id: 'cSt6',
    channel_name: 'On-Demand Tutoring',
    type: 'Resource',
    admin_email: 'gchalmers@neocollege.edu',
    enabled: null,
    external_file_sharing: true,
    anonymous_messaging: null,
    __: true,
  },
  {
    id: 'cSw6',
    channel_name: 'Women in Tech',
    type: 'Club',
    admin_email: 'artemis.lir@neocollecge.edu',
    enabled: false,
    external_file_sharing: false,
    anonymous_messaging: false,
    __: true,
  },
  {
    id: 'cSs6',
    channel_name: 'Entrepeneurship',
    type: 'Club',
    admin_email: 'phil.lamar@neocollege.edu',
    enabled: false,
    external_file_sharing: false,
    anonymous_messaging: false,
    __: true,
  },
  {
    id: 'cSj6',
    channel_name: 'Doctors No Borders',
    type: 'Club',
    admin_email: 'james.wu@neocollecge.edu',
    enabled: false,
    external_file_sharing: false,
    anonymous_messaging: false,
    __: true,
  },
  {
    id: 'cSo6',
    channel_name: 'Martial Arts Club',
    type: 'Club',
    admin_email: 'man.takai@neocollege.edu',
    enabled: false,
    external_file_sharing: false,
    anonymous_messaging: false,
    __: true,
  },
  {
    id: 'cSq6',
    channel_name: 'Delta Iota Kappa',
    type: 'Club',
    admin_email: 'mira.mouli@neocollecge.edu',
    enabled: false,
    external_file_sharing: false,
    anonymous_messaging: false,
    __: true,
  },
];

export { messagingData1, messagingData2, messagingData3 };
