import React, { useState, useEffect } from "react";
import Timekeeper from "react-timekeeper";

const Clock = (props) => {
  const [time, setTime] = useState("12:00am");

  useEffect(() => {
    let removed = time.replace(':', '');
    let parsed = parseInt(removed, 10);

    props.doNotDisturbStartTime.value = parsed;
    
  });


  return (
    <div>
      <div className="row">
        <div className="col-md-2"></div>
        <div className="col-md-8">
          <p className="curfew-time h5">
            Do Not Disturb
            <br /> Start Time: 
          </p>
        </div>
        <div className="col-md-2"></div>
      </div>

      <Timekeeper
        time={time}
        onChange={(newTime) => setTime(newTime.formatted24)}
        coarseMinutes={15}
        forceCoarseMinutes
        value={time}
      />
    </div>
  );
};

export default Clock;
