import React, { useState, useEffect } from 'react';
import '../Canvas.css';
import './Tabs.css';

import { Link } from 'react-router-dom';

// For the Program Roster tab
import Courses from './adminTabs/CoursesTab';
// For the SI Evaluations tab
import Evaluations from './adminTabs/EvaluationsTab';
// For the Session Schedules tab
import Schedules from './adminTabs/SchedulesTab';
// For the Messaging tab
import Messaging from './adminTabs/MessagingTab';
// For the Session Metrics tab
import Metrics from './adminTabs/MetricsTab';
// For the Program Settings tab
import Settings from './adminTabs/SettingsTab';

import { render } from '@testing-library/react';

const CanvasDashboard = ({
  courseData,
  sessionCourseData,
  sessionData1,
  sessionData2,
  sessionData3,
  sessionData4,
  metricData,
  settingsData,
  transferData,
}) => {
  const [tabState, setTabState] = useState('session-messaging');

  const changeTab = (event) => {
    // Passing in the value of the value attribute for the tab which this is called from
    setTabState(event.target.value);
  };

  const renderTab = () => {
    switch (tabState) {
      case 'program-roster':
        return <Courses data={courseData} />;
      case 'si-evaluations':
        return <Evaluations /* courseData={data} */ />;
      case 'session-schedules':
        return (
          <Schedules
            sessionCourseData={sessionCourseData}
            session1={sessionData1}
            session2={sessionData2}
            session3={sessionData3}
            session4={sessionData4}
          />
        );
      case 'session-messaging':
        return <Messaging data={courseData} /* courseData={data} */ />;
      case 'session-metrics':
        return <Metrics /* courseData={data} */ />;
      case 'program-settings':
        return (
          <Settings settingsData={settingsData} transferData={transferData} />
        );
    }
  };

  return (
    <div className='canvas-container dashboard'>
      <div className='welcome-box top-border width-full no-padding-bottom no-padding-sides'>
        <div className='heading'>Admin Dashboard</div>
        <div className='description'>
          Please upload your roster of classes, or manually add instructors here
        </div>
        {/*
        <div className='dashboard-button double-margin-buffer'>Add / Edit</div>
        <div className='dashboard-button-outline double-margin-buffer'>
          Replace
        </div>
        */}
        <div className='tab-bar unselectable'>
          <input
            type='radio'
            id='program-roster'
            name='tab-nav'
            value='program-roster'
            className='tab-radio-button'
            onChange={changeTab}
          />
          <label htmlFor='program-roster' className='tab-navigation-button'>
            <i className='fas fa-list tab-icon'></i>
            Program Roster
          </label>
          <input
            type='radio'
            id='si-evaluations'
            name='tab-nav'
            value='si-evaluations'
            className='tab-radio-button'
            onChange={changeTab}
          />
          <label htmlFor='si-evaluations' className='tab-navigation-button'>
            <i className='fas fa-star tab-icon'></i>
            SI Evaluations
          </label>
          <input
            type='radio'
            id='session-schedules'
            name='tab-nav'
            value='session-schedules'
            className='tab-radio-button'
            onChange={changeTab}
          />
          <label htmlFor='session-schedules' className='tab-navigation-button'>
            <i className='far fa-calendar-alt tab-icon'></i>
            Session Schedules
          </label>
          <input
            type='radio'
            id='session-messaging'
            name='tab-nav'
            value='session-messaging'
            className='tab-radio-button'
            onChange={changeTab}
          />
          <label htmlFor='session-messaging' className='tab-navigation-button'>
            <i class='fas fa-comments tab-icon'></i>
            Messaging
          </label>
          <input
            type='radio'
            id='session-metrics'
            name='tab-nav'
            value='session-metrics'
            className='tab-radio-button'
            onChange={changeTab}
          />
          <label htmlFor='session-metrics' className='tab-navigation-button'>
            <i className='fas fa-chart-line tab-icon'></i>
            Study Session Metrics
          </label>
          <input
            type='radio'
            id='program-settings'
            name='tab-nav'
            value='program-settings'
            className='tab-radio-button'
            onChange={changeTab}
          />
          <label htmlFor='program-settings' className='tab-navigation-button'>
            <i className='fas fa-cog tab-icon'></i>
            Program Settings
          </label>
        </div>
      </div>

      {renderTab()}
    </div>
  );
};

export default CanvasDashboard;
