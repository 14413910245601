import React, { Fragment, useEffect } from 'react';
import Menu from './CommentMenu';
import Plus from '../Icons/Plus';

import sample from './test.pdf';

// Default was var
let Latex = require('react-latex');

const Chat = ({
  chatroom,
  notifications,
  chatHistory,
  addReaction,
  handleUserClick,
  renderReaction,
  determineReactionType,
  handleCommentEvent,
  getQuoteHeight,
}) => {
  let quoteHeight = 0;
  useEffect(() => {
    quoteHeight = getQuoteHeight();
  }, [quoteHeight]);

  return (
    <div className='chat' style={{ paddingBottom: `${quoteHeight}rem` }}>
      {chatroom ? (
        <div className='chat__heading'>
          <div className='chat__room'>{chatroom}</div>
          <div className='chat__notifications'>
            {notifications ? `${notifications} New Messages` : ''}
          </div>
        </div>
      ) : null}
      {chatHistory.map(
        (
          {
            image,
            username,
            reactions,
            timestamp,
            comment,
            pdf,
            doc,
            quote,
            id,
          },
          index
        ) => (
          <div
            className='comment'
            data-id={id}
            {...handleCommentEvent}
            key={index}
            // 3.5rem is just an estimate of the hight of the class indicator, may need to to be updated later.
            style={index === 0 ? { marginTop: '3.5rem' } : {}}
          >
            {quote && (
              <div
                className='comment-quote'
                onClick={function () {
                  console.log('clicked quote' + id);
                  const target = document.querySelector(`[data-id=${"'1'"}]`);
                  target.scrollIntoView({
                    behavior: 'smooth',
                    block: 'nearest',
                    inline: 'start',
                  });

                  target.classList.remove('comment__highlight');
                  void target.offsetWidth;
                  target.classList.add('comment__highlight');
                }}
              >
                <div className='comment-quote__heading'>
                  <div className='comment-quote__user'>{quote.user}</div>
                  <div className='comment-quote__time'>{quote.time}</div>
                </div>
                <div className='comment-quote__comment'>{quote.comment}</div>
              </div>
            )}
            <img
              src={image}
              alt={username}
              onClick={handleUserClick}
              className='comment__image'
              style={quote && { gridRow: '2 / 4' }}
            />
            <div className='comment__heading'>{username}</div>
            <div className='comment__time'>{timestamp}</div>
            <div className='comment__reactions'>
              {Object.entries(reactions).map(
                ([reaction, number], index) =>
                  // number is the number of people who have given a specific reaction to a comment, so if no one has (i.e. the number is 0), this expression will short-circuit to !!number, which will be true, NOT 0 like usual, because the !! type casts it to a boolean, which React won't render (if it just checked number rather than !!number, it would render a 0)
                  !!number && (
                    <span
                      className={`comment__reaction comment__reaction--${determineReactionType(
                        reaction
                      )} u-unselectable u-clickable`}
                      key={index}
                      onClick={addReaction}
                    >
                      <span>{renderReaction(reaction, number)}</span>
                      {number && <span>{number}</span>}
                    </span>
                  )
              )}
              {/*               <div className='comment__add-reactin'>+</div> */}
            </div>

            <div className='comment__comment'>
              <Latex>{comment}</Latex>
              {pdf && (
                <Fragment>
                  <br />
                  <iframe
                    src={
                      'https://www.thnlsn.me/files/Thomas_Nelson_Resume.pdf' +
                      '#toolbar=0'
                    }
                    title='title'
                    className='iframe comment__doc'
                    id='myiframe'
                  >
                    {/* me: <a href={pdf}>Download PDF</a> */}
                  </iframe>
                  {/* <Document file={sample} className={'comment__pdf'}>
                    <Page pageIndex={0} renderTextLayer={true} scale={0.6} />
                  </Document> */}
                </Fragment>
              )}
              {doc && (
                <Fragment>
                  <br />
                  <iframe
                    className='iframe comment__doc'
                    title='Google Document'
                    // width='367px'
                    // height='475px'
                    src={doc + '?rm=minimal'}
                  />
                </Fragment>
              )}
            </div>
          </div>
        )
      )}
    </div>
  );
};

export default Chat;
