import React, { useState, useEffect } from "react";
import Timekeeper from "react-timekeeper";

const Clock2 = (props) => {
  const [time, setTime] = useState("8:00am");

  useEffect(() => {
    let removed = time.replace(':', '');
    let parsed = parseInt(removed, 10);

    props.doNotDisturbEndTime.value = parsed;
    
  });

  return (
    <div>
      <div className="row">
        <div className="col-md-2"></div>
        <div className="col-md-8">
          <h5 className="curfew-time">
            Do Not Disturb
            <br /> End Time:
          </h5>
        </div>
        <div className="col-md-2"></div>
      </div>
      <Timekeeper
        time={time}
        onChange={(newTime) => setTime(newTime.formatted24)}
        coarseMinutes={15}
        forceCoarseMinutes
      />
    </div>
  );
};

export default Clock2;
