import React from "react";
import "./Section4.css";

function Section4() {
  return (
    <div className="section4-body">
      <div className="row">
        <div className="col-md-2"></div>
        <div className="col-md-8">
          <h1 className="section4-title">How Neoboard works</h1>
        </div>
        <div className="col-md-2"></div>
      </div>

      <div className="row">
        <div className="col-md-2"></div>
        {/* Card row */}
        <div className="col-md-8">
          <div className="row card-container">
          <div className="col-md-4">
              <div className="card">
                {/* Icon row */}
                <div className="row">
                  <div className="col-md-12">
                    <div class="exp">
                      <i class="fas fa-users icon-lh gradient-icon"></i>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-12">
                    <div className="row">
                      <div className="col-md-12">
                        <p className="icon-text4 h4">
                        Determine which classrooms need study teams and generate student teams
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card">
                {/* Icon row */}
                <div className="row">
                  <div className="col-md-12">
                    <div class="exp">
                      <i class="fas fa-exclamation-triangle icon-lh gradient-icon"></i>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-12">
                    <div className="row">
                      <div className="col-md-12">
                        <p className="icon-text4 h4">
                        Create customizable alerts and define <strong>actions</strong> that help at-risk students
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            
            <div className="col-md-4">
              <div className="card">
                {/* Icon row */}
                <div className="row">
                  <div className="col-md-12">
                    <div class="exp">
                      <i class="far fa-chart-bar  icon-lh gradient-icon"></i>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-12">
                    <div className="row">
                      <div className="col-md-12">
                        <p className="icon-text4 h4">
                        Analytics across multiple systems and data sources are simplified
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-2"></div>
      </div>
    </div>
  );
}

export default Section4;
