import React from 'react';

function Plus() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      fillRule='evenodd'
      clipRule='evenodd'
      className='icon-add-reaction'
    >
      <path d='M11 11V0h1v11h11v1H12v11h-1V12H0v-1h11z'></path>
    </svg>
  );
}

export default Plus;
