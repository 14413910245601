import React from "react";
import "./Section2.css";

function Section2Stu() {
  return (
    <div className="section2-body">
      <div className="row">
        <div className="col-md-1"></div>

        {/* Content goes in here */}
        <div className="col-md-10 section2-container">
          <div className="row margin-top-small">
            <div className="col-md-2"></div>
            <div className="col-md-8">
              <h2 className="section2-title">STUDENTS FIRST</h2>
            </div>
          </div>
          <div className="col-md-2"></div>

          <div className="row">
            <div className="col-md-2"></div>
            <div className="col-md-8 margin-top-small">
              <p className="section2-text h4">
                {/* We offer the first LMS compatible app that automates study group
                scheduling and puts the power of study group metrics in your
                hands, saving you valuable time. Integration with existing
                software gives your tutors peace of mind, and empowers students
                to stay engaged in the class! */}
                Our team is all about building student connections with the
                power of cohort studying and academic support. We offer the
                first LMS compatible platform that makes creating study groups
                simple, methodically organizing student groups and automating
                scheduling, video sessions, academic integrity, and conflicts.
                We empower students to stay engaged in class by facilitating
                communications between students, instructors, TAs, tutors,
                counselors and campus academic support.
              </p>
            </div>
            <div className="col-md-2"></div>
          </div>

          <div className="row margin-top-small">
            <div className="col-md-1"></div>
            <div className="col-md-10">
              <div className="row">
                <div className="col-md-3">
                  <div className="icon-container">
                    <i className="fas fa-clock gradient-icon"></i>
                  </div>

                  <div className="row">
                    <div className="col-md-12 icon-text">
                      <p className="h6">Automated Study Group Scheduling</p>
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="icon-container">
                    <i className="fas fa-video gradient-icon"></i>
                  </div>

                  <div className="row">
                    <div className="col-md-12 icon-text">
                      <p className="h6">Zoom Integration</p>
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="icon-container">
                    <i className="fab fa-google-drive gradient-icon"></i>
                  </div>

                  <div className="row">
                    <div className="col-md-12 icon-text">
                      <p className="h6">LMS &amp; G Suite Compatible</p>
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="icon-container">
                    <i className="fas fa-chart-line gradient-icon"></i>
                  </div>

                  <div className="row">
                    <div className="col-md-12 icon-text">
                      <p className="h6">Study Group Metrics</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-1"></div>
          </div>

          {/* End of content */}
        </div>

        <div className="col-md-1"></div>
      </div>
    </div>
  );
}

export default Section2Stu;
