import React, { Fragment } from 'react';

const Burger = ({ notifications, handleBurgerClick }) => {
  return (
    <Fragment>
      <input
        type='checkbox'
        className='burger__checkbox'
        id='sidebar-toggle'
        onClick={handleBurgerClick}
      ></input>
      <label htmlFor='sidebar-toggle' className='burger__btn'>
        <span className='burger__icon'>
          {/* If notifications is falsy (0), then do not show a notification bubble */}
          {notifications ? (
            <span className='burger__notifications'>{notifications}</span>
          ) : null}
        </span>
        {/* <span className='navbar__notifications'>{notifications}</span> */}
      </label>
    </Fragment>
  );
};

export default Burger;
