import React from 'react';
import '../Canvas.css';

import { Link } from 'react-router-dom';

const CanvasPermissionsPage = ({ setLogin }) => {
  return (
    <div className='canvas-container greyscale-background container-padding'>
      <div className='welcome-box top-border-grey login-box'>
        <div className='heading'>
          <div className='neoboard-logo double-margin-buffer'></div>
          <div className='canvas-logo greyscale double-margin-buffer' />
        </div>
        <div className='description'>
          <span className='font-weight-bolder'>Neoboard</span> will receive the
          following information: your public profile, class calendar, assignment
          calendar, class roster, student emails.
        </div>
        <Link
          to='/canvas/dashboard/uploaded'
          className='permission-submit double-margin-buffer unselectable'
        >
          Accept
        </Link>
        <Link
          to='/canvas/dashboard/preupload'
          className='permission-submit-grey double-margin-buffer unselectable'
        >
          Decline
        </Link>
      </div>
    </div>
  );
};

export default CanvasPermissionsPage;
