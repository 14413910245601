import React from "react";
import "./Postings.css";

import Button from "react-bootstrap/Button";

function Postings() {
  // const [showResults, setShowResults] = useState(false);

  // function handleClick() {
  //   if (showResults === true) {
  //     setShowResults(false);
  //   } else {
  //     setShowResults(true);
  //   }
  // }

  return (
    <div className="postings-body blur-box">
      <div className="row">
        <div className="col-md-1"></div>
        <div className="col-md-10">
          <div className="border postings-card">
            <div className="row">
              <div className="col-md-12">
                <div className="postings-title h1">Neoboard Job Postings</div>
                <br />
                <div className="row">
                  <div className="col-md-1"></div>
                  <div className="col-md-10">
                    <ul className="postings-ul">
                      {/* Start of Posting */}
                      <li>
                        <div className="row">
                          <div className="col-md-6  ">
                            <div className="postings-label">
                              {" "}
                              Development Tech Lead
                            </div>
                          </div>
                          <div className="col-md-6">
                            <a
                              className="postings-link"
                              href="https://docs.google.com/document/d/1BshWBLe5JxZXXYYBQboUF1prb44zsc6gej7GF9ZmMbk/edit?usp=sharing"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <Button>Link to Doc</Button>
                            </a>
                            {/* <Button onClick={handleClick}>
                              {!showResults ? (
                                <div>Display Posting</div>
                              ) : (
                                <div>Hide Posting</div>
                              )}
                            </Button> */}
                          </div>
                        </div>
                      </li>
                      {/* End of Posting */}
                      <br />
                    </ul>
                  </div>
                  <div className="col-md-1"></div>
                </div>
                <br />
                <div className="row">
                  <div className="col-md-12">
                    {/* {showResults ? (
                      <iframe
                        className="iframe"
                        title="BackEnd Developer Posting"
                        width="600px"
                        height="780px"
                        src="https://docs.google.com/document/d/e/2PACX-1vRcvcBDo9j4YxUpEbT4qWiO-qADnz5SSMQxt8Krd1YVVs_nrrBIcnaWYJ0ZdK6xkQ96IYIjaerwTDLy/pub?embedded=true"
                      ></iframe>
                    ) : (
                      <div></div>
                    )} */}
                  </div>
                </div>

                <br />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End of job postings card */}
      <div className="row">
        <div className="col-md-1"></div>
        <div className="col-md-10">
          <div className="border team-card">
            <div className="row">
              <div className="col-md-12">
                <div className="postings-title h1">
                  Neoboard Internship Postings
                </div>
                <br />
                <div className="row">
                  <div className="col-md-1"></div>
                  <div className="col-md-10">
                    <ul className="postings-ul">
                      <br />
                      {/* Start of Posting */}
                      <li>
                        <div className="row">
                          <div className="col-md-6  ">
                            <div className="postings-label">
                              {" "}
                              Cloud Messaging Developer
                            </div>
                          </div>
                          <div className="col-md-6">
                            <a
                              className="postings-link"
                              href="https://docs.google.com/document/d/1RPuVHwPBfMHfl3SQOJHq2g4RMbF0-oKN4-jQdeDBaUY/edit?usp=sharing"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <Button>Link to Doc</Button>
                            </a>
                          </div>
                        </div>
                      </li>
                      {/* End of Posting */}
                      <br />
                      {/* Start of Posting */}
                      <li>
                        <div className="row">
                          <div className="col-md-6  ">
                            <div className="postings-label">
                              {" "}
                              AWS Back End Developer
                            </div>
                          </div>
                          <div className="col-md-6">
                            <a
                              className="postings-link"
                              href="https://docs.google.com/document/d/1O5LMpunPcYCAGIaAHxoGvPZ-tS6UyslkTe0YrUCyoRo/edit?usp=sharing"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <Button>Link to Doc</Button>
                            </a>
                          </div>
                        </div>
                      </li>
                      {/* End of Posting */}
                      <br />
                      {/* Start of Posting */}
                      <li>
                        <div className="row">
                          <div className="col-md-6  ">
                            <div className="postings-label">
                              {" "}
                              Python Calendar Developer
                            </div>
                          </div>
                          <div className="col-md-6">
                            <a
                              className="postings-link"
                              href="https://docs.google.com/document/d/1F-Y6NTlAHGOKOipUvI9hUH_omglvJJNiXPMOAu-_I3w/edit?usp=sharing"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <Button>Link to Doc</Button>
                            </a>
                          </div>
                        </div>
                      </li>
                      {/* End of Posting */}
                      <br />
                      {/* Start of Posting */}
                      <li>
                        <div className="row">
                          <div className="col-md-6  ">
                            <div className="postings-label">
                              {" "}
                              Brand Ambassador
                            </div>
                          </div>
                          <div className="col-md-6">
                            <a
                              className="postings-link"
                              href="https://docs.google.com/document/d/1xGkDBBIWIJTKLBuqX__2yRo4Cbtpozhj8vyO_DgokM4/edit"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <Button>Link to Doc</Button>
                            </a>
                          </div>
                        </div>
                      </li>
                      {/* End of Posting */}
                    </ul>
                  </div>
                  <div className="col-md-1"></div>
                </div>

                <br />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Postings;
