import React from "react";
import adminLogin from "../../../assets/Demo Neo 01.png";

function AdminLogin() {
  return (
    <div className="admin">
      <div className="admin__left">
        <h1>Integration made easy</h1>
        <h3>
        Integrate your LMS data (Canvas, Blackboard, etc.), Zoom, Discord, Microsoft Teams, data and other sources
        </h3>
      </div>

      <div className="admin__center"></div>

      <div className="admin__right">
        <div className="admin__right__container">

              <img src={adminLogin} alt=""  className="eval"/>

        </div>
      </div>
    </div>
  );
}

export default AdminLogin;
