import React, { useState } from 'react';
import './Engineer.css';

import CreateAccount from './engineerTabs/CreateAccountTab';
import ViewEditAccount from './engineerTabs/ViewEditAccountTab';

const EngineerDashboard = ({
  institutionData,
  adminData,
  institutionsData,
  allInstitutionsData,
}) => {
  const [tabState, setTabState] = useState('create-account');

  const changeTab = (event) => {
    // Passing in the value of the value attribute htmlFor the tab which this is called from
    setTabState(event.target.value);
  };

  const renderTab = () => {
    switch (tabState) {
      case 'create-account':
        return (
          <CreateAccount
            institutionData={institutionData}
            adminData={adminData}
          />
        );
      case 'view-edit-account':
        return (
          <ViewEditAccount
            institutionsData={institutionsData}
            // institutionData={institutionData}
            allInstitutionsData={allInstitutionsData}
          />
        );
      default:
        return (
          <div className='welcome-box'>
            Sorry, there is an error on our end.
          </div>
        );
    }
  };

  return (
    <div className='engineering-container dashboard'>
      <div className='welcome-box top-border width-full no-padding'>
        <br />
        <div className='heading'>Engineer Dashboard</div>
        <br />
        <div className='description'>
          Create, edit or delete new Neoboard Admin Accounts
        </div>
        <br />
        <div className='tab-bar'>
          {/* create */}
          <input
            type='radio'
            id='create-account'
            name='tab-nav'
            value='create-account'
            className='tab-radio-button'
            onChange={changeTab}
            defaultChecked
          />
          <label htmlFor='create-account' className='tab-navigation-button'>
            <i className='fas fa-cog tab-icon'></i>
            Create New Account
          </label>

          {/* view/edit */}
          <input
            type='radio'
            id='view-edit-account'
            name='tab-nav'
            value='view-edit-account'
            className='tab-radio-button'
            onChange={changeTab}
          />
          <label htmlFor='view-edit-account' className='tab-navigation-button'>
            <i className='fas fa-tasks tab-icon'></i>
            View/Edit Accounts
          </label>
        </div>
      </div>

      {renderTab()}
    </div>
  );
};

export default EngineerDashboard;
