import React from "react";

const HeaderWithSubmit = () => {
  return (
    <div className="row">
      <div className="col-md-2"></div>

      {/* Study Group Poll Box */}
      <div className="col-md-8">
        <div className="poll-card border">
          <div className="row">
            <div className="col-md-12">
              <div className="poll-title">
                <h1>Study Group Poll</h1>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-1"></div>
            <div className="col-md-10">
              <p className="title-text h3">
                Welcome Student, let's get you assigned the right study group!
              </p>
            </div>
            <div className="col-md-3"></div>
          </div>

          <div className="row">
            <div className="col-md-4"></div>
            <div className="col-md-4">
              <button type="submit" className="btn poll-btn">
                Submit Poll
              </button>
            </div>
            <div className="col-md-2"></div>
          </div>
        </div>
      </div>

      <div className="col-md-1"></div>
    </div>
  );
};

export default HeaderWithSubmit;
