import React from "react";

import { Link } from "react-router-dom";
import "./routes.css";

function Routes() {
  return (
    <div className="links-body">
      <br />
      <div className="row">
        <div className="col-md-2"></div>
        <div className="col-md-8">
          <div className="border links-card">
            <h1>Neoboard Routes</h1>
            <div className="row">
              <div className="col-md-12">
                <br />
                <Link to="/" alt="home">
                  <div>HOME</div>
                </Link>
                <Link to="/canvas" alt="canvas">
                  <div>CANVAS</div>
                </Link>
                <Link to="/demo" alt="demo">
                  <div>DEMO</div>
                </Link>
                <Link to="/download" alt="download">
                  <div>DOWNLOAD</div>
                </Link>
                <Link to="/graph" alt="graph">
                  <div>GRAPH</div>
                </Link>

                <Link to="/contact" alt="contact">
                  <div>CONTACT</div>
                </Link>

                <Link to="/studygrouppoll" alt="studygrouppoll">
                  <div>STUDY GROUP POLL</div>
                </Link>
                <Link to="/studentpollsi" alt="studentpollsi">
                  <div>STUDENT SI POLL</div>
                </Link>

                <Link to="/student" alt="student">
                  <div>STUDENT VERSION HOME PAGE</div>
                </Link>
                <Link to="/privacy" alt="privacy">
                  <div>PRIVACY</div>
                </Link>
                <Link to="/session_itinerary" alt="session_itinerary">
                  <div>SESSION ITINERARY</div>
                </Link>

                <Link to="/leader_sessiontime" alt="leader_sessiontime">
                  <div>SESSION TIME SELECTION - STUDENT LEADER</div>
                </Link>
                <Link to="/student_sessiontime" alt="student_sessiontime">
                  <div>SESSION TIME SELECTION - STUDENT</div>
                </Link>
                <Link to="/blog" alt="blog">
                  <div>BLOG (In Progess)</div>
                </Link>
                <Link to="/team" alt="team">
                  <div>TEAM (In Progess)</div>
                </Link>
                <Link to="/postings" alt="postings">
                  <div>POSTINGS (In Progess)</div>
                </Link>
                <Link to="/tutorials" alt="tutorials">
                  <div>TUTORIALS (In Progress) </div>
                </Link>
                <Link to="/login" alt="login">
                  <div>LOGIN (In Progress)</div>
                </Link>
                <Link to="/poll" alt="poll">
                  <div>POLL (Deprecated)</div>
                </Link>
                <Link to="/register" alt="register">
                  <div>REGISTER (Deprecated)</div>
                </Link>
                <Link to="/welcome" alt="welcome">
                  <div>WELCOME (Deprecated)</div>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-2"></div>
      </div>
    </div>
  );
}

export default Routes;
