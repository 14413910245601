import React, { Fragment, useState, useEffect } from 'react';

const CommentMenu = ({
  instructorOrAdmin,
  visible,
  reactions,
  renderReaction,
  determineReactionType,
  closeChatMenu,
  copyToClipboard,
  handleQuoteClick,
  handleAnnotateClick,
  document,
}) => {
  const [clipboardAlert, setClipboardAlert] = useState(false);

  // Function to compare reactions to total reaction list and only display ones not included
  const flipReactions = (reactions) => {
    const allReactions = [
      'thumbsUp',
      'handPaper',
      'check',
      'question',
      'angry',
      'flag',
    ];
    if (instructorOrAdmin) allReactions.push('wrench', 'checkDouble');
    // For each possible reaction, determine whether the comment has it, and if so DON'T include it, meaning the resulting array will only be reactions not already on the comment
    return allReactions.filter((reaction) => {
      // If the reaction currently passing for filter exists in the comments reactions, flip the true to false so it fails the filter, else it will pass the filter
      return !reactions.includes(reaction);
    });
  };

  const clipboardMessage = (
    <div className='chat-menu__alert'>Message copied to clipboard.</div>
  );

  return (
    <Fragment>
      {visible && (
        <div className='chat-menu-overlay' onClick={closeChatMenu}></div>
      )}
      <div className={`chat-menu ${visible ? 'chat-menu--visible' : ''}`}>
        {!flipReactions(reactions)?.length || (
          <div className='chat-menu__reactions'>
            {flipReactions(reactions).map((reaction, index) => (
              <span
                className={`chat-menu__reaction comment__reaction--${determineReactionType(
                  reaction
                )} u-unselectable u-clickable`}
                key={index}
                /*             onClick={addReaction} */
              >
                <span>{renderReaction(reaction)}</span>
              </span>
            ))}
          </div>
        )}
        <div className='chat-menu__item'>
          <i className='fas fa-edit'></i> Edit
        </div>
        {/*         <div className='chat-menu__item'>
          <i className='far fa-copy'></i> Copy
        </div> */}
        <div className='chat-menu__item' onClick={(e) => handleQuoteClick(e)}>
          <i className='fas fa-share'></i> Quote
        </div>
        {document && (
          <div
            className='chat-menu__item'
            onClick={(e) => handleAnnotateClick(e)}
          >
            <i className='fas fa-marker'></i> View Fullscreen
          </div>
        )}
        <div
          className='chat-menu__item'
          // Arrow function
          onClick={(e) => {
            copyToClipboard(e);
            setClipboardAlert(true);
            setTimeout(function () {
              setClipboardAlert(false);
            }, 1000);
          }}
        >
          <i className='fas fa-file-export'></i> Export
        </div>
        {clipboardAlert && clipboardMessage}
      </div>
    </Fragment>
  );
};

export default CommentMenu;
