import React from "react";
import RLDD from "react-list-drag-and-drop/lib/RLDD";
import Selection from "./Selection";
const topics = require("./topics.json");

export default class Drag extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      items: topics.topics,
      duration: 0,
    };

    this.itemRenderer = this.itemRenderer.bind(this);
    this.handleRLDDChange = this.handleRLDDChange.bind(this);
    this.changeColor = this.changeColor.bind(this);
    this.checkIndex = this.checkIndex.bind(this);
    this.timeCallbackFunction = this.timeCallbackFunction.bind(this);
  }

  checkIndex(e) {
    for (let i = 0; i < this.state.items.length; i++) {
      let x = e.target.outerHTML.includes("topic" + [i]);
      if (x === true) {
        let index = i;
        console.log("Check id:");
        console.log(index);
        this.changeColor(e, index);
      }
    }
  }

  changeColor(e, index) {
    let tRed = `2px solid rgb(136, 34, 85)`;
    let tGreen = `2px solid rgb(17, 119, 51)`;
    let tYellow = `2px solid rgb(221, 204, 119)`;

    console.log(index);

    if (e.target.outerHTML.includes(`${tGreen}`)) {
      e.target.style.border = tYellow;
      let items = [...this.state.items];
      let item = { ...items[index] };
      item.difficulty = "medium";
      items[index] = item;
      this.setState(
        (prevState) => ({ items }),
        function () {
          //console.log(this.state);
        }
      );
      //console.log(items);

      return;
    }
    if (e.target.outerHTML.includes(`${tYellow}`)) {
      e.target.style.border = tRed;
      let items = [...this.state.items];
      let item = { ...items[index] };
      item.difficulty = "hard";
      items[index] = item;
      this.setState(
        (prevState) => ({ items }),
        function () {
          //console.log(this.state);
        }
      );
      //console.log(items);
      return;
    }
    if (e.target.outerHTML.includes(`${tRed}`)) {
      e.target.style.border = tGreen;
      let items = [...this.state.items];
      let item = { ...items[index] };
      item.difficulty = "easy";
      items[index] = item;
      this.setState(
        (prevState) => ({ items }),
        function () {
          //console.log(this.state);
        }
      );

      return;
    }
  }

  timeCallbackFunction(childData) {
    let str = childData.id;
    let res = str.split("timeSelect");
    let index = res[1];

    let items = [...this.state.items];
    let item = { ...items[index] };
    item.duration = childData.selectedOption.value;
    items[index] = item;
    this.setState(
      (prevState) => ({ items }),
      function () {
        //console.log(this.state);
      }
    );
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    console.log("prevProps");
    console.log(prevProps);
    console.log("prevState");
    console.log(prevState);
    console.log("snapshot");
    console.log(snapshot);
    console.log("this.state");
    console.log(this.state);
    prevState = this.state;
  }

  render() {
    const items = this.state.items;
    //console.log(this.state);
    return (
      <div>
        <RLDD
          items={items}
          itemRenderer={this.itemRenderer}
          onChange={this.handleRLDDChange}
        />
      </div>
    );
  }

  itemRenderer(item) {
    return (
      <div className="item">
        <div className="button-container centering">
          <button
            style={{
              border: `2px solid 
                #117733`,
            }}
            onClick={this.checkIndex}
            id={`topic${item.id}`}
          ></button>
        </div>

        <p className="title">{item.title}</p>
        <Selection
          duration={this.state.duration}
          parentCallback={this.timeCallbackFunction}
          id={`timeSelect${item.id}`}
        />
        <i className="fas fa-bars centering"></i>
      </div>
    );
  }

  handleRLDDChange(reorderedItems) {
    this.setState(
      (prevState) => ({ items: reorderedItems }),
      function () {
        //console.log(this.state);
      }
    );
  }
}
