import React, { useState } from "react";
import Graph from "../../../Graph/Graph";

import { withStyles } from "@material-ui/core/styles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import PeopleInput from "./../PeopleInput";

import Select from "react-select";
import { FormGroup } from "@material-ui/core";

const IOSSwitch = withStyles((theme) => ({
  root: {
    width: 42,
    height: 26,
    padding: 0,
    margin: theme.spacing(1),
  },
  switchBase: {
    padding: 1,
    "&$checked": {
      transform: "translateX(16px)",
      color: theme.palette.common.white,
      "& + $track": {
        backgroundColor: "#4bbf9a",
        opacity: 1,
        border: "none",
      },
    },
    "&$focusVisible $thumb": {
      color: "#52d869",
      border: "6px solid #fff",
    },
  },
  thumb: {
    width: 24,
    height: 24,
  },
  track: {
    borderRadius: 26 / 2,
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: theme.palette.grey[50],
    opacity: 1,
    transition: theme.transitions.create(["background-color", "border"]),
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});

const options = [
  { value: "Viewer", label: "Viewer" },
  { value: "Edit", label: "Edit" },
];

const MetricsTab = () => {
  const [state, setState] = useState({
    checkedEnabled: true,
    checkedPublicLink: false,
    checkedAdmins: true,
    checkedInstructors: false,
    checkedStudentLeaders: false,
    urlInput: "",
    userPermissionsState: "",
    viewOrEdit: "Viewer",
  });

  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  const handleURL = (event) => {
    setState({ ...state, [event.target.name]: event.target.value });
  };

  const handleViewOrEdit = (event) => {
    setState({ ...state, viewOrEdit: event.value });
  };

  const usersPermissionsSelect = (childData) => {
    setState({ ...state, userPermissionsState: childData });
  };

  return (
    <div>
      <div className="welcome-box top-border width-full">
        <div className="heading">Quick Dashboard</div>
        <br />
        <div className="row">
          <div className="col-md-1"></div>
          <div className="col-md-10">
            <div className="quickdash">
              <div className="row urlRow">
                <div className="col-md-12">
                  <label htmlFor="urlInput">
                    Unique URL:&nbsp;&nbsp;https://neo.board/quickdash/
                    <input
                      value={state.urlInput}
                      type="text"
                      name="urlInput"
                      onChange={handleURL}
                      className="urlInput"
                    />
                  </label>
                  <FormControlLabel
                    control={
                      <IOSSwitch
                        checked={state.checkedEnabled}
                        onChange={handleChange}
                        name="checkedEnabled"
                      />
                    }
                    label="Enabled"
                  />
                  <FormControlLabel
                    control={
                      <IOSSwitch
                        checked={state.checkedPublicLink}
                        onChange={handleChange}
                        name="checkedPublicLink"
                      />
                    }
                    label="Public Link"
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-8 centering">
                  <div className="centering">Give Users Permissions:</div>
                </div>
                <div className="col-md-4">
                  <div>
                    <Select
                      value={state.viewOrEdit}
                      onChange={handleViewOrEdit}
                      options={options}
                      placeholder={
                        state.viewOrEdit === "Viewer" ? (
                          <div>
                            <i className="far fa-eye"></i>&nbsp;Viewer
                          </div>
                        ) : (
                          <div>
                            <i className="fas fa-edit"></i>&nbsp;Edit
                          </div>
                        )
                      }
                      viewOrEdit={state.viewOrEdit}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 userPermissionsRow">
                  <div>
                    <PeopleInput
                      parentCallback={usersPermissionsSelect}
                      userPermissionsState={state.userPermissionsState}
                      props={state.viewOrEdit}
                    />
                  </div>
                </div>
              </div>
              <div className="row viewableRow">
                <div className="col-md-3"></div>
                <div className="col-md-6">
                  <div className="row">
                    <div className="col-md-4">Make Viewable to all:</div>
                    <div className="col-md-4">
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <IOSSwitch
                              checked={state.checkedAdmins}
                              onChange={handleChange}
                              name="checkedAdmins"
                            />
                          }
                          label="Admins"
                        />
                        <FormControlLabel
                          control={
                            <IOSSwitch
                              checked={state.checkedInstructors}
                              onChange={handleChange}
                              name="checkedInstructors"
                            />
                          }
                          label="Instructors"
                        />
                        <FormControlLabel
                          control={
                            <IOSSwitch
                              checked={state.checkedStudentLeaders}
                              onChange={handleChange}
                              name="checkedStudentLeaders"
                            />
                          }
                          label="Student Leaders"
                        />
                      </FormGroup>
                    </div>
                  </div>
                </div>
                <div className="col-md-4"></div>
              </div>

              <div className="row">
                <div className="col-md-5"></div>
                <div className="col-md-2">
                  <button className="viewBtn">View</button>
                </div>
                <div className="col-md-5"></div>
              </div>
            </div>
          </div>
          <div className="col-md-1"></div>
        </div>
      </div>

      <div className="welcome-box top-border width-full">
        <div className="description">
          <Graph />
        </div>
      </div>
    </div>
  );
};

export default MetricsTab;
