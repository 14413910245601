import React, { useState, useEffect } from "react";
import "../Tabs.css";

// For the table generation
import Row from "../components/DashboardTableRows";

let mq = window.matchMedia("(max-width: 767px)");

const SchedulesTab = ({
  sessionCourseData,
  session1,
  session2,
  session3,
  session4,
}) => {
  let subject = "Astronomy 101";

  const [missingTutors, setMissingTutors] = useState(0);
  const [substituteTutors, setSubstituteTutors] = useState(3); // Just 3 for now while I figure out how to determine what tutors are substitutes

  useEffect(() => {
    let dataArr = Object.values(session1);
    let missingTutorNum = 0;
    /*     let substituteTutorsArr = []; */

    for (let i = 0; i < dataArr.length; i++) {
      if (dataArr[i].tutor_name === "") {
        missingTutorNum++;
      }
      /*       if (!substituteTutorsArr.includes(dataArr[i].substitute_name)) {
        substituteTutorsArr.push(dataArr[i].substitute_name);
      } */
    }

    setMissingTutors(missingTutorNum);
  }, [missingTutors]);

  if (mq.matches) {
    return (
      <div className="welcome-box top-border width-full">
        <div className="heading">Session Schedules</div>
        <br />
        <table className="courses-table-admin">
          <tr>
            <th>Instructor</th>
            <th>Course Name</th>
          </tr>
          {/* Currently taking a JSON object of dummy data */}
          {sessionCourseData.map((row) => {
            const rowArr = Object.values(row);
            return <Row row={rowArr} />;
          })}
        </table>
        <div className="description">
          Sessions for {subject} can be viewed below
        </div>
        <div className="description-instruction font18">
          {missingTutors} session is missing a tutor
        </div>
        <div className="description-instruction font18">
          {substituteTutors} sessions have a substituted tutor
        </div>
        <br />
        <table className="mobileSessionTable">
          {/* Currently taking a JSON object of dummy data */}
          {session1.map((row) => {
            const rowArr = Object.values(row);
            return <Row row={rowArr} />;
          })}
        </table>
        <table className="mobileSessionTable">
          <tr>
            <th></th>
            {/* Dummy Data date */}
            <th className="mobileSessionDate">Tomorrow, January 12th</th>
          </tr>
          {/* Currently taking a JSON object of dummy data */}
          {session2.map((row) => {
            const rowArr = Object.values(row);
            return <Row row={rowArr} />;
          })}
        </table>
        <table className="mobileSessionTable">
          <tr>
            <th></th>
            {/* Dummy Data date */}
            <th className="mobileSessionDate">Wednesday, January 13th</th>
          </tr>
          {/* Currently taking a JSON object of dummy data */}
          {session3.map((row) => {
            const rowArr = Object.values(row);
            return <Row row={rowArr} />;
          })}
        </table>
        <table className="mobileSessionTable">
          <tr>
            <th></th>
            {/* Dummy Data date */}
            <th className="mobileSessionDate">Thursday, January 14th</th>
          </tr>
          {/* Currently taking a JSON object of dummy data */}
          {session4.map((row) => {
            const rowArr = Object.values(row);
            return <Row row={rowArr} />;
          })}
        </table>
      </div>
    );
  } else {
    return (
      <div className="welcome-box top-border width-full">
        <div className="heading">Session Schedules</div>
        <br />
        <table className="courses-table-admin">
          <tr>
            <th>Instructor</th>
            <th>Course Name</th>
          </tr>
          {/* Currently taking a JSON object of dummy data */}
          {sessionCourseData.map((row) => {
            const rowArr = Object.values(row);
            return <Row row={rowArr} />;
          })}
        </table>
        <div className="description">
          Sessions for {subject} can be viewed below
        </div>
        <div className="description-instruction font18">
          {missingTutors} session is missing a tutor
        </div>
        <div className="description-instruction font18">
          {substituteTutors} sessions have a substituted tutor
        </div>
        <table className="desktopSessionTable">
          <tr>
            <th></th>
            <th>Time</th>
            <th>Duration</th>
            <th>Tutor</th>
            <th>Attending</th>
            <th>Group</th>
            <th>Location</th>
          </tr>
          {/* Currently taking a JSON object of dummy data */}
          {session1.map((row) => {
            const rowArr = Object.values(row);
            return <Row row={rowArr} />;
          })}
        </table>
        <table className="desktopSessionTable">
          <tr>
            <th></th>
            {/* Dummy Data date */}
            <th className="desktopSessionDate">Tomorrow, January 12th</th>
          </tr>
          {/* Currently taking a JSON object of dummy data */}
          {session2.map((row) => {
            const rowArr = Object.values(row);
            return <Row row={rowArr} />;
          })}
        </table>
        <table className="desktopSessionTable">
          <tr>
            <th></th>
            {/* Dummy Data date */}
            <th className="desktopSessionDate">Wednesday, January 13th</th>
          </tr>
          {/* Currently taking a JSON object of dummy data */}
          {session3.map((row) => {
            const rowArr = Object.values(row);
            return <Row row={rowArr} />;
          })}
        </table>
        <table className="desktopSessionTable">
          <tr>
            <th></th>
            {/* Dummy Data date */}
            <th className="desktopSessionDate">Thursday, January 14th</th>
          </tr>
          {/* Currently taking a JSON object of dummy data */}
          {session4.map((row) => {
            const rowArr = Object.values(row);
            return <Row row={rowArr} />;
          })}
        </table>
      </div>
    );
  }
};

export default SchedulesTab;
