import React, { useState, useEffect } from 'react';

import Table from '../components/Table/NeoboardTable';

import {
  messagingData1,
  messagingData2,
  messagingData3,
} from '../../../../dummyData/adminMessagingData';
import { Fragment } from 'react';

let CoursesTab = ({}) => {
  //   console.log(messagingData1); // The data imported from js file (later to be from database)
  const [tableData1, setTableData1] = useState(messagingData1);
  const [tableData2, setTableData2] = useState(messagingData2);
  const [tableData3, setTableData3] = useState(messagingData3);

  return (
    <Fragment>
      <div className='welcome-box top-border width-full'>
        <div className='heading'>Class Messaging Channels</div>
        <div className='description'>
          Control Messaging Channels for each course. Note that all channels are
          viewable by student leaders, and can share content inside of their LMS
          course content material
        </div>

        <div className='neoboard-table-container'>
          <Table
            //   editable={true}
            headers={[
              'Channel Name',
              'Type',
              'Enabled?',
              'External File Sharing?',
              'Instructor Particpant?',
              'Anonymous Messaging?',
              'Grouping Method',
            ]}
            items={tableData1}
            //   addButton={'+ Add Course'}
            delButton={true}
            bgColor={'bg-color-blue'}
            onTableUpdate={(event) => {
              let dataCopy = tableData1; // Copy of the table data

              // item is the name of the variable for an item
              console.log(event.target);
              let item = {
                id: event.target.id,
                name: event.target.name,
                value: event.target.value,
              };
              let rows = dataCopy.slice();
              let newRows = rows.map((row) => {
                // for each key in row
                for (let key in row) {
                  // if key is the same as item name AND id is the same as item id, then set the key to the item value
                  if (key === item.name && row.id === item.id) {
                    row[key] = item.value;
                  }
                }
                return row;
              });
              setTableData1([...newRows]);
              console.log(tableData1);
            }}
            onRowAdd={(event) => {
              let dataCopy = tableData1; // Copy of the table data
              let id = (
                new Date() + Math.floor(Math.random() * 999999)
              ).toString(36); // Generate random id to associate to each item (this should be unnessecary and removable once there is a database that automatically assigns unique ID's)

              // Array of the keys of each key/value pair we want for each row
              // Dynamically based off the data we put into the table
              let itemKeys = Object.keys(dataCopy[0]);

              // Empty container that will become the object a new row is based on
              let itemToAdd = {};

              // For each key (in a key/value pair) for a rows data...
              itemKeys.map((key, index) => {
                // For the first key which is always id, set it to a random id
                if (index === 0) {
                  itemToAdd[`${key}`] = id;
                } else {
                  // Else, dynamically generate a key with an empty value, as a new row will not have values input yet
                  itemToAdd[`${key}`] = '';
                }
              });
              // Push the object of keys with empty values to our data copy
              dataCopy.push(itemToAdd);
              // Set state so that it updates on the front end with an empty row
              setTableData1([...dataCopy]);
            }}
            onRowDel={(event) => {
              let dataCopy = tableData1; // Copy of the table data
              let rowDataCopy = tableData1[0]; // Copy of the table data

              let index = dataCopy
                .map((item) => {
                  return item.id;
                })
                .indexOf(event.target.name); // Index of the row to delete

              console.log(rowDataCopy);
              dataCopy.splice(index, 1); // Splice it from the array of table rows

              // If array would not exist, is not an array, or is empty
              if (!Array.isArray(dataCopy) || !dataCopy.length) {
                ////////////////////////////////////////////////////////////////////
                console.log('Non array case');
                console.log(rowDataCopy);

                let id = (
                  new Date() + Math.floor(Math.random() * 999999)
                ).toString(36); // Generate random id to associate to each item (this should be unnessecary and removable once there is a database that automatically assigns unique ID's)

                // Array of the keys of each key/value pair we want for each row
                // Dynamically based off the data we put into the table
                let itemKeys = Object.keys(rowDataCopy);
                console.log(itemKeys);

                // Empty container that will become the object a new row is based on
                let itemToAdd = {};

                // For each key (in a key/value pair) for a rows data...
                itemKeys.map((key, index) => {
                  // For the first key which is always id, set it to a random id
                  if (index === 0) {
                    itemToAdd[`${key}`] = id;
                  } else {
                    // Else, dynamically generate a key with an empty value, as a new row will not have values input yet
                    itemToAdd[`${key}`] = '';
                  }
                });
                console.log(itemToAdd);
                // Push the object of keys with empty values to our data copy
                /*                     rowDataCopy.push(itemToAdd); */
                // Set state so that it updates on the front end with an empty row
                setTableData1([itemToAdd]);

                ////////////////////////////////////////////////////////////////////
              } else {
                setTableData1([...dataCopy]); // Set the state to the new current table
              }
            }}
          />
        </div>

        {/*  */}
        {/*  */}
        {/*  */}
        {/*  */}
        {/*  */}
        {/*  */}
        {/*  */}

        <div className='neoboard-table-container'>
          <Table
            editable={true}
            headers={['', '', '', '', '', '', '']}
            items={tableData2}
            addButton={'+ Add Course'}
            delButton={true}
            bgColor={'bg-color-blue'}
            onTableUpdate={(event) => {
              let dataCopy = tableData2; // Copy of the table data

              // item is the name of the variable for an item
              console.log(event.target);
              let item = {
                id: event.target.id,
                name: event.target.name,
                value: event.target.value,
              };
              let rows = dataCopy.slice();
              let newRows = rows.map((row) => {
                // for each key in row
                for (let key in row) {
                  // if key is the same as item name AND id is the same as item id, then set the key to the item value
                  if (key === item.name && row.id === item.id) {
                    row[key] = item.value;
                  }
                }
                return row;
              });
              setTableData2([...newRows]);
              console.log(tableData2);
            }}
            onRowAdd={(event) => {
              let dataCopy = tableData2; // Copy of the table data
              console.log(dataCopy);
              let id = (
                new Date() + Math.floor(Math.random() * 999999)
              ).toString(36); // Generate random id to associate to each item (this should be unnessecary and removable once there is a database that automatically assigns unique ID's)

              // Array of the keys of each key/value pair we want for each row
              // Dynamically based off the data we put into the table
              let itemKeys = Object.keys(dataCopy[0]);
              let itemValues = Object.values(dataCopy[0]);

              // Empty container that will become the object a new row is based on
              let itemToAdd = {};

              // For each key (in a key/value pair) for a rows data...
              itemKeys.map((key, index) => {
                // For the first key which is always id, set it to a random id
                if (index === 0) {
                  itemToAdd[`${key}`] = id;
                } else {
                  // Else, dynamically generate a key with an empty value, as a new row will not have values input yet
                  switch (typeof itemValues[index]) {
                    case 'string':
                      itemToAdd[`${key}`] = '';
                      break;
                    case 'boolean':
                      itemToAdd[`${key}`] = false;
                      break;
                    case 'object':
                      itemToAdd[`${key}`] = itemValues[index];
                      break;
                    default:
                      itemToAdd[`${key}`] = '';
                  }
                }
              });
              // Push the object of keys with empty values to our data copy
              dataCopy.push(itemToAdd);
              // Set state so that it updates on the front end with an empty row
              setTableData2([...dataCopy]);
            }}
            onRowDel={(event) => {
              let dataCopy = tableData2; // Copy of the table data
              let rowDataCopy = tableData2[0]; // Copy of the table data

              let index = dataCopy
                .map((item) => {
                  return item.id;
                })
                .indexOf(event.target.name); // Index of the row to delete

              console.log(rowDataCopy);
              dataCopy.splice(index, 1); // Splice it from the array of table rows

              // If array would not exist, is not an array, or is empty
              if (!Array.isArray(dataCopy) || !dataCopy.length) {
                ////////////////////////////////////////////////////////////////////
                console.log('Non array case');
                console.log(rowDataCopy);

                let id = (
                  new Date() + Math.floor(Math.random() * 999999)
                ).toString(36); // Generate random id to associate to each item (this should be unnessecary and removable once there is a database that automatically assigns unique ID's)

                // Array of the keys of each key/value pair we want for each row
                // Dynamically based off the data we put into the table
                let itemKeys = Object.keys(rowDataCopy);
                console.log(itemKeys);

                // Empty container that will become the object a new row is based on
                let itemToAdd = {};

                // For each key (in a key/value pair) for a rows data...
                itemKeys.map((key, index) => {
                  // For the first key which is always id, set it to a random id
                  if (index === 0) {
                    itemToAdd[`${key}`] = id;
                  } else {
                    // Else, dynamically generate a key with an empty value, as a new row will not have values input yet
                    itemToAdd[`${key}`] = '';
                  }
                });
                console.log(itemToAdd);
                // Push the object of keys with empty values to our data copy
                /*                     rowDataCopy.push(itemToAdd); */
                // Set state so that it updates on the front end with an empty row
                setTableData2([itemToAdd]);

                ////////////////////////////////////////////////////////////////////
              } else {
                setTableData2([...dataCopy]); // Set the state to the new current table
              }
            }}
          />
        </div>
        <div className='settings-container'>
          <div className='toggle-container'>
            <input
              className='editable-table__cell editable-table__cell--required'
              type='checkbox'
              id={'allow-individual-accounts'}
              onChange={() => {
                console.log('toggle switch');
              }}
            />
            <div className='toggle-switch unselectable'>
              <label
                className='toggle-switch__button'
                htmlFor={'allow-individual-accounts'}
              >
                <div className='toggle-switch__indicator'>&#x2B24;</div>
                <div className='toggle-switch__blank' />
              </label>
            </div>
            <div className='toggle-label'>
              Allow instructors to create or disable individual messaging
              channels for their class
            </div>
          </div>
          <div className='toggle-container'>
            <input
              className='editable-table__cell editable-table__cell--required'
              type='checkbox'
              id={'students-private-message'}
              onChange={() => {
                console.log('toggle switch');
              }}
            />
            <div className='toggle-switch unselectable'>
              <label
                className='toggle-switch__button'
                htmlFor={'students-private-message'}
              >
                <div className='toggle-switch__indicator'>&#x2B24;</div>
                <div className='toggle-switch__blank' />
              </label>
            </div>
            <div className='toggle-label'>
              Allow students to private message one another
            </div>
          </div>
          <div className='toggle-container'>
            <input
              className='editable-table__cell editable-table__cell--required'
              type='checkbox'
              id={'students-create-channels'}
              onChange={() => {
                console.log('toggle switch');
              }}
            />
            <div className='toggle-switch unselectable'>
              <label
                className='toggle-switch__button'
                htmlFor={'students-create-channels'}
              >
                <div className='toggle-switch__indicator'>&#x2B24;</div>
                <div className='toggle-switch__blank' />
              </label>
            </div>
            <div className='toggle-label'>
              Allow students to create their own channels
            </div>
          </div>
        </div>
      </div>
      {/*  */}
      {/*  */}
      {/*  */}
      {/*  */}
      {/*  */}
      {/*  */}
      {/*  */} {/*  */}
      {/*  */}
      {/*  */}
      {/*  */}
      {/*  */}
      {/*  */}
      {/*  */} {/*  */}
      {/*  */}
      {/*  */}
      {/*  */}
      {/*  */}
      {/*  */}
      {/*  */} {/*  */}
      {/*  */}
      {/*  */}
      {/*  */}
      {/*  */}
      {/*  */}
      {/*  */} {/*  */}
      {/*  */}
      {/*  */}
      {/*  */}
      {/*  */}
      {/*  */}
      {/*  */} {/*  */}
      {/*  */}
      {/*  */}
      {/*  */}
      {/*  */}
      {/*  */}
      {/*  */} {/*  */}
      {/*  */}
      {/*  */}
      {/*  */}
      {/*  */}
      {/*  */}
      {/*  */} {/*  */}
      {/*  */}
      {/*  */}
      {/*  */}
      {/*  */}
      {/*  */}
      {/*  */} {/*  */}
      {/*  */}
      {/*  */}
      {/*  */}
      {/*  */}
      {/*  */}
      {/*  */} {/*  */}
      {/*  */}
      {/*  */}
      {/*  */}
      {/*  */}
      {/*  */}
      {/*  */}
      <div className='welcome-box top-border width-full'>
        <div className='heading'>Campus Messaging Channels</div>
        <div className='description'>
          Control Messaging Channels for the Campus. Note that all channels
          lebeled as campus or resource are visible to all students on the
          application, clubs are visible to invited students only
        </div>

        <div className='neoboard-table-container'>
          <Table
            editable={true}
            headers={[
              'Channel Name',
              'Type',
              'Admin Email',
              'Enabled?',
              'External File Sharing?',
              'Anonymous Messaging?',
              '',
            ]}
            items={tableData3}
            addButton={'+ Add Campus Channel'}
            delButton={true}
            bgColor={'bg-color-blue'}
            onTableUpdate={(event) => {
              let dataCopy = tableData3; // Copy of the table data

              // item is the name of the variable for an item
              console.log(event.target);
              let item = {
                id: event.target.id,
                name: event.target.name,
                value: event.target.value,
              };
              let rows = dataCopy.slice();
              let newRows = rows.map((row) => {
                // for each key in row
                for (let key in row) {
                  // if key is the same as item name AND id is the same as item id, then set the key to the item value
                  if (key === item.name && row.id === item.id) {
                    row[key] = item.value;
                  }
                }
                return row;
              });
              setTableData3([...newRows]);
              console.log(tableData3);
            }}
            onRowAdd={(event) => {
              let dataCopy = tableData3; // Copy of the table data
              console.log(dataCopy);
              let id = (
                new Date() + Math.floor(Math.random() * 999999)
              ).toString(36); // Generate random id to associate to each item (this should be unnessecary and removable once there is a database that automatically assigns unique ID's)

              // Array of the keys of each key/value pair we want for each row
              // Dynamically based off the data we put into the table
              let itemKeys = Object.keys(dataCopy[0]);
              let itemValues = Object.values(dataCopy[0]);

              // Empty container that will become the object a new row is based on
              let itemToAdd = {};

              // For each key (in a key/value pair) for a rows data...
              itemKeys.map((key, index) => {
                // For the first key which is always id, set it to a random id
                if (index === 0) {
                  itemToAdd[`${key}`] = id;
                } else {
                  // Else, dynamically generate a key with an empty value, as a new row will not have values input yet
                  switch (typeof itemValues[index]) {
                    case 'string':
                      itemToAdd[`${key}`] = '';
                      break;
                    case 'boolean':
                      itemToAdd[`${key}`] = false;
                      break;
                    case 'object':
                      itemToAdd[`${key}`] = itemValues[index];
                      break;
                    default:
                      itemToAdd[`${key}`] = '';
                  }
                }
              });
              // Push the object of keys with empty values to our data copy
              dataCopy.push(itemToAdd);
              // Set state so that it updates on the front end with an empty row
              setTableData3([...dataCopy]);
            }}
            onRowDel={(event) => {
              let dataCopy = tableData3; // Copy of the table data
              let rowDataCopy = tableData3[0]; // Copy of the table data

              let index = dataCopy
                .map((item) => {
                  return item.id;
                })
                .indexOf(event.target.name); // Index of the row to delete

              console.log(rowDataCopy);
              dataCopy.splice(index, 1); // Splice it from the array of table rows

              // If array would not exist, is not an array, or is empty
              if (!Array.isArray(dataCopy) || !dataCopy.length) {
                ////////////////////////////////////////////////////////////////////
                console.log('Non array case');
                console.log(rowDataCopy);

                let id = (
                  new Date() + Math.floor(Math.random() * 999999)
                ).toString(36); // Generate random id to associate to each item (this should be unnessecary and removable once there is a database that automatically assigns unique ID's)

                // Array of the keys of each key/value pair we want for each row
                // Dynamically based off the data we put into the table
                let itemKeys = Object.keys(rowDataCopy);
                console.log(itemKeys);

                // Empty container that will become the object a new row is based on
                let itemToAdd = {};

                // For each key (in a key/value pair) for a rows data...
                itemKeys.map((key, index) => {
                  // For the first key which is always id, set it to a random id
                  if (index === 0) {
                    itemToAdd[`${key}`] = id;
                  } else {
                    // Else, dynamically generate a key with an empty value, as a new row will not have values input yet
                    itemToAdd[`${key}`] = '';
                  }
                });
                console.log(itemToAdd);
                // Push the object of keys with empty values to our data copy
                /*                     rowDataCopy.push(itemToAdd); */
                // Set state so that it updates on the front end with an empty row
                setTableData3([itemToAdd]);

                ////////////////////////////////////////////////////////////////////
              } else {
                setTableData3([...dataCopy]); // Set the state to the new current table
              }
            }}
          />
        </div>

        <div className='settings-container'>
          <div className='toggle-container'>
            <input
              className='editable-table__cell editable-table__cell--required'
              type='checkbox'
              id={'students-private-message'}
              onChange={() => {
                console.log('toggle switch');
              }}
            />
            <div className='toggle-switch unselectable'>
              <label
                className='toggle-switch__button'
                htmlFor={'students-private-message'}
              >
                <div className='toggle-switch__indicator'>&#x2B24;</div>
                <div className='toggle-switch__blank' />
              </label>
            </div>
            <div className='toggle-label'>
              Allow students to private message one another
            </div>
          </div>

          <div className='toggle-container'>
            <input
              className='editable-table__cell editable-table__cell--required'
              type='checkbox'
              id={'students-create-group-channels'}
              onChange={() => {
                console.log('toggle switch');
              }}
            />
            <div className='toggle-switch unselectable'>
              <label
                className='toggle-switch__button'
                htmlFor={'students-create-group-channels'}
              >
                <div className='toggle-switch__indicator'>&#x2B24;</div>
                <div className='toggle-switch__blank' />
              </label>
            </div>
            <div className='toggle-label'>
              Allow students to create their own group channels
            </div>
          </div>
        </div>

        {/*  */}
        {/*  */}
        {/*  */}
        {/*  */}
        {/*  */}
        {/*  */}
        {/*  */}
      </div>
    </Fragment>
  );
};

export default CoursesTab;
