import React, { Fragment } from 'react';
import EditableCell from './EditableCell';
import UneditableCell from './UneditableCell';
import './NeoboardTable.css';

let mq = window.matchMedia('(max-width: 767px)');

const TableRow = ({
  onTableUpdate,
  rowItem,
  onDelEvent,
  delButton,
  placeholders,
  editable,
  onClickableCell,
}) => {
  let objKeys = Object.keys(rowItem); // They keys of the object
  objKeys.shift();

  if (mq.matches) {
    return (
      <div className='mobileCell'>
        {' '}
        <Fragment>
          <div className='cellLeft'>
            {delButton && (
              <button
                type='button'
                // Function for delete row
                onClick={onDelEvent}
                name={rowItem.id}
                className='editable-table__del-cell'
              >
                <i className='fas fa-times'></i>
              </button>
            )}
          </div>
          <div className='cellRight'>
            {editable
              ? objKeys.map((objKey, index) => (
                  <div>
                    {placeholders[index]} :
                    <EditableCell
                      onTableUpdate={onTableUpdate}
                      cellData={{
                        type: objKey,
                        value: rowItem[`${objKey}`], // bracket notation to access the value at the key passed in
                        id: rowItem.id,
                      }}
                      placeholder={placeholders[index]}
                    />
                  </div>
                ))
              : objKeys.map((objKey, index) => (
                  <UneditableCell
                    onClickableCell={onClickableCell}
                    cellData={{
                      type: objKey,
                      value: rowItem[`${objKey}`], // bracket notation to access the value at the key passed in
                      id: rowItem.id,
                    }}
                    placeholder={placeholders[index]}
                  />
                ))}
          </div>
        </Fragment>
      </div>
    );
  } else {
    return (
      <Fragment>
        {delButton && (
          <button
            type='button'
            // Function for delete row
            onClick={onDelEvent}
            name={rowItem.id}
            className='editable-table__del-cell'
          >
            <i className='fas fa-times'></i>
          </button>
        )}
        {editable
          ? objKeys.map((objKey, index) => (
              <EditableCell
                onTableUpdate={onTableUpdate}
                cellData={{
                  type: objKey,
                  value: rowItem[`${objKey}`], // bracket notation to access the value at the key passed in
                  id: rowItem.id,
                }}
                placeholder={placeholders[index]}
              />
            ))
          : objKeys.map((objKey, index) => (
              <UneditableCell
                onClickableCell={onClickableCell}
                cellData={{
                  type: objKey,
                  value: rowItem[`${objKey}`], // bracket notation to access the value at the key passed in
                  id: rowItem.id,
                }}
                placeholder={placeholders[index]}
              />
            ))}
      </Fragment>
    );
  }
};

export default TableRow;
