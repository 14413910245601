import React, { useState, useEffect, Fragment } from 'react';

import Table from '../../Canvas/pages/components/Table/NeoboardTable';

const CreateAccountTab = ({ institutionData, adminData }) => {
  const [institutionTable, setinstitutionTable] = useState(institutionData);
  const [adminTable, setAdminTable] = useState(adminData);
  /* const [tableData2, setTableData2] = useState(data2); */

  useEffect(() => {
    console.log(institutionTable);
  }, [institutionTable]);

  return (
    <Fragment>
      <div className='welcome-box top-border width-full'>
        <div className='heading'>Institution Information</div>
        <div className='description'>
          Create accounts here, note that email addresses must match the ones on
          their LMS account
        </div>

        <div className='neoboard-table-container'>
          <Table
            editable={true} // True will make the cells editable
            headers={['Entry', 'Value']}
            items={institutionTable}
            bgColor={'bg-color-blue'}
            onTableUpdate={(event) => {
              let dataCopy = institutionTable; // Copy of the table data

              // item is the name of the variable for an item
              console.log(event.target);
              let item = {
                id: event.target.id,
                name: event.target.name,
                value: event.target.value,
              };
              let rows = dataCopy.slice();
              let newRows = rows.map((row) => {
                // for each key in row
                for (let key in row) {
                  // if key is the same as item name AND id is the same as item id, then set the key to the item value
                  if (key === item.name && row.id === item.id) {
                    row[key] = item.value;
                  }
                }
                return row;
              });
              setinstitutionTable([...newRows]);
              console.log(institutionTable);
            }}
            onRowAdd={(event) => {
              let dataCopy = institutionTable; // Copy of the table data
              let id = (
                new Date() + Math.floor(Math.random() * 999999)
              ).toString(36); // Generate random id to associate to each item (this should be unnessecary and removable once there is a database that automatically assigns unique ID's)

              // Array of the keys of each key/value pair we want for each row
              // Dynamically based off the data we put into the table
              let itemKeys = Object.keys(dataCopy[0]);

              // Empty container that will become the object a new row is based on
              let itemToAdd = {};

              // For each key (in a key/value pair) for a rows data...
              itemKeys.map((key, index) => {
                // For the first key which is always id, set it to a random id
                if (index === 0) {
                  itemToAdd[`${key}`] = id;
                } else {
                  // Else, dynamically generate a key with an empty value, as a new row will not have values input yet
                  itemToAdd[`${key}`] = '';
                }
              });
              // Push the object of keys with empty values to our data copy
              dataCopy.push(itemToAdd);
              // Set state so that it updates on the front end with an empty row
              setinstitutionTable([...dataCopy]);
              console.log(institutionTable);
            }}
          />
        </div>

        {/*  */}
        <button
          onClick={() => {
            const data = institutionTable;

            console.log(data);

            console.log(
              JSON.stringify({
                institution_name: data[0].value,
                institution_address: data[1].value,
                billing_address: data[2].value,
                LMS_admin_name: data[3].value,
                LMS_admin_email: data[4].value,
                LMS_admin_phone: data[5].value,
                institution_LMS_URL: data[6].value,
                trial_end_date: data[7].value,
              })
            );

            fetch(
              'https://a3ytcynnh6.execute-api.us-west-2.amazonaws.com/Beta/engineer/institution',
              {
                mode: 'no-cors', // I have to have this otherwise I get the error I showed you guys
                method: 'POST', // or 'PUT'
                headers: {
                  'Content-Type': 'application/json',
                  'Access-Control-Allow-Origin': '*',
                },
                body: JSON.stringify({
                  institution_name: data[0].value,
                  institution_address: data[1].value,
                  billing_address: data[2].value,
                  LMS_admin_name: data[3].value,
                  LMS_admin_email: data[4].value,
                  LMS_admin_phone: data[5].value,
                  institution_LMS_URL: data[6].value,
                  trial_end_date: data[7].value,
                }),
              }
            )
              .then((response) => data)
              .then((data) => {
                console.log('Success:', data);
              })
              .catch((error) => {
                console.error('Error:', error);
              });
          }}
          className='dashboard-button'
        >
          Submit
        </button>
      </div>

      {/* ADMIN TABLE */}
      {/* ADMIN TABLE */}
      {/* ADMIN TABLE */}
      {/* ADMIN TABLE */}
      {/* ADMIN TABLE */}
      {/* ADMIN TABLE */}
      <div className='welcome-box top-border width-full'>
        <div className='neoboard-table-container'>
          <Table
            editable={true}
            headers={[
              'Program Director',
              'Email Address',
              'Business Number',
              'Program Name',
            ]}
            items={adminTable}
            delButton={true}
            addButton={'+ Add Admin'}
            bgColor={'bg-color-blue'}
            onTableUpdate={(event) => {
              let dataCopy = adminTable; // Copy of the table data

              // item is the name of the variable for an item
              console.log(event.target);
              let item = {
                id: event.target.id,
                name: event.target.name,
                value: event.target.value,
              };
              let rows = dataCopy.slice();
              let newRows = rows.map((row) => {
                // for each key in row
                for (let key in row) {
                  // if key is the same as item name AND id is the same as item id, then set the key to the item value
                  if (key === item.name && row.id === item.id) {
                    row[key] = item.value;
                  }
                }
                return row;
              });
              setAdminTable([...newRows]);
              console.log(adminTable);
            }}
            onRowAdd={(event) => {
              let dataCopy = adminTable; // Copy of the table data
              let id = (
                new Date() + Math.floor(Math.random() * 999999)
              ).toString(36); // Generate random id to associate to each item (this should be unnessecary and removable once there is a database that automatically assigns unique ID's)

              // Array of the keys of each key/value pair we want for each row
              // Dynamically based off the data we put into the table
              let itemKeys = Object.keys(dataCopy[0]);

              // Empty container that will become the object a new row is based on
              let itemToAdd = {};

              // For each key (in a key/value pair) for a rows data...
              itemKeys.map((key, index) => {
                // For the first key which is always id, set it to a random id
                if (index === 0) {
                  itemToAdd[`${key}`] = id;
                } else {
                  // Else, dynamically generate a key with an empty value, as a new row will not have values input yet
                  itemToAdd[`${key}`] = '';
                }
              });
              // Push the object of keys with empty values to our data copy
              dataCopy.push(itemToAdd);
              // Set state so that it updates on the front end with an empty row
              setAdminTable([...dataCopy]);
              console.log(adminTable);
            }}
            onRowDel={(event) => {
              let dataCopy = adminTable; // Copy of the table data
              let rowDataCopy = adminTable[0]; // Copy of the table data

              let index = dataCopy
                .map((item) => {
                  return item.id;
                })
                .indexOf(event.target.name); // Index of the row to delete

              console.log(rowDataCopy);
              dataCopy.splice(index, 1); // Splice it from the array of table rows

              // If array would not exist, is not an array, or is empty
              if (!Array.isArray(dataCopy) || !dataCopy.length) {
                ////////////////////////////////////////////////////////////////////
                console.log('Non array case');
                console.log(rowDataCopy);

                let id = (
                  new Date() + Math.floor(Math.random() * 999999)
                ).toString(36); // Generate random id to associate to each item (this should be unnessecary and removable once there is a database that automatically assigns unique ID's)

                // Array of the keys of each key/value pair we want for each row
                // Dynamically based off the data we put into the table
                let itemKeys = Object.keys(rowDataCopy);
                console.log(itemKeys);

                // Empty container that will become the object a new row is based on
                let itemToAdd = {};

                // For each key (in a key/value pair) for a rows data...
                itemKeys.map((key, index) => {
                  // For the first key which is always id, set it to a random id
                  if (index === 0) {
                    itemToAdd[`${key}`] = id;
                  } else {
                    // Else, dynamically generate a key with an empty value, as a new row will not have values input yet
                    itemToAdd[`${key}`] = '';
                  }
                });
                console.log(itemToAdd);
                // Push the object of keys with empty values to our data copy
                /*                     rowDataCopy.push(itemToAdd); */
                // Set state so that it updates on the front end with an empty row
                setAdminTable([itemToAdd]);

                ////////////////////////////////////////////////////////////////////
              } else {
                setAdminTable([...dataCopy]); // Set the state to the new current table
              }
            }}
          />
        </div>
      </div>
    </Fragment>
  );
};

export default CreateAccountTab;
