import React from "react";
import "./Section6.css";
import Mailto from "reactv16-mailto";



function Section6Stu() {
  return (
    <div className="section6-body">
      <div className="row">
        <div className="col-md-2"></div>

        <div className="col-md-8">
          <div className="row">
            <div className="col-md-12">
              <h1 className="section6-title">Do not fall behind!</h1>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12 margin-top-smaller">
              <p className="section6-text h6">
              Save yourself time and headaches by using Neoboard today!
              </p>
            </div>
          </div>

          <div className="row">
              <div className="col-md-4"></div>
              <div className="col-md-4">
                  <div className="section6-button-container">
                    
                    <Mailto email="classroomquestions@neoboardapp.com" obfuscate={true}>
                    <div className="section6-button">GET UPDATES TODAY</div>
                </Mailto></div>
                  
              </div>
              <div className="col-md-4"></div>
          </div>
        </div>




        <div className="col-md-2"></div>
      </div>
    </div>
  );
}

export default Section6Stu;
