import React, { useState, useEffect } from "react";

import html2canvas from "html2canvas";

import "./graph.css";

import quarterData from "./quarter.json";
import yearData from "./year.json";
import jsPDF from "jspdf";

import GraphChild from "./GraphChild";

const colorCodes = [
  "",
  "#e8ecfb",
  "#d9cce3",
  "",
  "#d9cce3",
  "#b98eb3",
  "",
  "#aa6f9f",
  "#994f88",
  "#882d71",
  "#1964b0",
  "#427dbf",
  "",
  "#6095d0",
  "#7bb0df",
  "#4db264",
  "#90c786",
  "#cae1ac",
  "#f7f057",
  "#f7ca46",
  "",
  "#f4a637",
  "",
  "#ee8026",
  "",
  "#e65519",
  "#db060b",
  "#a4180e",
  "#71190d",
  "#421509",
];

let colorPyramid = {
  0: [""],
  1: [colorCodes[10]],
  2: [colorCodes[10], colorCodes[26]],
  3: [colorCodes[10], colorCodes[18], colorCodes[26]],
  4: [colorCodes[10], colorCodes[15], colorCodes[18], colorCodes[26]],
  5: [
    colorCodes[10],
    colorCodes[14],
    colorCodes[15],
    colorCodes[18],
    colorCodes[26],
  ],
  6: [
    colorCodes[10],
    colorCodes[14],
    colorCodes[15],
    colorCodes[17],
    colorCodes[18],
    colorCodes[26],
  ],
  7: [
    colorCodes[9],
    colorCodes[10],
    colorCodes[14],
    colorCodes[15],
    colorCodes[17],
    colorCodes[18],
    colorCodes[26],
  ],
  8: [
    colorCodes[9],
    colorCodes[10],
    colorCodes[14],
    colorCodes[15],
    colorCodes[17],
    colorCodes[18],
    colorCodes[23],
    colorCodes[26],
  ],
  9: [
    colorCodes[9],
    colorCodes[10],
    colorCodes[14],
    colorCodes[15],
    colorCodes[17],
    colorCodes[18],
    colorCodes[23],
    colorCodes[26],
    colorCodes[28],
  ],
  10: [
    colorCodes[9],
    colorCodes[10],
    colorCodes[14],
    colorCodes[15],
    colorCodes[17],
    colorCodes[18],
    colorCodes[21],
    colorCodes[24],
    colorCodes[26],
    colorCodes[28],
  ],
  11: [
    colorCodes[9],
    colorCodes[10],
    colorCodes[12],
    colorCodes[14],
    colorCodes[15],
    colorCodes[17],
    colorCodes[18],
    colorCodes[21],
    colorCodes[24],
    colorCodes[26],
    colorCodes[28],
  ],
  12: [
    colorCodes[3],
    colorCodes[6],
    colorCodes[9],
    colorCodes[10],
    colorCodes[12],
    colorCodes[14],
    colorCodes[15],
    colorCodes[17],
    colorCodes[18],
    colorCodes[21],
    colorCodes[24],
    colorCodes[26],
  ],
  13: [
    colorCodes[3],
    colorCodes[6],
    colorCodes[9],
    colorCodes[10],
    colorCodes[12],
    colorCodes[14],
    colorCodes[15],
    colorCodes[16],
    colorCodes[17],
    colorCodes[18],
    colorCodes[21],
    colorCodes[24],
    colorCodes[26],
  ],
  14: [
    colorCodes[3],
    colorCodes[6],
    colorCodes[9],
    colorCodes[10],
    colorCodes[12],
    colorCodes[14],
    colorCodes[15],
    colorCodes[16],
    colorCodes[17],
    colorCodes[18],
    colorCodes[20],
    colorCodes[22],
    colorCodes[24],
    colorCodes[26],
  ],
  15: [
    colorCodes[3],
    colorCodes[6],
    colorCodes[9],
    colorCodes[10],
    colorCodes[12],
    colorCodes[14],
    colorCodes[15],
    colorCodes[16],
    colorCodes[17],
    colorCodes[18],
    colorCodes[20],
    colorCodes[22],
    colorCodes[24],
    colorCodes[26],
    colorCodes[28],
  ],
  16: [
    colorCodes[3],
    colorCodes[5],
    colorCodes[7],
    colorCodes[9],
    colorCodes[10],
    colorCodes[12],
    colorCodes[14],
    colorCodes[15],
    colorCodes[16],
    colorCodes[17],
    colorCodes[18],
    colorCodes[20],
    colorCodes[22],
    colorCodes[24],
    colorCodes[26],
    colorCodes[28],
  ],
  17: [
    colorCodes[3],
    colorCodes[5],
    colorCodes[7],
    colorCodes[8],
    colorCodes[9],
    colorCodes[10],
    colorCodes[12],
    colorCodes[14],
    colorCodes[15],
    colorCodes[16],
    colorCodes[17],
    colorCodes[18],
    colorCodes[20],
    colorCodes[22],
    colorCodes[24],
    colorCodes[26],
    colorCodes[28],
  ],
  18: [
    colorCodes[3],
    colorCodes[5],
    colorCodes[7],
    colorCodes[8],
    colorCodes[9],
    colorCodes[10],
    colorCodes[12],
    colorCodes[14],
    colorCodes[15],
    colorCodes[16],
    colorCodes[17],
    colorCodes[18],
    colorCodes[20],
    colorCodes[22],
    colorCodes[24],
    colorCodes[26],
    colorCodes[27],
    colorCodes[28],
  ],
  19: [
    colorCodes[2],
    colorCodes[4],
    colorCodes[5],
    colorCodes[7],
    colorCodes[8],
    colorCodes[9],
    colorCodes[10],
    colorCodes[12],
    colorCodes[14],
    colorCodes[15],
    colorCodes[16],
    colorCodes[17],
    colorCodes[18],
    colorCodes[20],
    colorCodes[22],
    colorCodes[24],
    colorCodes[26],
    colorCodes[27],
    colorCodes[28],
  ],
  20: [
    colorCodes[2],
    colorCodes[4],
    colorCodes[5],
    colorCodes[7],
    colorCodes[8],
    colorCodes[9],
    colorCodes[10],
    colorCodes[11],
    colorCodes[13],
    colorCodes[14],
    colorCodes[15],
    colorCodes[16],
    colorCodes[17],
    colorCodes[18],
    colorCodes[20],
    colorCodes[22],
    colorCodes[24],
    colorCodes[26],
    colorCodes[27],
    colorCodes[28],
  ],
  21: [
    colorCodes[2],
    colorCodes[4],
    colorCodes[5],
    colorCodes[7],
    colorCodes[8],
    colorCodes[9],
    colorCodes[10],
    colorCodes[11],
    colorCodes[13],
    colorCodes[14],
    colorCodes[15],
    colorCodes[16],
    colorCodes[17],
    colorCodes[18],
    colorCodes[19],
    colorCodes[21],
    colorCodes[23],
    colorCodes[25],
    colorCodes[26],
    colorCodes[27],
    colorCodes[28],
  ],
  22: [
    colorCodes[2],
    colorCodes[4],
    colorCodes[5],
    colorCodes[7],
    colorCodes[8],
    colorCodes[9],
    colorCodes[10],
    colorCodes[11],
    colorCodes[13],
    colorCodes[14],
    colorCodes[15],
    colorCodes[16],
    colorCodes[17],
    colorCodes[18],
    colorCodes[19],
    colorCodes[21],
    colorCodes[23],
    colorCodes[25],
    colorCodes[26],
    colorCodes[27],
    colorCodes[28],
    colorCodes[29],
  ],
  23: [
    colorCodes[1],
    colorCodes[2],
    colorCodes[4],
    colorCodes[5],
    colorCodes[7],
    colorCodes[8],
    colorCodes[9],
    colorCodes[10],
    colorCodes[11],
    colorCodes[13],
    colorCodes[14],
    colorCodes[15],
    colorCodes[16],
    colorCodes[17],
    colorCodes[18],
    colorCodes[19],
    colorCodes[21],
    colorCodes[23],
    colorCodes[25],
    colorCodes[26],
    colorCodes[27],
    colorCodes[28],
    colorCodes[29],
  ],
};

function Graph() {
  const [currentData, setCurrentData] = useState(quarterData.datasets);
  const [loaded, setLoaded] = useState(0);

  function render() {
    //using this to trigger useEffect
    setLoaded(loaded + 1);
    console.log(loaded);

    function percent() {
      for (let i = 0; i < currentData.length; i++) {
        let percentArray = [];
        let size = currentData[i].classSize;
        for (let j = 0; j < currentData[i].attended.length; j++) {
          let stuAttended = currentData[i].attended[j];
          let percent = Math.trunc((stuAttended / size) * 100);
          // console.log(percent + "%");
          percentArray.push(percent);
        }
        // console.log(percentArray);
        currentData[i].data = percentArray;
      }
      // console.log(currentData);
      setCurrentData(currentData);
    }

    percent();
  }

  useEffect(() => {
    let arrayLength = currentData.length;
    let colorArray = colorPyramid[arrayLength];

    for (let i = 0; i < arrayLength; i++) {
      currentData[i].borderColor = colorArray[i];
      currentData[i].backgroundColor = colorArray[i];
    }
  }, [currentData]);

  function handleClick(e) {
    let currentSelection = e.target.value;
    if (currentSelection === "Quarter") {
      setCurrentData(quarterData.datasets);
    }
    if (currentSelection === "Year") {
      setCurrentData(yearData.datasets);
    }
  }

  function exportGraph() {
    let input = window.document.getElementsByClassName("graph")[0];

    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL("image/png", 1.0);

      var pdf = new jsPDF("p", "pt", [canvas.width, canvas.height]);
      const imgProps = pdf.getImageProperties(imgData);
      let pdfWidth = pdf.internal.pageSize.getWidth();
      pdfWidth = pdfWidth * 0.85;
      let pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
      pdfHeight = pdfHeight * 0.85;
      pdf.addImage(imgData, "PNG", 10, 10, pdfWidth, pdfHeight);
      pdf.save("attendance_graph.pdf");
    });
  }

  return (
    <div onMouseOver={render}>
      <GraphChild
        currentData={currentData}
        handleClick={handleClick}
        exportGraph={exportGraph}
      />
    </div>
  );
}

export default Graph;
