import React, { useState, useEffect } from "react";
import swal from "sweetalert";

function StuLeaderChild(props) {
  let [childCounter, setStuLeaderChildCounter] = useState(0);

  useEffect(() => {
    setStuLeaderChildCounter(childCounter);
  }, []);

  function checked(e) {
    if (e.target.checked === true) {
      addSelected(e.target.name);
      setStuLeaderChildCounter(childCounter + 1);
    }
    if (e.target.checked === false) {
      removeSelected(e.target.name);
      setStuLeaderChildCounter(childCounter - 1);
    }
  }

  function validate(e) {
    let counter = 0;

    let chosenTimes = [];
    for (let i = 0; i < props.parentData.length; i++) {
      if (props.parentData[i].selected === true) {
        counter++;
        chosenTimes.push(props.parentData[i]);
      }
    }
    if (counter > 3) {
      swal(
        `Too many sessions selected for: \n${props.parentData[0].classTitle}`,
        "Please select only 3 to prevent an error",
        "warning"
      );
    }

    if (counter === 3) {
      //send data to parent'
      console.log(counter);
      handleChange(chosenTimes);
    }
  }

  function addSelected(e) {
    props.parentData[e].selected = true;
    validate(e);
  }

  function removeSelected(e) {
    props.parentData[e].selected = false;
    validate(e);
  }

  function handleChange(chosenTimes) {
    // Here, we invoke the callback with the new value

    props.onChange(chosenTimes);
  }

  return (
    <div className="row">
      <div className="col-md-2"></div>
      <div className="col-md-8">
        <div className="poll-card padding">
          <div className="row">
            <div className="col-md-2">
              <div className="icon-circle">
                <i className="fas fa-crown session-icon"></i>
              </div>
            </div>
            <div className="col-md-8">
              <div className="session-title">
                <h4>Student Leader Schedule for</h4>
                <h4>{props.parentData[0].classTitle}</h4>
              </div>
            </div>
            <div className="col-md-2"></div>
          </div>
          <div className="row">
            <div className="col-md-2"></div>
            <div className="col-md-8">
              <div className="session-text h5">
                Your administrator has assigned you for 6{" "}
                <strong>sessions per week,</strong> each{" "}
                <strong>30 minutes.</strong> Based on your schedule and the
                schedules of existing students this quarter and past quarters,
                we have automatically selected the best times. Revise as needed.
              </div>
            </div>
            <div className="col-md-2"></div>
          </div>
          <br />
          <div className="row">
            <div className="col-md-12">
              {/* DESKTOP VIEW */}

              <table className="session__table session-desktop">
                <thead className="session__table-header">
                  <tr>
                    <th></th>
                    <th>Available Time</th>
                    <th>Student Availability</th>
                    <th>Spots Available</th>
                  </tr>
                </thead>

                <tbody>
                  {props.parentData.map((data) => (
                    <tr key={`${data.id}`} className="session__table-data">
                      <th scope="row">
                        <input
                          type="checkbox"
                          name={data.id}
                          onChange={(event) => {
                            checked(event);
                          }}
                        />
                      </th>
                      <td>{data.availableTime}</td>
                      <td>{data.studentAvailability}</td>
                      <td>{data.spotsAvailable}</td>
                    </tr>
                  ))}
                </tbody>
              </table>

              {/* END DESKTOP VIEW */}

              {/* MOBILE VIEW */}

              <div className="session__table session-mobile">
                {props.parentData.map((data, i) => (
                  <div
                    className="mobile-table"
                    key={`${data.id}_${data.studentAvailability}_mobile`}
                  >
                    <div className="table-left">
                      <input
                        type="checkbox"
                        className="table-checkbox"
                        id={data.id}
                        onChange={(event) => {
                          checked(event);
                        }}
                      />
                    </div>
                    <div className="table-right">
                      <div>
                        {" "}
                        <strong>Available Time: </strong> <br />
                        {data.availableTime}
                      </div>
                      <div>
                        {" "}
                        <strong>Availability: </strong> <br />{" "}
                        {data.studentAvailability}
                      </div>
                      <div>
                        {" "}
                        <strong>Spots Available: </strong> <br />{" "}
                        {data.spotsAvailable}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              {/* END MOBILE VIEW  */}
              <br />
              <div className="row">
                <div className="col-md-4"></div>
                <div className="col-md-4">
                  {" "}
                  <div className="session-text h5">
                    {childCounter === 3 ? (
                      <i className="fas fa-check-circle session-checks"></i>
                    ) : (
                      <i className="fas fa-times-circle session-x"></i>
                    )}
                    Sessions selected: {childCounter}/3
                  </div>
                  <div className="session-text h5">
                    <i className="fas fa-check-circle session-checks"></i>
                    Student Accessibility: 100%
                  </div>
                </div>
                <div className="col-md-4"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-2"></div>
    </div>
  );
}

export default StuLeaderChild;
