import React from 'react';
import '../../Canvas.css';

const UploadCompleteModal = ({ func }) => {
    return (
        <div className='welcome-box top-border complete-modal'>
            <div className='heading green-bold'>
                <i class='far fa-check-circle'></i>
            </div>
            <div className='description'>
                Upload complete, your students will now be emailed their poll
                questions momentarily. Groups will be assigned after filing out
                their polls based on ther responses.
            </div>
            <div
                onClick={func}
                className='permission-submit double-margin-buffer unselectable small-margin-top'
            >
                Return to Dashboard
            </div>
        </div>
    );
};

export default UploadCompleteModal;
