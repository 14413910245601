import React, { Fragment, useState, useEffect } from 'react';

import { Link } from 'react-router-dom';

// For the table generation
import Row from './components/DashboardTableRows';

const SIRecomendation = ({ students }) => {
  const [studentAmount] = useState(students.length - 1);

  const [courseName, setCourseName] = useState(null);

  let table = students.slice(1);

  const [rowsToDelete, setRowsToDelete] = useState([]);

  useEffect(() => {
    setCourseName(students[0].course_name);
  }, []);

  // Function to call when checkbox is checked
  const handleCheckboxClick = (event) => {
    let currentRowsToDelete = rowsToDelete;
    let id = event.target.name;

    if (event.target.checked) {
      // Add to deleteState
      currentRowsToDelete.push(id);
      setRowsToDelete(currentRowsToDelete);
      console.log(`added 1 item: ${rowsToDelete}`);
    } else {
      // Delete from deleteState
      console.log(currentRowsToDelete.indexOf(id));
      /*       currentRowsToDelete.indexOf(id) */
      currentRowsToDelete.splice(currentRowsToDelete.indexOf(id), 1);
      console.log(`deleted 1 item: ${rowsToDelete}`);
    }

    if (rowsToDelete[0] && true) {
      console.log('button shown');
    }
  };

  return (
    <div className='canvas-container dashboard'>
      <div className='welcome-box top-border width-full'>
        <div className='heading'>Your SI Recommendation</div>
        <div className='description'>
          All courses where you can provide SI recommendations are shown below.
          Use the left and right arrows below to switch between courses
        </div>
        <Link
          /* Link currently does nothing (just goes back to the same page) */
          to='/canvas/recommendations'
          className='dashboard-button-update double-margin-buffer min-width-200'
        >
          Update via Canvas
        </Link>
      </div>

      <div className='welcome-box top-border width-full'>
        <div className='heading'>{courseName} Recommendations</div>
        <div className='description'>
          All students shown below meet the minimum grade of B for the course
          and GPA {'>'} 3.0. Study sessions were in groups of 4, and students should
          average 25% participation. Please select which students you recommend
          to be the SI for the next course
        </div>
        {/* Currently assuming uploaded is true by sending into this component as a prop with the value true */}
        <Fragment>
          <div className='description-instruction font18'>
            {studentAmount} Students
          </div>

          <table>
            <tr>
              <th></th>
              <th>Name</th>
              <th>Messages Sent</th>
              <th>Avg Session Participation</th>
              <th>Attendance (out of 8)</th>
              <th>Badges from Course</th>
              <th>Additional Comments</th>
            </tr>
            {table.map((row) => {
              const rowArr = Object.values(row);
              const rowKeys = Object.keys(row); // array of keys
              return (
                <Row row={rowArr} keys={rowKeys} func={handleCheckboxClick} />
              );
            })}
          </table>

          {rowsToDelete[0] && (
            <div onClick={''} className='dashboard-button double-margin-buffer'>
              Delete
            </div>
          )}

          <div
            onClick={''}
            className='dashboard-button-update double-margin-buffer'
          >
            Submit
          </div>
        </Fragment>
      </div>
    </div>
  );
};

export default SIRecomendation;
