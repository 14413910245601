import React, { useState, useEffect } from "react";

import classList from "./classList.json";
import studentClasses from "./studentClasses.json";

import StuChild from "./StuChild";

import swal from "sweetalert";

function StuTimeSelection() {
  const [stuParentData, setStuParentData] = useState([]);
  const [stuChosen, setStuChosen] = useState([]);
  const [stuClassCount, setStuClassCount] = useState([]);

  let newArray = [];

  let all = Object.keys(classList);

  let stu = studentClasses.classes;

  for (let i = 0; i < all.length; i++) {
    if (classList[stu[i]] !== undefined) {
      newArray.push(classList[stu[i]]);
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0);

    setStuParentData(newArray);
    setStuChosen(stuChosen);
    setStuClassCount(newArray.length);
  }, []);

  function handleChange(e) {
    let dupe = false;
    const newChosen = stuChosen.slice();

    for (let i = 0; i < newChosen.length; i++) {
      if (newChosen[i][0].classTitle === e[0].classTitle) {
        //Duplicate Entry for class
        const index = newChosen.indexOf(newChosen[i]);

        newChosen[index] = e;
        dupe = true;
      }
    }
    if (dupe === false) {
      newChosen.push(e);
    }

    setStuChosen(newChosen);
  }

  function onSubmit(e) {
    //on submit, 'stuChosen' is an array of the selected sessions for each class

    e.preventDefault();
    // console.log(JSON.stringify(stuChosen));
    console.log(stuChosen);
    if (stuChosen.length < stuClassCount) {
      swal("Please select 3 sessions per class", {
        icon: "warning",
      });
    } else {
      swal({
        title: "You have completed the time selection!",
        icon: "success",
      });
    }
  }

  return (
    <form onSubmit={onSubmit}>
      <div className="poll-body blur-box mt-3">
        <div className="row">
          <div className="col-md-2"></div>
          <div className="col-md-8">
            <div className="poll-card">
              <div className="row">
                <div className="col-md-2"></div>
                <div className="col-md-8">
                  <div className="session-title">
                    <h1>Session Time Selection</h1>
                  </div>
                </div>
                <div className="col-md-2"></div>
              </div>
              <div className="row">
                <div className="col-md-2"></div>
                <div className="col-md-8">
                  <div className="title-text h3">
                    Welcome Marcus, select all times you are available for study
                    sessions!
                  </div>
                </div>
                <div className="col-md-2"></div>
              </div>
              <br />
              <div className="row">
                <div className="col-md-2"></div>
                <div className="col-md-8">
                  <div className="row">
                    <div className="col-md-5">
                      <button className="session__btn-dark" type="submit">
                        Submit
                      </button>
                    </div>
                    <div className="col-md-2"></div>
                    <div className="col-md-5">
                      <button className="session__btn-light" disabled>
                        Previous Poll
                      </button>
                    </div>
                  </div>
                </div>
                <div className="col-md-2"></div>
              </div>
            </div>
          </div>
          <div className="col-md-2"></div>
        </div>

        {/* new card - Available sessions */}

        <div className="row">
          <div className="col-md-2"></div>
          <div className="col-md-8">
            <div className="poll-card padding">
              <div className="row">
                <div className="col-md-2">
                  <div className="icon-circle">
                    <i className="fas fa-user session-icon"></i>
                  </div>
                </div>
                <div className="col-md-8">
                  <div className="session-title">
                    <h4>Available Sessions for</h4>
                    <h4>Philosophy 201</h4>
                  </div>
                </div>
                <div className="col-md-2"></div>
              </div>
              <div className="row">
                <div className="col-md-2"></div>
                <div className="col-md-8 session-text h5">
                  No action needed, your group and session time will be found
                  automatically based on your calendar availability and poll
                  responses
                </div>
                <div className="col-md-2"></div>
              </div>
            </div>
          </div>
          <div className="col-md-2"></div>
        </div>

        {newArray.map((data, i) => (
          <div key={`${data[i].classTitle}_${data.id}`}>
            <StuChild stuParentData={data} onChange={handleChange} />
          </div>
        ))}

        <div className="row">
          <div className="col-md-2"></div>
          <div className="col-md-8">
            <div className="poll-card padding">
              <div className="row">
                <div className="col-md-2"></div>
                <div className="col-md-8">
                  <div className="session-title">
                    <h4>Available Sessions for</h4>
                    <h4>British Literature - Writing Tutor Program</h4>
                  </div>
                </div>
                <div className="col-md-2"></div>
              </div>
              <br />
              <div className="row">
                <div className="col-md-2"></div>
                <div className="col-md-8 session-text h5">
                  You declined to participate in this program
                </div>
                <div className="col-md-2"></div>
              </div>
            </div>
          </div>
          <div className="col-md-2"></div>
        </div>
      </div>
    </form>
  );
}

export default StuTimeSelection;
