import React from "react";
import "./Nav.css";
import { Link } from "react-router-dom";

function Nav() {
  return (
    <div className="neo-navbar">
      <div className="row">
        <div className="col-md-1"></div>
        <div className="col-md-10">
          <div className="row">
            <div className="col-md-3">
              <div className="logo-container">
                <Link to="/">
                  <img
                    src={require("../../assets/3/Neoboard Wordmark Logo 2020 Large.png")}
                    alt="neoboard logo"
                    className="neoboard-logo"
                  ></img>
                </Link>
              </div>
            </div>
            <div className="col-md-3"></div>
            <div className="col-md-6 align-self-center">
              <ul className="nav-links">
                <Link to="/contact">
                  <li className="link">CONTACT</li>
                </Link>

                <Link to="/demo">
                  <li className="link">DEMO</li>
                </Link>

                <Link to="/login">
                  <li className="link">LOGIN</li>
                </Link>
                <Link to="/download">
                  <li className="nav-button link">DOWNLOAD THE APP</li>
                </Link>
              </ul>
            </div>
          </div>
        </div>
        <div className="col-md-1"></div>
      </div>

      {/* Displays returned Facebook User Info  */}
      {/* <div id="user-info"></div> */}
    </div>
  );
}

export default Nav;
