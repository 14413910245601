import React, { useState, Fragment } from 'react';

import Table from '../../Canvas/pages/components/Table/NeoboardTable';

const ViewEditAccountTab = ({
  institutionsData,
  // institutionData,
  allInstitutionsData,
}) => {
  const [institutionsTable, setInstitutionsTable] = useState(institutionsData); // List of insitiutions
  const [institutionTables, setInstitutionTables] = useState(null); // Selected institution
  // const [institutionTables, setInstitutionTables] = useState(null); // Admin data of selected institution

  ////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////
  let loadInstitutionInfo = (data) => {
    console.log(data);
    if (data) {
      return (
        <Fragment>
          <div className='welcome-box top-border width-full'>
            <div className='heading'>Institution Information</div>
            <div className='description'>
              Create accounts here, note that email addresses must match the
              ones on their LMS account
            </div>

            <div className='neoboard-table-container'>
              <Table
                editable={true} // True will make the cells editable
                headers={['Entry', 'Value']}
                items={data.instData}
                bgColor={'bg-color-blue'}
                onTableUpdate={(event) => {
                  let dataCopy = data.instData; // Copy of the table data

                  // item is the name of the variable for an item
                  console.log(event.target);
                  let item = {
                    id: event.target.id,
                    name: event.target.name,
                    value: event.target.value,
                  };
                  let rows = dataCopy.slice();
                  let newRows = rows.map((row) => {
                    // for each key in row
                    for (let key in row) {
                      // if key is the same as item name AND id is the same as item id, then set the key to the item value
                      if (key === item.name && row.id === item.id) {
                        row[key] = item.value;
                      }
                    }
                    return row;
                  });
                  setInstitutionTables([...newRows]);
                }}
                onRowAdd={(event) => {
                  let dataCopy = data.instData; // Copy of the table data
                  let id = (
                    new Date() + Math.floor(Math.random() * 999999)
                  ).toString(36); // Generate random id to associate to each item (this should be unnessecary and removable once there is a database that automatically assigns unique ID's)

                  // Array of the keys of each key/value pair we want for each row
                  // Dynamically based off the data we put into the table
                  let itemKeys = Object.keys(dataCopy[0]);

                  // Empty container that will become the object a new row is based on
                  let itemToAdd = {};

                  // For each key (in a key/value pair) for a rows data...
                  itemKeys.map((key, index) => {
                    // For the first key which is always id, set it to a random id
                    if (index === 0) {
                      itemToAdd[`${key}`] = id;
                    } else {
                      // Else, dynamically generate a key with an empty value, as a new row will not have values input yet
                      itemToAdd[`${key}`] = '';
                    }
                  });
                  // Push the object of keys with empty values to our data copy
                  dataCopy.push(itemToAdd);
                  // Set state so that it updates on the front end with an empty row
                  setInstitutionTables([...dataCopy]);
                  console.log(institutionTables);
                }}
                onRowDel={(event) => {
                  let dataCopy = data.instData; // Copy of the table data

                  let index = dataCopy
                    .map((item) => {
                      return item.id;
                    })
                    .indexOf(event.target.name); // Index of the row to delete

                  dataCopy.splice(index, 1); // Splice it from the array of table rows
                  setInstitutionTables([...dataCopy]); // Set the state to the new current table
                  console.log(dataCopy);
                  console.log(institutionTables);
                }}
              />
            </div>

            {/*  */}
            <div onClick={''} className='dashboard-button'>
              Submit
            </div>
          </div>

          <div className='welcome-box top-border width-full'>
            <div className='neoboard-table-container'>
              <Table
                editable={true}
                headers={[
                  'Program Director',
                  'Email Address',
                  'Business Number',
                  'Program Name',
                ]}
                items={data.adminData}
                delButton={true}
                addButton={'+ Add Admin'}
                bgColor={'bg-color-blue'}
                onTableUpdate={(event) => {
                  let dataCopy = data.adminData; // Copy of the table data

                  // item is the name of the variable for an item
                  console.log(event.target);
                  let item = {
                    id: event.target.id,
                    name: event.target.name,
                    value: event.target.value,
                  };
                  let rows = dataCopy.slice();
                  let newRows = rows.map((row) => {
                    // for each key in row
                    for (let key in row) {
                      // if key is the same as item name AND id is the same as item id, then set the key to the item value
                      if (key === item.name && row.id === item.id) {
                        row[key] = item.value;
                      }
                    }
                    return row;
                  });
                  setInstitutionTables([...newRows]);
                  console.log(institutionTables);
                }}
                onRowAdd={(event) => {
                  let dataCopy = data.adminData; // Copy of the table data
                  let id = (
                    new Date() + Math.floor(Math.random() * 999999)
                  ).toString(36); // Generate random id to associate to each item (this should be unnessecary and removable once there is a database that automatically assigns unique ID's)

                  // Array of the keys of each key/value pair we want for each row
                  // Dynamically based off the data we put into the table
                  let itemKeys = Object.keys(dataCopy[0]);

                  // Empty container that will become the object a new row is based on
                  let itemToAdd = {};

                  // For each key (in a key/value pair) for a rows data...
                  itemKeys.map((key, index) => {
                    // For the first key which is always id, set it to a random id
                    if (index === 0) {
                      itemToAdd[`${key}`] = id;
                    } else {
                      // Else, dynamically generate a key with an empty value, as a new row will not have values input yet
                      itemToAdd[`${key}`] = '';
                    }
                  });
                  // Push the object of keys with empty values to our data copy
                  dataCopy.push(itemToAdd);
                  // Set state so that it updates on the front end with an empty row
                  setInstitutionTables({
                    adminData: [...dataCopy],
                    instData: data.instData,
                  });
                  console.log(institutionTables);
                }}
                onRowDel={(event) => {
                  let dataCopy = data.adminData; // Copy of the table data
                  let rowDataCopy = data.adminData[0]; // Copy of the table data

                  let index = dataCopy
                    .map((item) => {
                      return item.id;
                    })
                    .indexOf(event.target.name); // Index of the row to delete

                  console.log(rowDataCopy);
                  dataCopy.splice(index, 1); // Splice it from the array of table rows

                  // If array would not exist, is not an array, or is empty
                  if (!Array.isArray(dataCopy) || !dataCopy.length) {
                    ////////////////////////////////////////////////////////////////////
                    console.log('Non array case');
                    console.log(rowDataCopy);

                    let id = (
                      new Date() + Math.floor(Math.random() * 999999)
                    ).toString(36); // Generate random id to associate to each item (this should be unnessecary and removable once there is a database that automatically assigns unique ID's)

                    // Array of the keys of each key/value pair we want for each row
                    // Dynamically based off the data we put into the table
                    let itemKeys = Object.keys(rowDataCopy);
                    console.log(itemKeys);

                    // Empty container that will become the object a new row is based on
                    let itemToAdd = {};

                    // For each key (in a key/value pair) for a rows data...
                    itemKeys.map((key, index) => {
                      // For the first key which is always id, set it to a random id
                      if (index === 0) {
                        itemToAdd[`${key}`] = id;
                      } else {
                        // Else, dynamically generate a key with an empty value, as a new row will not have values input yet
                        itemToAdd[`${key}`] = '';
                      }
                    });
                    console.log(itemToAdd);
                    // Push the object of keys with empty values to our data copy
                    /*                     rowDataCopy.push(itemToAdd); */
                    // Set state so that it updates on the front end with an empty row
                    setInstitutionTables({
                      adminData: [itemToAdd],
                      instData: data.instData,
                    }); // Set the state to the new current table

                    ////////////////////////////////////////////////////////////////////
                  } else {
                    setInstitutionTables({
                      adminData: [...dataCopy],
                      instData: data.instData,
                    }); // Set the state to the new current table // Set the state to the new current table
                  }
                }}
              />
            </div>
          </div>
        </Fragment>
      );
    } else {
      return;
    }
  };
  ////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////

  return (
    <Fragment>
      <div className='welcome-box top-border width-full'>
        <div className='heading'>Find Institution</div>
        <div className='description'>
          Institutions partnered with Neoboard are listed below:
        </div>

        <div className='neoboard-table-container'>
          <Table
            headers={[
              'Institution Name',
              'LMS Admin Name',
              'LMS Admin Email',
              'No. Admins',
            ]}
            items={institutionsTable}
            delButton={true}
            bgColor={'bg-color-blue'}
            editable={false}
            onTableUpdate={(event) => {
              let dataCopy = institutionsTable; // Copy of the table data

              // item is the name of the variable for an item
              console.log(event.target);
              let item = {
                id: event.target.id,
                name: event.target.name,
                value: event.target.value,
              };
              let rows = dataCopy.slice();
              let newRows = rows.map((row) => {
                // for each key in row
                for (let key in row) {
                  // if key is the same as item name AND id is the same as item id, then set the key to the item value
                  if (key === item.name && row.id === item.id) {
                    row[key] = item.value;
                  }
                }
                return row;
              });
              setInstitutionsTable([...newRows]);
              console.log(institutionsTable);
            }}
            onRowAdd={(event) => {
              let dataCopy = institutionsTable; // Copy of the table data
              let id = (
                new Date() + Math.floor(Math.random() * 999999)
              ).toString(36); // Generate random id to associate to each item (this should be unnessecary and removable once there is a database that automatically assigns unique ID's)

              // Array of the keys of each key/value pair we want for each row
              // Dynamically based off the data we put into the table
              let itemKeys = Object.keys(dataCopy[0]);

              // Empty container that will become the object a new row is based on
              let itemToAdd = {};

              // For each key (in a key/value pair) for a rows data...
              itemKeys.map((key, index) => {
                // For the first key which is always id, set it to a random id
                if (index === 0) {
                  itemToAdd[`${key}`] = id;
                } else {
                  // Else, dynamically generate a key with an empty value, as a new row will not have values input yet
                  itemToAdd[`${key}`] = '';
                }
              });
              // Push the object of keys with empty values to our data copy
              dataCopy.push(itemToAdd);
              // Set state so that it updates on the front end with an empty row
              setInstitutionsTable([...dataCopy]);
              console.log(institutionsTable);
            }}
            onRowDel={(event) => {
              let dataCopy = institutionsTable; // Copy of the table data

              let index = dataCopy
                .map((item) => {
                  return item.id;
                })
                .indexOf(event.target.name); // Index of the row to delete

              dataCopy.splice(index, 1); // Splice it from the array of table rows
              setInstitutionsTable([...dataCopy]); // Set the state to the new current table
              console.log(dataCopy);
              console.log(institutionsTable);
            }}
            onClickableCell={(event) => {
              console.log(event.target.name);
              // Check all the instData for the one with the same id (e.target.name)
              let instArr = allInstitutionsData;
              if (instArr.some((object) => object.id === event.target.name)) {
                let selectedInstInfo = instArr.find(
                  (object) => object.id == event.target.name
                ).instData;
                let selectedAdminInfo = instArr.find(
                  (object) => object.id == event.target.name
                ).adminData;

                // Call loadInstitutionData() with the info in the instData and adminData keys
                setInstitutionTables({
                  instData: selectedInstInfo,
                  adminData: selectedAdminInfo,
                });
              }
            }}
          />
        </div>
      </div>
      {loadInstitutionInfo(institutionTables)}
    </Fragment>
  );
};

export default ViewEditAccountTab;
