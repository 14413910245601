import React from "react";
import "./Footer.css";
import { Link } from "react-router-dom";
// import Mailto from "reactv16-mailto";

function Footer() {
  return (
    <div className="footer-body">
      <div className="row">
        <div className="col-md-2"></div>
        <div className="col-md-2">
          <Link to="/" alt="home" className="neo-footer-mobile">
            <p className="neo-footer-mobile-text">Neoboard</p>
            <img
              src={require("../../assets/3/Neoboard_bw.png")}
              alt="neoboard logo"
              className="footer-logo"
            ></img>
          </Link>
        </div>

        <div className="col-md-1"></div>
        <div className="col-md-5 align-self-center">
          <div className="link-container">
            <div className="footer-links">
              <Link to="/" alt="home">
                <div className="footer-link" alt="home">
                  HOME
                </div>
              </Link>
              {/*
              <Link to="/tutorials" alt="tutorials">
                <div className="footer-link" alt="tutorials">
                  TUTORIALS
                </div>
              </Link>
            */}
              {/*
              <Link to="/team" alt="team">
                <div className="footer-link" alt="team">
                  TEAM
                </div>
              </Link>
              */}
              {/*
              <Link to="/postings" alt="postings">
                <div className="footer-link" alt="careers">
                  CAREERS
                </div>
              </Link>
              */}
              <Link to="/privacy" alt="privacy">
                <div className="footer-link" alt="privacy">
                  PRIVACY
                </div>
              </Link>

              {/* <Link to="/studygrouppoll" alt="studygrouppoll">
                <div className="footer-link" alt="studygrouppoll">
                  POLL
                </div>
              </Link>

              <Link to="/studentpollsi" alt="studentpollsi">
                <div className="footer-link" alt="studentpollsi">
                  STUDENT SI POLL
                </div>
              </Link>

              <Link to="/contact" alt="contact">
                <div className="footer-link" alt="contact">
                  CONTACT
                </div>
              </Link>

              <Link to="/engineering" alt="engineering">
                <div className="footer-link" alt="engineering">
                  ENGINEERING
                </div>
              </Link>

              <Link to="/student_sessiontime" alt="student session time">
                <div className="footer-link" alt="student session time">
                  SESSION TIME- STUDENT
                </div>
              </Link>

              <Link to="/leader_sessiontime" alt="leader session time">
                <div className="footer-link" alt="leader session time">
                  SESSION TIME - LEADER
                </div>
              </Link> */}
            </div>
          </div>
        </div>
        <div className="col-md-2"></div>
      </div>
    </div>
  );
}

export default Footer;
