import React from 'react';

const Sidebar = ({
  open,
  channels,
  selectedChannel,
  chatrooms,
  selectedChatroom,
  handleChannelClick,
  handleChatroomClick,
}) => {
  return (
    <div
      className='sidebar'
      style={
        open
          ? {
              left: '0%',
            }
          : {
              left: '-100%',
            }
      }
    >
      <div className='sidebar__channels'>
        {channels.map((channel, index) => (
          <div
            className={`channel channel--${
              channel.name === selectedChannel ? 'selected' : 'unselected'
            }`}
            key={index}
            data-channel={channel.name}
            onClick={handleChannelClick} // Handle click on channel
          >
            <div
              className={`channel__icon-box channel__icon-box--${channel.color}`}
            >
              <i className={channel.icon}></i>
            </div>
            <div className='channel__heading sidebar-heading'>
              {channel.name}
            </div>
          </div>
        ))}
      </div>
      <div className='sidebar__chatrooms'>
        {chatrooms.map((room, index) => (
          <div
            className={`chatroom chatroom--${
              room.name === selectedChatroom ? 'selected' : 'unselected'
            }`}
            key={index}
            data-chatroom={room.name}
            id={room.name}
            onClick={handleChatroomClick}
          >
            <div className='chatroom__heading sidebar-heading'>{room.name}</div>
            <div className='chatroom__notifications'>
              {room.notifications.red ? (
                <span className='notification notification--red'>!</span>
              ) : room.notifications.yellow ? (
                <span className='notification notification--yellow'>
                  {room.notifications.yellow}
                </span>
              ) : room.notifications.green ? (
                <span className='notification notification--green'>
                  {room.notifications.green}
                </span>
              ) : (
                <span>&nbsp;</span>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

// Channels will be pulled from the database.
// ---- WHEN USER CLICK CHANNEL:
// -------- Set background color of that channel icon to $color-primary-medium
// -------- Pull all chatrooms associated with that channel from the database and display in the chatrooms container
// ------------ WHEN USER CLICK CHATROOM:
// ---------------- Set background color of that chatroom icon to $color-primary-medium
// ---------------- Pull all comments for that chatroom and display on the main content area

export default Sidebar;
