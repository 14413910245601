import React from "react";

import Drag from "./Drag";

function ItinerarySection() {
  return (
    <div className="itinerary-section">
      <div className="row">
        <div className="col-md-4">
          <div className="itinerary-card">
            <div className="h4 itinerary-title">Topics / Material Covered</div>
            <div className="itinerary-text h6">
              for Monday, January 17th, please order topics by priority and
              state difficulty
            </div>
            <div className="topics">
              <Drag />
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="itinerary-card">Session Plan</div>
        </div>
        <div className="col-md-4">
          <div className="itinerary-card">Session Goal</div>
        </div>
      </div>
    </div>
  );
}

export default ItinerarySection;
