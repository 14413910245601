import React from "react";
import "./Section4.css";

import { Link } from "react-router-dom";

function Section4Stu() {
  return (
    <div className="section4-body">
      <div className="row">
        <div className="col-md-2"></div>
        <div className="col-md-8">
          <h1 className="section4-title">How Neoboard Works for Students</h1>
        </div>
        <div className="col-md-2"></div>
      </div>

      <div className="row">
        <div className="col-md-2"></div>
        {/* Card row */}
        <div className="col-md-8">
          <div className="row card-container">
            <div className="col-md-4">
              <div className="card">
                {/* Icon row */}
                <div className="row">
                  <div className="col-md-12">
                    <div class="exp">
                      <i class="fas fa-cloud-download-alt icon-lh gradient-icon"></i>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-12">
                    <p className="icon-title h4">1 - Download</p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-12">
                    <p className="icon-text4 h6">
                      Download the{" "}
                      <Link to="/download" className="text-link">
                        Neoboard Application
                      </Link>
                      , and login using your LMS or social media
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card">
                {/* Icon row */}
                <div className="row">
                  <div className="col-md-12">
                    <div class="exp">
                      <i class="fas fa-user icon-lh gradient-icon"></i>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-12">
                    <p className="icon-title h4">2 - Setup</p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-12">
                    <p className="icon-text4 h6">
                      Setup your profile, and fill out a quick poll on your
                      study habits
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card">
                {/* Icon row */}
                <div className="row">
                  <div className="col-md-12">
                    <div class="exp">
                      <i class="fas fa-edit icon-lh gradient-icon"></i>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-12">
                    <p className="icon-title h4">3 - Study</p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-12">
                    <p className="icon-text4 h6">
                      Get grouped up with your classmates and start
                      collaborating and studying!
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-2"></div>
      </div>
    </div>
  );
}

export default Section4Stu;
