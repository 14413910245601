import React, { Fragment } from 'react';
import './Tutorial3.css';

function Tutorials() {
  return (
    <Fragment>
      <div className='header-container'>
        <h1 className='header'>Let's Find Your Team!</h1>
        <select className='classes'>
          <option value='Bio'>Biology 101</option>
          <option value='CS'>Computer Science 101</option>
          <option value='English'>English 101</option>
          <option value='Econ'>Economics 101</option>
        </select>
      </div>
      <p className='information'>
        We all bring something to the classroom, first begin by telling us the
        school you attend 🎓
      </p>
    </Fragment>
  );
}

export default Tutorials;
