import React from "react";
import "./Footer2.css";

function Footer2() {
  return (
    <div className="footer2-body">
      <div className="row">
        <div className="col-md-2"></div>
        <div className="col-md-8">
          <div className="row">
            <div className="col-md-3 align-self-center">
              <div className="link-container">
                <div className="footer2-links">
                  <a href="https://twitter.com/Neoboardapp" aria-label="Twitter">
                    <div className="footer2-link">
                      <i className="fab fa-twitter" alt="twitter"></i>
                    </div>
                  </a>
                  <a href="https://www.instagram.com/neoboardapp/?hl=en" aria-label="Instagram">
                    <div className="footer2-link">
                      <i className="fab fa-instagram" alt="instagram"></i>
                    </div>
                  </a>
                  <a href="https://www.facebook.com/Neoboardapp/" aria-label="Facebook">
                    <div className="footer2-link">
                      <i className="fab fa-facebook-f" alt="facebook"></i>
                    </div>
                  </a>
                  <a href="https://www.linkedin.com/company/neoboardapp/about/" aria-label="Linked In">
                    <div className="footer2-link">
                      <i className="fab fa-linkedin-in" alt="linked in"></i>
                    </div>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-md-3"></div>
            <div className="col-md-3"></div>
            <div className="col-md-3 align-self-center">
              
              <p className="copyright">
              &copy;{new Date().getFullYear()} - Neoboard | All rights reserved
              </p>
            </div>
          </div>
        </div>
        <div className="col-md-2"></div>
      </div>
    </div>
  );
}

export default Footer2;
