import React, { useState } from "react";
import "./Engineering.css";
import "./EngineeringDashboard.css";

import NewAccount from "./NewAccount";
import ViewEdit from "./ViewEdit";

const EngineeringDashboard = () => {
  const [tabState, setTabState] = useState("program-roster");

  const changeTab = (event) => {
    // Passing in the value of the value attribute for the tab which this is called from
    setTabState(event.target.value);
  };

  const renderTab = () => {
    switch (tabState) {
      case "create-new-account":
        return <NewAccount />;
      case "view-edit-accts":
        return <ViewEdit />;
      default:
        return <div></div>;
    }
  };

  return (
    <div className="engineering-container dashboard">
      <div className="welcome-box top-border width-full no-padding">
        <br />
        <div className="heading">Engineer Dashboard</div>
        <br />
        <div className="description">
          Create, edit or delete new Neoboard Admin Accounts
        </div>
        <br />
        <div className="tab-bar">
          <input
            type="radio"
            id="create-new-account"
            name="tab-nav"
            value="create-new-account"
            className="tab-radio-button-e"
            onChange={changeTab}
          />

          <label for="create-new-account" className="tab-navigation-button-e">
            <div className="inside-tab">
              <i className="fas fa-cog large-icon"></i>
              <br />
              Create New Account
            </div>
          </label>

          <input
            type="radio"
            id="view-edit-accts"
            name="tab-nav"
            value="view-edit-accts"
            className="tab-radio-button-e"
            onChange={changeTab}
          />

          <label for="view-edit-accts" className="tab-navigation-button-e">
            <div className="inside-tab">
              <i class="fas fa-tasks large-icon"></i>
              <br />
              View/Edit Accounts
            </div>
          </label>
        </div>
      </div>

      {renderTab()}
    </div>
  );
};

export default EngineeringDashboard;
