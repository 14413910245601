import React from "react";
import "./Section4Mobile.css";

import { Link } from "react-router-dom";

function Section4MobileStu() {
  return (
    <div className="section4-mobile-body">
      <div className="row">
        <div className="col-md-2"></div>
        <div className="col-md-8">
          <h1 className="section4-mobile-title">
            How Neoboard Works for Students
          </h1>
        </div>
        <div className="col-md-2"></div>
      </div>

      <div className="container-fluid card-box">
        <div className="row">
          <div className="col-md-6 card-left">
            <div class="exp">
              <i class="fas fa-cloud-download-alt icon-lh gradient-icon"></i>
            </div>
          </div>
          <div className="col-md-6 card-right">
            <h4 className="icon-title">1 - Download</h4>
            <h6 className="mobile-icon-text4">
              Download the{" "}
              <Link to="/download" className="text-link">
                Neoboard Application
              </Link>
              , and login using your LMS or social media
            </h6>
          </div>
        </div>
      </div>

      <div className="container-fluid card-box">
        <div className="row">
          <div className="col-md-6 card-left">
            <div class="exp">
              <i class="fas fa-user icon-lh gradient-icon"></i>
            </div>
          </div>
          <div className="col-md-6 card-right">
            <h4 className="icon-title">2 - Setup</h4>
            <h6 className="mobile-icon-text4">
              Setup your profile, and fill out a quick poll on your study habits
            </h6>
          </div>
        </div>
      </div>

      <div className="container-fluid card-box">
        <div className="row">
          <div className="col-md-6 card-left">
            <div class="exp">
              <i class="fas fa-edit icon-lh gradient-icon"></i>
            </div>
          </div>
          <div className="col-md-6 card-right">
            <h4 className="icon-title">3 - Study</h4>
            <h6 className="mobile-icon-text4">
              Get grouped up with your classmates and start collaborating and
              studying!
            </h6>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Section4MobileStu;
