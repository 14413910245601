import React from "react";
import { BrowserRouter as Switch, Route } from "react-router-dom";

import EngineeringWelcome from "../Engineering/EngineeringWelcome";
import EngineeringDashboard from "./EngineeringDashboard";

const Engineering = () => {
  return (
    <div className="test engineering blur-box">
      <Switch>
        <Route exact path="/engineering" component={EngineeringWelcome} />
        <Route
          exact
          path="/engineering/dashboard"
          component={EngineeringDashboard}
        />
      </Switch>
    </div>
  );
};

export default Engineering;
