import React, { Fragment } from 'react';

import TableRow from './TableRow';
import './NeoboardTable.css';

const Table = ({
  headers,
  popups,
  items,
  onTableUpdate,
  onRowAdd,
  onRowDel,
  addButton,
  delButton,
  bgColor,
  editable,
  onClickableCell,
}) => {
  let item = items.map((item) => {
    return (
      <TableRow
        onTableUpdate={onTableUpdate}
        rowItem={item}
        onDelEvent={onRowDel}
        delButton={delButton}
        key={item.id}
        placeholders={headers}
        editable={editable}
        onClickableCell={onClickableCell}
      />
    );
  });

  return (
    <Fragment>
      <div
        className={`editable-table editable-table--${
          delButton ? headers.length + 1 : headers.length
        }cols ${
          delButton ? `editable-table--del-${headers.length + 1}cols` : ''
        }`}
      >
        {delButton && (
          <div className='editable-table__del-cell editable-table__del-cell--header'></div>
        )}
        {headers.map((header, index) => (
          <div className='editable-table__cell editable-table__cell--header'>
            {header}
            {popups && (
              <span className='table-header-popup'>{popups[index]}</span>
            )}
          </div>
        ))}
        {item}
      </div>
      {addButton && (
        <button
          type='button'
          onClick={onRowAdd}
          className={`editable-table__add-button ${
            items.length % 2 !== 0 ? '' : bgColor
          }`}
        >
          {addButton}
        </button>
      )}
    </Fragment>
  );
};

export default Table;
