import React, { Fragment } from 'react';
import './Tutorial4.css';

function Tutorials() {
  return (
    <Fragment>
      <div className='header-container'>
        <h1 className='header'>Introduce Yourself!</h1>
        <div>
          <i class='icon fas fa-id-card'></i>
        </div>
        <div className='btn-container'>
          <a href='#' className='btn profile-btn'>
            Set Up Profile
          </a>
          <a href='#' className='btn skip-btn'>
            Skip
          </a>
        </div>
      </div>
      <p className='information'>
        Set up your profile, it only takes a minute and gives us some insight on
        your preferences ⚙️
      </p>
    </Fragment>
  );
}

export default Tutorials;
