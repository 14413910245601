import React, { useState, useEffect } from "react";
import "../Canvas.css";
import "./SelectInputs.css";

import { Link } from "react-router-dom";
import SelectSearch from "react-select-search";

const options = [
  { name: "Neocollege of the Sciences", value: "Neocollege of the Sciences" },
  { name: "Springfield College", value: "Springfield College" },
  {
    name: "Wildwood College of Fine Arts",
    value: "Wildwood College of Fine Arts",
  },
  { name: "Golden Oak Institute", value: "Golden Oak Institute" },
  { name: "Gotham City Univeristy", value: "Gotham City Univeristy" },
  {
    name: "Eureka Academy of Technology",
    value: "Eureka Academy of Technology",
  },
  {
    name: "Twin Peaks Community College",
    value: "Twin Peaks Community College",
  },
  { name: "Hogwarts University", value: "Hogwarts University" },
  {
    name: "Xaviers School for the Gifted",
    value: "Xaviers School for the Gifted",
  },
  { name: "Faraday Charter College", value: "Faraday Charter College" },
  {
    name: "Grandview Institute of Astronomy",
    value: "Grandview Institute of Astronomy",
  },
  { name: "Trinity Tech", value: "Trinity Tech" },
  {
    name: "Coral Springs Technical School",
    value: "Coral Springs Technical School",
  },
  { name: "Fortuna University", value: "Fortuna University" },
  { name: "Mammoth University", value: "Mammoth University" },
];

const CanvasLinkPage = () => {
  return (
    <div className="canvas-container dashboard container-padding">
      <div className="welcome-box top-border">
        <div className="heading">Welcome!</div>
        <div className="description">
          Let's get your students better connected, click below to login to
          Canvas
        </div>
        <label htmlFor="canvasEmail"></label>
        <input
          className="login-input double-margin-buffer-horizontal"
          type="text"
          placeholder="Email"
          id="canvasEmail"
        />
        <label htmlFor="canvasPassword"></label>
        <input
          className="login-input double-margin-buffer-horizontal"
          type="text"
          placeholder="Password"
          id="canvasPassword"
        />
        <SelectSearch
          search={true}
          options={options}
          value="sv"
          name="language"
          placeholder="Choose your instituion"
        />
        <Link
          to="/canvas/dashboard"
          className="permission-submit double-margin-buffer unselectable no-margin-top"
        >
          Login
        </Link>
        <div className="description-instruction">
          Instructors, please login using Canvas by clicking below.
        </div>
        <Link to="/canvas/dashboard/preupload" className="canvas-link">
          <div className="canvas-link" />
          <p className="text-link">Canvas</p>
        </Link>
      </div>
    </div>
  );
};

export default CanvasLinkPage;
