import React from "react";
import "./StuTimeSelection.css";

import StuLeaderParent from "./StuLeaderParent";

function StuLeaderTimeSelection() {
  return <StuLeaderParent />;
}

export default StuLeaderTimeSelection;
