import React from 'react';

function VerticalEllipsis() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='11'
      height='38'
      viewBox='0 0 11 38'
      className='navbar__icon icon-vertical-ellipsis'
    >
      <path d='M10.653 30.286a2.572 2.572 0 00-2.572-2.572H2.94a2.572 2.572 0 00-2.572 2.572v5.143A2.572 2.572 0 002.94 38H8.08a2.572 2.572 0 002.572-2.571v-5.143zm0-13.715A2.572 2.572 0 008.08 14H2.94a2.572 2.572 0 00-2.572 2.571v5.143a2.572 2.572 0 002.572 2.572H8.08a2.572 2.572 0 002.572-2.572v-5.143zm0-13.714A2.572 2.572 0 008.08.286H2.94A2.572 2.572 0 00.367 2.857V8a2.572 2.572 0 002.572 2.571H8.08A2.572 2.572 0 0010.653 8V2.857z'></path>
      <path d='M10.653 30.286a2.572 2.572 0 00-2.572-2.572H2.94a2.572 2.572 0 00-2.572 2.572v5.143A2.572 2.572 0 002.94 38H8.08a2.572 2.572 0 002.572-2.571v-5.143zm0-13.715A2.572 2.572 0 008.08 14H2.94a2.572 2.572 0 00-2.572 2.571v5.143a2.572 2.572 0 002.572 2.572H8.08a2.572 2.572 0 002.572-2.572v-5.143zm0-13.714A2.572 2.572 0 008.08.286H2.94A2.572 2.572 0 00.367 2.857V8a2.572 2.572 0 002.572 2.571H8.08A2.572 2.572 0 0010.653 8V2.857z'></path>
    </svg>
  );
}

export default VerticalEllipsis;
