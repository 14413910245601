import React, { useState, useEffect, Fragment } from "react";
import "../Tabs.css";

// For the table generation
import Row from "../components/DashboardTableRows";

let mq = window.matchMedia("(max-width: 767px)");

const SettingsTab = ({ settingsData, transferData }) => {
  if (mq.matches) {
    return (
      <Fragment>
        <div className="welcome-box top-border width-full">
          <div className="heading">Account Settings</div>
          <br />
          <table className="mobileSettingsTab-table">
            <tr>
              {/*             <th>Program Name</th>
              <th>Admin Contact Number</th>
              <th>Contact Number</th>
              <th>Session Leader Title</th> */}
              <th>Entry</th>
              <th>Value</th>
            </tr>
            {/* Currently taking a JSON object of dummy data */}
            {settingsData.map((row) => {
              const rowArr = Object.values(row);
              return <Row row={rowArr} />;
            })}
          </table>
        </div>
        {/* ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */}
        <div className="welcome-box top-border width-full">
          <div className="heading">Settings</div>
          {/* /////////// */}
          <div className="description lower-margin-buffer-small">
            Please select how you want students to be grouped:
          </div>

          <form className="settings-form font18" action="">
            <input type="radio" id="default" name="grouping" value="default" />
            <label for="default">Default: Emphasizes student preferences</label>
            <br />
            <input
              type="radio"
              id="networks"
              name="grouping"
              value="networks"
            />
            <label for="networks">Networks: Emphasizes network building</label>
            <br />
            <input
              type="radio"
              id="diveristy"
              name="grouping"
              value="diveristy"
            />
            <label for="diveristy">
              Diversity: Emphasizes different backgrounds
            </label>
            <br />
            <input type="radio" id="origin" name="grouping" value="origin" />
            <label for="origin">Origin: Emphasizes common backgrounds</label>
            <br />
            <input type="radio" id="hours" name="grouping" value="hours" />
            <label for="hours">
              Hours: Emphasizes available learning center hours
            </label>
            <br />
            <input
              type="radio"
              id="duration"
              name="grouping"
              value="duration"
            />
            <label for="duration">
              Duration: Emphasizes preffered duration of study session
            </label>
          </form>
          {/* /////////// */}
          <div className="description upper-margin-buffer">
            Study Session Hours:
          </div>
          <div className="dropdown-settings-input">
            <select name="session-hours" id="session-hours-start">
              <option value="12:00 AM">12:00 AM</option>
            </select>
            <span>to</span>
            <select name="session-hours" id="session-hours-end">
              <option value="12:00 AM">12:00 AM</option>
            </select>
            <div className="dropdown-settings-input font18">
              <input type="checkbox" id="24 Hours" />
              <label for="24 Hours">24 Hours</label>
            </div>
          </div>
          {/* /////////// */}
          <div className="description upper-margin-buffer">Study Sessions:</div>
          <div className="dropdown-settings-container">
            <div className="dropdown-settings-input">
              <br />
              <label htmlFor="session-month">
                Last Day for Instructor to Import Roster:
              </label>
              <select name="session-month" id="session-month">
                <option value="Jan">Jan</option>
              </select>

              <select name="session-day" id="session-day">
                <option value="1">1</option>
              </select>
            </div>

            <div className="dropdown-settings-input">
              <br />
              <label htmlFor="session-month">
                First Study Group Formations:
              </label>
              <select name="session-month" id="session-month">
                <option value="Jan">Jan</option>
              </select>

              <select name="session-day" id="session-day">
                <option value="1">1</option>
              </select>
            </div>

            <div className="dropdown-settings-input">
              <br />
              <label htmlFor="session-month">First Session Day:</label>
              <select name="session-month" id="session-month">
                <option value="Jan">Jan</option>
              </select>

              <select name="session-day" id="session-day">
                <option value="1">1</option>
              </select>
            </div>
          </div>
          {/* /////////// */}
          <div className="dropdown-settings-input">
            <br />
            <label htmlFor="session-end-month">Last Session Day:</label>
            <select name="session-end-month" id="session-end-month">
              <option value="Jan">Jan</option>
            </select>

            <select name="session-end-day" id="session-end-day">
              <option value="1">1</option>
            </select>

            <span className="input-span"> or </span>

            {/* <label htmlFor="session-end-week-range"  >Session End Week Range</label> */}
            <select
              className="gray"
              name="session-end-week-range"
              id="session-end-week-range"
            >
              <option value="10">10</option>
            </select>
            <label htmlFor="session-end-week-range" className="input-span">
              Weeks from start time ending on a
            </label>
            {/* <span className='input-span'> Weeks from start time ending on a </span> */}
            <label htmlFor="session-end-week-range2">: </label>
            <select
              className="gray"
              name="session-end-week-range2"
              id="session-end-week-range2"
            >
              <option value="Friday">Friday</option>
            </select>
          </div>

          <div className="dropdown-settings-input">
            {/* <span className='input-span'>Timezone:</span> */}
            <label htmlFor="timezone" className="input-span">
              Timezone:
            </label>
            <select name="session-end-month" id="timezone">
              <option value="Pacific Time">Pacific Time</option>
            </select>

            <div className="dropdown-settings-input font18 inline">
              <input type="checkbox" id="Misc1" />
              <label for="Misc1">Observe Daylight Savings</label>
            </div>
          </div>
          <div className="dropdown-settings-input font18">
            <input type="checkbox" id="misc-students-decide" />
            <label for="misc-students-decide">
              Program is required for students enrolled in the courses
            </label>
            <br />
            <input type="checkbox" id="misc-students-decide" />
            <label for="misc-students-decide">
              Let students decide what time their study session is each week
            </label>
            <br />
            <input type="checkbox" id="misc-students-decide" />
            <label for="misc-students-decide">
              Automatically find time based on student and tutor availability
            </label>
            <br />
            <input type="checkbox" id="misc-students-decide" />
            <label for="misc-students-decide">
              Automatically find time based on student’s calendar availability
              (no tutors)
            </label>
          </div>

          <div className="description upper-margin-buffer">
            Miscellaneous Settings:
          </div>
          <div className="dropdown-settings-input font18">
            <input type="checkbox" id="misc-students-decide" />
            <label for="misc-students-decide">
              Link all courses with the same course name
            </label>
          </div>
        </div>
        {/* ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */}
        <div className="welcome-box top-border-red width-full">
          <div className="heading">Account Settings</div>
          <br />
          <table className="table-red mobileSettingsTab-table2">
            <tr>
              <th>Entry</th>
              <th>Value</th>
            </tr>
            {/* Currently taking a JSON object of dummy data */}
            {transferData.map((row) => {
              const rowArr = Object.values(row);
              return <Row row={rowArr} />;
            })}
          </table>
          <div onClick={""} className="dashboard-button-red">
            Submit
          </div>
        </div>
      </Fragment>
    );
  } else {
    return (
      <Fragment>
        <div className="welcome-box top-border width-full">
          <div className="heading">Account Settings</div>
          <table className="desktopSettingsTable">
            <tr>
              {/*             <th>Program Name</th>
              <th>Admin Contact Number</th>
              <th>Contact Number</th>
              <th>Session Leader Title</th> */}
              <th>Entry</th>
              <th>Value</th>
            </tr>
            {/* Currently taking a JSON object of dummy data */}
            {settingsData.map((row) => {
              const rowArr = Object.values(row);
              return <Row row={rowArr} />;
            })}
          </table>
        </div>
        {/* ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */}
        <div className="welcome-box top-border width-full">
          <div className="heading">Settings</div>
          {/* /////////// */}
          <div className="description lower-margin-buffer-small">
            Please select how you want students to be grouped:
          </div>

          <form className="settings-form font18" action="">
            <input type="radio" id="default" name="grouping" value="default" />
            <label for="default">Default: Emphasizes student preferences</label>
            <br />
            <input
              type="radio"
              id="networks"
              name="grouping"
              value="networks"
            />
            <label for="networks">Networks: Emphasizes network building</label>
            <br />
            <input
              type="radio"
              id="diveristy"
              name="grouping"
              value="diveristy"
            />
            <label for="diveristy">
              Diversity: Emphasizes different backgrounds
            </label>
            <br />
            <input type="radio" id="origin" name="grouping" value="origin" />
            <label for="origin">Origin: Emphasizes common backgrounds</label>
            <br />
            <input type="radio" id="hours" name="grouping" value="hours" />
            <label for="hours">
              Hours: Emphasizes available learning center hours
            </label>
            <br />
            <input
              type="radio"
              id="duration"
              name="grouping"
              value="duration"
            />
            <label for="duration">
              Duration: Emphasizes preffered duration of study session
            </label>
          </form>
          {/* /////////// */}
          <div className="description upper-margin-buffer">
            Study Session Hours:
          </div>
          <div className="dropdown-settings-input">
            <select name="session-hours" id="session-hours-start">
              <option value="12:00 AM">12:00 AM</option>
            </select>
            <span>to</span>
            <select name="session-hours" id="session-hours-end">
              <option value="12:00 AM">12:00 AM</option>
            </select>
            <div className="dropdown-settings-input font18">
              <input type="checkbox" id="24 Hours" />
              <label for="24 Hours">24 Hours</label>
            </div>
          </div>
          {/* /////////// */}
          <div className="description upper-margin-buffer">Study Sessions:</div>
          <div className="dropdown-settings-container">
            <div className="dropdown-settings-input">
              <br />
              <label htmlFor="session-month">
                Last Day for Instructor to Import Roster:
              </label>
              <select name="session-month" id="session-month">
                <option value="Jan">Jan</option>
              </select>

              <select name="session-day" id="session-day">
                <option value="1">1</option>
              </select>
            </div>

            <div className="dropdown-settings-input">
              <br />
              <label htmlFor="session-month">
                First Study Group Formations:
              </label>
              <select name="session-month" id="session-month">
                <option value="Jan">Jan</option>
              </select>

              <select name="session-day" id="session-day">
                <option value="1">1</option>
              </select>
            </div>

            <div className="dropdown-settings-input">
              <br />
              <label htmlFor="session-month">First Session Day:</label>
              <select name="session-month" id="session-month">
                <option value="Jan">Jan</option>
              </select>

              <select name="session-day" id="session-day">
                <option value="1">1</option>
              </select>
            </div>
          </div>
          {/* /////////// */}
          <div className="dropdown-settings-input">
            <br />
            <label htmlFor="session-end-month">Last Session Day:</label>
            <select name="session-end-month" id="session-end-month">
              <option value="Jan">Jan</option>
            </select>

            <select name="session-end-day" id="session-end-day">
              <option value="1">1</option>
            </select>

            <span className="input-span"> or </span>

            {/* <label htmlFor="session-end-week-range"  >Session End Week Range</label> */}
            <select
              className="gray"
              name="session-end-week-range"
              id="session-end-week-range"
            >
              <option value="10">10</option>
            </select>
            <label htmlFor="session-end-week-range" className="input-span">
              Weeks from start time ending on a
            </label>
            {/* <span className='input-span'> Weeks from start time ending on a </span> */}
            <label htmlFor="session-end-week-range2">: </label>
            <select
              className="gray"
              name="session-end-week-range2"
              id="session-end-week-range2"
            >
              <option value="Friday">Friday</option>
            </select>
          </div>

          <div className="dropdown-settings-input">
            {/* <span className='input-span'>Timezone:</span> */}
            <label htmlFor="timezone" className="input-span">
              Timezone:
            </label>
            <select name="session-end-month" id="timezone">
              <option value="Pacific Time">Pacific Time</option>
            </select>

            <div className="dropdown-settings-input font18 inline">
              <input type="checkbox" id="Misc1" />
              <label for="Misc1">Observe Daylight Savings</label>
            </div>
          </div>
          <div className="dropdown-settings-input font18">
            <input type="checkbox" id="misc-students-decide" />
            <label for="misc-students-decide">
              Program is required for students enrolled in the courses
            </label>
            <br />
            <input type="checkbox" id="misc-students-decide" />
            <label for="misc-students-decide">
              Let students decide what time their study session is each week
            </label>
            <br />
            <input type="checkbox" id="misc-students-decide" />
            <label for="misc-students-decide">
              Automatically find time based on student and tutor availability
            </label>
            <br />
            <input type="checkbox" id="misc-students-decide" />
            <label for="misc-students-decide">
              Automatically find time based on student’s calendar availability
              (no tutors)
            </label>
          </div>

          <div className="description upper-margin-buffer">
            Miscellaneous Settings:
          </div>
          <div className="dropdown-settings-input font18">
            <input type="checkbox" id="misc-students-decide" />
            <label for="misc-students-decide">
              Link all courses with the same course name
            </label>
          </div>
        </div>
        {/* ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */}
        <div className="welcome-box top-border-red width-full">
          <div className="heading">Account Settings</div>
          <br />
          <table className="table-red desktopSettingsTable">
            <tr>
              <th>Entry</th>
              <th>Value</th>
            </tr>
            {/* Currently taking a JSON object of dummy data */}
            {transferData.map((row) => {
              const rowArr = Object.values(row);
              return <Row row={rowArr} />;
            })}
          </table>
          <div onClick={""} className="dashboard-button-red">
            Submit
          </div>
        </div>
      </Fragment>
    );
  }
};

export default SettingsTab;
