import React, { Fragment } from 'react';

import Loading from '../Icons/Loading';

const MessagingBar = ({ selectedChatroom, loading, addComment, quote }) => {
  return (
    <Fragment>
      {selectedChatroom ? (
        <div className='messaging messaging--valid'>
          <Loading loading={loading} />
          <textarea
            className='messaging__input messaging-text'
            placeholder={`Message ${selectedChatroom}`}
          />
          <div className='messaging__icon messaging__icon--add-image u-clickable'>
            <i className='fas fa-camera'></i>
          </div>
          <div className='messaging__icon messaging__icon--add-attachment u-clickable'>
            <i className='fas fa-paperclip'></i>
          </div>
          <div
            onClick={addComment}
            className='messaging__icon messaging__icon--send u-clickable'
          >
            <i className='fas fa-paper-plane'></i>
          </div>
        </div>
      ) : (
        <div className='messaging messaging--invalid messaging-text'>
          Select a channel to begin messaging...
        </div>
      )}
      {quote.visible && (
        <div className='quote'>
          <div className='quote__heading'>
            <div className='quote__user'>{quote.user}</div>
            <div className='quote__time'>{quote.time}</div>
          </div>
          <div className='quote__comment'>{quote.comment}</div>
        </div>
      )}
    </Fragment>
  );
};

export default MessagingBar;

{
  /* <textarea
className='messaging__input messaging-text'
placeholder={`Message ${selectedChatroom}`}
/>
<div className='messaging__icon messaging__icon--add-image'>
<i className='fas fa-camera'></i>
</div>
<div className='messaging__icon messaging__icon--add-attachment'>
<i className='fas fa-paperclip'></i>
</div>
<div className='messaging__icon messaging__icon--send'>
<i className='fas fa-paper-plane'></i>
</div> */
}
