import React from "react";
import { Link } from "react-router-dom";
import "./error.css";

function ErrorPage() {
  return (
    <div className="error-body download">
      <br />
      <div className="row">
        <div className="col-md-2"></div>
        <div className="col-md-8">
          <div className="border error-card">
            <h1>Oops! This page does not exist!</h1>
            <br />
            <h2>
              {" "}
              <Link to="/" alt="home">
                <div alt="home">Home</div>
              </Link>{" "}
            </h2>
          </div>
        </div>
        <div className="col-md-2"></div>
      </div>
    </div>
  );
}

export default ErrorPage;
