import React, { Fragment } from 'react';
import './Tutorial6.css';

function Tutorials() {
  return (
    <Fragment>
      <div className='header-container'>
        <h1 className='header'>Study Together!</h1>
        <div>
          <i class='icon far fa-handshake'></i>
        </div>
        <p className='information'>
          Meet with your group to discuss homework problems or study! When you
          finish, fill out a quick poll to help your future classmates 🤜🤛
        </p>
      </div>
    </Fragment>
  );
}

export default Tutorials;
