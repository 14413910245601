import React from 'react';

import Burger from './Burger';
import Sidebar from './Sidebar';
import Menu from '../Icons/VerticalEllipsis';
import Search from '../Icons/Search';

const Navbar = ({ notifications, handleBurgerClick, toggleDarkMode }) => {
  return (
    <div className='navbar'>
      <div className='burger'>
        <Burger
          handleBurgerClick={handleBurgerClick}
          notifications={notifications}
        />
      </div>
      <div className='navbar__search u-clickable'>
        <Search />
      </div>
      <div className='navbar__menu u-clickable' onClick={toggleDarkMode}>
        <Menu />
      </div>
    </div>
  );
};

export default Navbar;
