import React from "react";

function Blog() {
  return (
    <div className="team-body blur-box">
      <div className="row">
        <div className="col-md-1"></div>
        <div className="col-md-10">
          <div className="border team-card">
            <div className="row">
              <div className="col-md-12">
                <div className="team-title h1">BLOG</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Blog;
