import React from "react";
import "./Login.css";
import { Link } from "react-router-dom";
// import FB from '../Facebook/Facebook';

function Login() {
  return (
    <div className="login-body blur-box">
      <div className="row">
        <div className="col-md-4"></div>
        <div className="col-md-4">
          <div className="login-card border">
            {/* Icon row */}
            <div className="row">
              <div className="col-md-4"></div>
              <div className="col-md-4 section5-icon-container">
                <img
                  src={require("../../assets/3/Neo_logo.png")}
                  alt="download icon"
                  className="login-icon-img"
                ></img>
              </div>
              <div className="col-md-4"></div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <div className="login-title">
                  <h1>Login to Neoboard</h1>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <div className="stu-quote">
                  <form>
                    <div className="form-group">
                      <input
                        type="email"
                        className="form-control"
                        id="exampleInputEmail1"
                        placeholder="Email"
                      />
                    </div>
                    <div className="form-group">
                      <input
                        type="password"
                        className="form-control"
                        id="exampleInputPassword1"
                        placeholder="Password"
                      />
                    </div>
                    <button type="submit" className="btn login-btn">
                      Submit
                    </button>
                  </form>
                </div>

                {/* Email form and calendar sync start*/}
                <div className="row">
                  <div className="col-md-3"></div>
                  <div className="col-md-6">
                    <Link to="/register">
                      <div className="register">
                        Register your Email, and sync your calendar
                      </div>
                    </Link>
                  </div>
                  <div className="col-md-3"></div>
                </div>
                {/* Email form and calendar sync end*/}
                <div className="row">
                  <div className="col-md-12">
                    <h4 className="link-title">Other ways to sign in</h4>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-12">
                    <ul className="login-links-list">
                      <li className="login-link">
                        <i className="fab fa-facebook"></i>
                      </li>
                      {/* <FB /> */}

                      <li className="login-link">
                        <i className="fab fa-twitter-square"></i>
                      </li>
                      <li className="login-link">
                        <i className="fab fa-twitter-square"></i>
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-12">
                    <ul className="login-links-list">
                      <li className="login-link">
                        <i className="fab fa-google"></i>
                      </li>
                      <li className="login-link">Canvas</li>
                      <li className="login-link">
                        <i className="fab fa-weixin"></i>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-md-4"></div>
      </div>
    </div>
  );
}

export default Login;
