import React from "react";
import "../StudentPollSI.css";

export default class Prepared extends React.Component {
  state = {
    prepared: "3",
    preparedText: "Neutral",
    dangerBG: "",
    dangerIcon: "",
    dangerSlider: "",
    dangerTrack: "",
    greenSlider: "",
    greenBG: "",
    greenIcon: "",
    greenTrack: "",
  };

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });

    //START -> Prepared , Agree/Disagee Type Text
    if (event.target.name === "prepared" && event.target.value === "1") {
      this.setState({ preparedText: "Strongly Disagree" });
      this.setState({ dangerBG: "dangerBG" });
      this.setState({ dangerIcon: "dangerIcon" });
      this.setState({ dangerSlider: "dangerSlider" });
      this.setState({ dangerTrack: "dangerTrack" });
    }
    if (event.target.name === "prepared" && event.target.value === "2") {
      this.setState({ preparedText: "Disagree" });
      this.setState({ dangerBG: "" });
      this.setState({ dangerIcon: "" });
      this.setState({ dangerSlider: "" });
      this.setState({ dangerTrack: "" });
    }
    if (event.target.name === "prepared" && event.target.value === "3") {
      this.setState({ preparedText: "Neutral" });
    }
    if (event.target.name === "prepared" && event.target.value === "4") {
      this.setState({ preparedText: "Agree" });
      this.setState({ greenBG: "" });
      this.setState({ greenIcon: "" });
      this.setState({ greenSlider: "" });
      this.setState({ greenTrack: "" });
    }
    if (event.target.name === "prepared" && event.target.value === "5") {
      this.setState({ preparedText: "Strongly Agree" });
      this.setState({ greenBG: "greenBG" });
      this.setState({ greenIcon: "greenIcon" });
      this.setState({ greenSlider: "greenSlider" });
      this.setState({ greenTrack: "greenTrack" });
    }
    //END -> Prepared , Agree/Disagee Type Text
    this.sendData(this.state.prepared);
  };

  sendData = () => {
    let newInt = parseInt(this.state.prepared);
    this.props.parentCallback(newInt);
  };

  render() {
    return (
      <div className="si-sliders">
        {/* Start Range Slider */}
        <div className="row">
          <div className="col-md-1"></div>
          <div className="col-md-10">
            <div className="row">
              <div className="col-md-12 mb3">
                <div
                  className={`poll-icon-container ${this.state.dangerBG} ${this.state.greenBG}`}
                >
                  <i
                    className={`icon-img fas fa-briefcase  ${this.state.dangerIcon} ${this.state.greenIcon}`}
                  ></i>
                </div>

                <div className="poll-title-container">
                  <p className="si-slider-title h6">
                    My SI was well prepared for each session
                  </p>
                </div>
              </div>
            </div>

            <div
              className={`${this.state.dangerSlider} ${this.state.greenSlider} ${this.state.dangerTrack} ${this.state.greenTrack} `}
            >
              <input
                min="1"
                max="5"
                step="1"
                name="prepared"
                type="range"
                onChange={this.handleChange}
                value={this.props.preparedRating}
                id="prepared"
              />
            </div>

            
            <label htmlFor="prepared">{this.state.prepared}</label>
            <br />
            <div className="importance-label">{this.state.preparedText}</div>
            <br />
          </div>
          <div className="col-md-1"></div>
        </div>
        {/* End Range Slider */}
      </div>
    );
  }
}
