import React from "react";
import "./Privacy.css";
import { Link } from "react-router-dom";

// import Mailto from "reactv16-mailto";

class Privacy extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <div className="privacy blur-box">
        <div className="privacy-body">
          <div className="row">
            <div className="col-md-2"></div>
            <div className="col-md-8">
              <div className="privacy-card border">
                <div className="row">
                  <div className="col-md-4"></div>
                  <div className="col-md-4">
                    <img
                      src={require("../../assets/3/Neoboard Wordmark Logo 2020 Large.png")}
                      alt="neoboard logo"
                      className="neoboard-logo-privacy"
                    ></img>
                  </div>
                  <div className="col-md-4"></div>
                </div>

                <div className="row">
                  <div className="col-md-4"></div>
                  <div className="col-md-4">
                    <h1 className="center privacy-title">Privacy Policy</h1>
                  </div>
                  <div className="col-md-4"></div>
                </div>
                <br />
                <div className="row">
                  <div className="col-1"></div>
                  <div className="col-10">
                    <p>
                      Please read below to get informed about our Privacy Notice
                      and your choices about how information is used. This
                      notice is designed to provide transparency into our
                      privacy practices, in a format that is easy to read,
                      understand and navigate. Reference each section to get an
                      overview of how we collect, use, share, and safeguard your
                      information, or click learn more to expand and view the
                      full Customer Privacy Notice.
                    </p>
                    <br />
                    <p>
                      <b>Data Collected</b>
                    </p>
                    <br />
                    <p>
                      We collect three main types of information related to you
                      or your use of our products and services:
                    </p>
                    <br />
                    <ol>
                      <li> Information from or about you or your devices</li>
                      <li>
                        {" "}
                        Information submitted from your academic institution or
                        company
                      </li>
                      <li>
                        {" "}
                        Information from your utilization of our platform{" "}
                      </li>
                    </ol>
                    <br />
                    <p>
                      <b>From or about you or your devices</b>
                    </p>
                    <p>
                      We may collect information from or about you (such as your
                      name, address, phone number, email, payment information,
                      driver’s license or other government identification
                      information) or your devices in a variety of ways,
                      including:
                    </p>
                    <br />
                    <p>
                      1) <b>Through our Digital Services: </b>We may collect
                      information from or about you through our websites,
                      software applications, your social media pages, email
                      messages, or other digital services (the “Digital
                      Services”), including when you sign into your social media
                      accounts, sign up for our newsletter, or make a purchase.
                    </p>
                    <br />
                    <p>
                      <b>From other sources: </b>We may receive information
                      about you from other sources, such as public databases,
                      joint marketing partners, certified installers,
                      third-party vehicle repair or service centers, and social
                      media platforms.
                    </p>
                    <br />
                    <p>
                      <b>Neoboard Account:</b> Users of our platform will
                      receive a Neoboard Account, which is hosted on our
                      website. We may collect and process the following types of
                      data for your Neoboard Account that you elect to provide
                      to us: personal information such as your general
                      availability, social preferences, your approximate
                      location of residence and similar information. You can
                      access your Neoboard Account to update the information
                      from or about you in that account at any time.
                    </p>
                    <br />
                    <p>
                      <b>Offline:</b> We may collect information from or about
                      you offline, such as when and where you attend a study
                      session, or contact our customer service or sales
                      department.
                    </p>
                    <br />
                    <p>
                      <b>Through your browser or device:</b> Certain information
                      is collected by most browsers or automatically through
                      your device, such as your Media Access Control (MAC)
                      address, computer type (Windows or Macintosh), screen
                      resolution, operating system name and version, device
                      manufacturer and model, language, Internet browser type
                      and version, and the name and version of the Digital
                      Services (such as the Neoboard App) you are using. We use
                      this information to ensure that the Digital Services
                      function properly.
                    </p>
                    <br />
                    <p>
                      When you visit our website or otherwise use our Digital
                      Services, we may use cookies, pixel tags, analytics tools,
                      and other similar third-party technologies to help provide
                      and improve our Digital Services, and as detailed below:
                    </p>
                    <br />
                    <ul>
                      <li>
                        <b>Cookies:</b>Cookies are pieces of information stored
                        directly on the computer that you are using. Cookies
                        allow us to collect information such as browser type,
                        time spent on the Digital Services, pages visited,
                        language preferences, and other web traffic data. We,
                        and our service providers, use the information for
                        security purposes, to facilitate online navigation, to
                        display information more effectively, to personalize
                        your experience while using the Digital Services, and to
                        otherwise analyze user activity. We can recognize your
                        computer to assist your use of the Digital Services. We
                        also gather statistical information about the usage of
                        the Digital Services in order to continually improve
                        their design and functionality, understand how the
                        Digital Services are used, and assist us with resolving
                        questions regarding the Digital Services. Cookies
                        further allow us to select which of our offers are most
                        likely to appeal to you and to display them to you. We
                        may also use cookies to see how you interact with our
                        offers, and we may use cookies or other files to
                        understand your use of our websites.
                      </li>
                      <li>
                        <b>Opting out of Cookies: </b>If you do not want
                        information collected through the use of cookies when
                        using your Neoboard Account or our website, there is a
                        simple procedure in most browsers (e.g., Safari, Chrome,
                        Firefox) that allows you to automatically decline
                        cookies or gives you the choice of declining or
                        accepting the transfer to your computer of a particular
                        cookie(s). However, if you do not accept these cookies,
                        you may experience some inconvenience in your use of the
                        Digital Services. For example, we may not be able to
                        recognize your computer, and you may need to log in
                        every time you visit the applicable Digital Services.
                      </li>
                      <li>
                        <b>Pixel tags and other similar technologies: </b>Pixel
                        tags (also known as web beacons and clear GIFs) may be
                        used in connection with some Digital Services to, among
                        other things, track the actions of users of the Digital
                        Services (including email recipients), measure the
                        success of our marketing campaigns, and compile
                        statistics about usage of the Digital Services and
                        response rates.
                      </li>
                      <li>
                        <b>Analytics tools: </b>We use website and application
                        analytics services provided by third parties (such as
                        Google Analytics) that use cookies and other similar
                        technologies to collect information about website or
                        application use and to report trends, without
                        identifying individual visitors. The third parties that
                        provide us with these services may also collect
                        information about your use of third-party websites. You
                        can learn how to opt out of analytics by downloading the
                        Google Analytics opt-out browser add-on, available here.
                      </li>
                    </ul>
                    <br />
                    <p>
                      We retain the information we collect from or about you,
                      our products, and our services for the period necessary to
                      fulfill the purposes outlined in this Notice unless a
                      longer retention period is required or permitted by law.
                      When the information is no longer necessary for these
                      purposes, we delete it or keep it in a form that does not
                      identify you. When determining this retention period, we
                      take into account various criteria, such as the type of
                      products and services requested by or provided to you, the
                      nature of our relationship with you, the impact on the
                      services we provide to you if we delete some information
                      from or about you, and retention periods required by law.
                    </p>
                    <br />
                    <p>
                      We also collect information in a form that does not, on
                      its own, permit direct association with any specific
                      individual. We may collect, use, transfer, and disclose
                      non-personally identifiable data for any purpose. If we do
                      combine non-personally identifiable data with your
                      personal information, the combined information will be
                      treated as personal information for as long as it remains
                      combined.
                    </p>
                    <br />
                    <p>
                      <b>
                        Information submitted from your academic institution or
                        company
                      </b>
                    </p>
                    <p>
                      We may collect information from or about you (such as your
                      name, email, and enrollment status) or your devices in a
                      variety of ways, including:
                    </p>
                    <ul>
                      <li>
                        <b>Name: </b>To identify you within the framework of
                        using our platform
                      </li>
                      <li>
                        <b>Course Enrollment: </b> To verify your enrollment
                        within a course and determine which groups you will be
                        participating in
                      </li>
                      <li>
                        <b>Email Address: </b>To communicate with you regarding
                        updates, account information, or to carry out your own
                        requests.
                      </li>
                      <li>
                        <b>Enrollment Status: </b>To verify your enrollment
                        within a course and determine which groups you will be
                        participating in
                      </li>
                      <li>
                        <b>Grades: </b>To provide you with appropriate campus
                        resources whenever the system determines it is necessary
                        and appropriate.
                      </li>
                    </ul>
                    <br />
                    <p>
                      Information that falls under FERPA guidelines requires
                      your permission and is only used by the system for the
                      above purposes and is never viewed by any individual,
                      including instructors, counselors, tutors, other students,
                      and any other academic personnel or Neoboard engineers.
                    </p>
                    <br />
                    <p>
                      <b>Information from your utilization of our platform</b>
                    </p>
                    <br />
                    <p>
                      We may collect a variety of information from regular usage
                      or activity with the Neoboard platform, including:
                    </p>
                    <ul>
                      <li>
                        <b>Usage Data: </b>we may collect information about your
                        usage of the Neoboard platform, including login times,
                        frequency, message interactions and similar information.
                      </li>
                      <li>
                        <b>Participation: </b>In order to provide you with the
                        right resources, we may collect information about your
                        participation in study groups, including attendance,
                        written data, and transcriptions.
                      </li>
                      <li>
                        <b>Audio Transcriptions: </b>Study session audio files
                        and transcriptions from third party software may be
                        stored.
                      </li>
                      <li>
                        <b>Message Content: </b>Message data and metadata may be
                        collected.
                      </li>
                      <li>
                        <b>Shared Personal Data: </b>We may collect information
                        from uploaded content, such as profile images, links,
                        documents, images, audio files, and poll response data.
                      </li>
                    </ul>
                    <br />
                    <p>
                      We collect information you provide directly to us. For
                      example, we collect information when you create an
                      account, subscribe, participate in any interactive
                      features of our services, fill out a form, request
                      customer support or otherwise communicate with us. The
                      types of information we may collect include your name,
                      email address, postal address, credit card information and
                      other contact or identifying information you choose to
                      provide.
                    </p>
                    <br />
                    <p>
                      We collect anonymous data from every visitor of the
                      Website to monitor traffic and fix bugs. For example, we
                      collect information like web requests, the data sent in
                      response to such requests, the Internet Protocol address,
                      the browser type, the browser language, and a timestamp
                      for the request.
                    </p>
                    <br />
                    <p>
                      We also use various technologies to collect information,
                      and this may include sending cookies to your computer.
                      Cookies are small data files stored on your hard drive or
                      in your device memory that helps us to improve our
                      services and your experience, see which areas and features
                      of our services are popular and count visits. We may also
                      collect information using web beacons (also known as
                      "tracking pixels"). Web beacons are electronic images that
                      may be used in our services or emails and to track count
                      visits or understand usage and campaign effectiveness.
                    </p>
                    <br />
                    <p>
                      <b>Use of the Data</b>
                    </p>
                    <p>
                      We only use your personal information to provide you the
                      Neoboard services, such as for the purposes of creating or
                      modifying study groups, provide you with appropriate
                      resources for your academic success or to communicate with
                      you about the Website or the services.{" "}
                    </p>
                    <br />
                    <p>
                      We employ FERPA, HIPAA, and industry standard techniques
                      to protect against unauthorized access of data about you
                      that we store, including personal information.
                    </p>
                    <br />
                    <p>
                      We do not share personal information you have provided to
                      us without your consent, unless:
                    </p>
                    <br />
                    <ul>
                      <li>
                        Doing so is appropriate to carry out your own request
                      </li>
                      <li>
                        We believe it's needed to enforce our legal agreements
                        or that is legally required
                      </li>
                      <li>
                        We believe it's needed to detect, prevent or address
                        fraud, security or technical issues
                      </li>
                    </ul>
                    <br />
                    <p>
                      <b>Sharing of Data</b>
                    </p>
                    <p>
                      We don't share your personal information with third
                      parties. Aggregated, anonymized data is periodically
                      transmitted to external services to help us improve the
                      Website and service.
                    </p>
                    <br />
                    <p>
                      We may allow third parties to provide analytics services.
                      These third parties may use cookies, web beacons and other
                      technologies to collect information about your use of the
                      services and other websites, including your IP address,
                      web browser, pages viewed, time spent on pages, links
                      clicked and conversion information.
                    </p>
                    <br />
                    <p>
                      We also use social buttons provided by services like
                      Twitter, Google+, LinkedIn and Facebook. Your use of these
                      third party services is entirely optional. We are not
                      responsible for the privacy policies and/or practices of
                      these third party services, and you are responsible for
                      reading and understanding those third party services'
                      privacy policies.
                    </p>
                    <br />
                    <p>
                      <b>Opt-Out, Communication Preferences</b>
                    </p>
                    <p>
                      You may modify your communication preferences and/or
                      opt-out from specific communications at any time. Please
                      specify and adjust your preferences.
                    </p>
                    <br />
                    <p>
                      <b>Security</b>
                    </p>
                    <p>
                      We take reasonable steps to protect personally
                      identifiable information from loss, misuse, and
                      unauthorized access, disclosure, alteration, or
                      destruction. But, you should keep in mind that no Internet
                      transmission is ever completely secure or error-free. In
                      particular, email sent to or from the Sites may not be
                      secure.
                    </p>
                    <br />
                    <p>
                      <b>About Children</b>
                    </p>
                    <p>
                      The Website is not intended for children under the age of
                      13. We do not knowingly collect personally identifiable
                      information via the Website from visitors in this age
                      group.
                    </p>
                    <br />
                    <p>
                      <b>Changes to the Privacy Policy</b>
                    </p>
                    <p>
                      We may amend this Privacy Policy from time to time. Use of
                      information we collect now is subject to the Privacy
                      Policy in effect at the time such information is used.
                    </p>
                    <br />
                    <p>
                      If we make major changes in the way we collect or use
                      information, we will notify you by posting an announcement
                      on the Website or sending you an email.
                    </p>
                    <br />
                    <p>
                      <b>How to Contact Us</b>
                    </p>
                    <p>
                      For questions or comments, to opt out from certain
                      services, or to submit a data privacy request, please
                      contact us through this link.
                    </p>
                    <br />

                    <Link to="/contact">CONTACT US</Link>
                    {/* <Mailto
                      email="classroomquestions@neoboardapp.com"
                      obfuscate={true}
                      className="privacy-contact"
                    >
                      CONTACT US
                    </Mailto> */}

                    <br />
                    <br />
                  </div>
                  <div className="col-1"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Privacy;
