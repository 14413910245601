import React, { Fragment } from 'react';
import './Tutorial2.css';

function Tutorials() {
  return (
    <Fragment>
      <div className='header-container'>
        <h1 className='header'>Your Community!</h1>
        <select className='schools'>
          <option value='UCR'>University of California, Riverside</option>
          <option value='UCLA'>University of California, Los Angeles</option>
          <option value='UCSD'>University of California, San Diego</option>
          <option value='UCI'>University of California, Irvine</option>
        </select>
      </div>
      <p className='information'>
        We all bring something to the classroom, first begin by telling us the
        school you attend 🎓
      </p>
    </Fragment>
  );
}

export default Tutorials;
