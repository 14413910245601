import React from 'react';
import './WelcomePage.css';

import { Link } from 'react-router-dom';

const WelcomePage = () => {
    return (
        <div className='canvas-container welcome-page'>
            <div className='welcome-box width-full welcome-padding'>
                <div className='logo-wrapper'>
                    <div className='logo-neoboard'></div>
                </div>
                <hr />

                <div className='banner-container'>
                    <div className='welcome-banner'></div>
                    <div className='banner-text'>Welcome Mira</div>
                </div>

                <div className='welcome-text'>
                    Your instructor Dr. Hughes invited you to join the class
                    messaging community, let’s get started by clicking below to
                    create an account
                </div>
                <Link
                    to={'/poll'}
                    className='dashboard-button small-margin-top unselectable'
                >
                    Join the Class
                </Link>
            </div>
        </div>
    );
};

export default WelcomePage;
