import React from "react";

function ViewEdit() {
  return (
    <div className="welcome-box top-border width-full no-padding">
      <br />
      <div className="heading">Find Institution</div>
      <br />
      <div className="description">
        Your courses that you have input or uploaded are shown below:
      </div>
      <br />
    </div>
  );
}

export default ViewEdit;
