import React from "react";
import adminDash from "../../../assets/Demo Neo 2.png";

import "./devices.min.css";

import Popover from "react-bootstrap/Popover";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";

function AdminEnrollment() {
  const popoverCheckbox = (
    <Popover show={true} id="popover-basic">
      <Popover.Content as="p">
        Choose which courses students are required to enroll in your academic
        program
      </Popover.Content>
    </Popover>
  );

  const popoverLeader = (
    <Popover show={true} id="popover-basic">
      <Popover.Content as="p">
        Quickly enroll student leaders for the course, using just their school
        email address
      </Popover.Content>
    </Popover>
  );

  return (
    <div className="admin">
      <div className="admin__left">
        <h1>Measure everything</h1>
        <h3>
        Through the use reliable data such as study team analytics, behavior and detailed participation data, find at-risk students early
        </h3>
      </div>

      <div className="admin__center"></div>

      <div className="admin__right">
        <div className="admin__right__container">



              <img src={adminDash} alt="" className="eval"/>
              {/*<OverlayTrigger placement="auto" overlay={popoverCheckbox}>
                <div className="admin__pop__checkbox"></div>
              </OverlayTrigger>
              <OverlayTrigger placement="auto" overlay={popoverLeader}>
                <div className="admin__pop__leader"></div>
  </OverlayTrigger>*/}


        </div>
      </div>
    </div>
  );
}

export default AdminEnrollment;
