import React from "react";
import sessions from "../../../assets/Demo Neo 3.png";

import "./devices.min.css";

import Popover from "react-bootstrap/Popover";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";

function AdminSessions() {
  const popoverResolve = (
    <Popover show={true} id="popover-basic">
      <Popover.Content as="p">
        Take control of all scheduling conflicts at the click of a drop-down
        menu!
      </Popover.Content>
    </Popover>
  );

  const popoverLocation = (
    <Popover show={true} id="popover-basic">
      <Popover.Content as="p">
        In case of study group issues, you can enter any group at the click of a
        button
      </Popover.Content>
    </Popover>
  );

  return (
    <div className="admin">
      <div className="admin__left">
        <h1>Automate actions to reduce dropout</h1>
        <h3>
        Automatic scheduling of student team sessions, team assignments, tutor scheduling and more
        </h3>
      </div>

      <div className="admin__center"></div>

      <div className="admin__right">
        <div className="admin__right__container">

              <img src={sessions} alt="" className="eval" />


        </div>
      </div>
    </div>
  );
}

export default AdminSessions;
