import React from "react";
import "./App.css";
import "./components/Nav/Nav";
import { Link } from "react-router-dom";
// import Mailto from "reactv16-mailto";
import "./components/Section1/Section1.css";
import ReactGA from "react-ga";

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

//Components
// import Nav from "./components/Nav/Nav";
// import Section1 from "./components/Section1/Section1";
import Section2 from "./components/Section2/Section2";
import Section3 from "./components/Section3/Section3";
import Section4 from "./components/Section4/Section4";
import Section5 from "./components/Section5/Section5";
import Section6 from "./components/Section6/Section6";
import Footer from "./components/Footer/Footer";
import Footer2 from "./components/Footer2/Footer2";
// import Email from "./components/Email/Email";

//Mobile Components - These are displayed instead of their counterpart on devices under 768px wide
import Section4Mobile from "./components/Section4Mobile/Section4Mobile";
import Section5Mobile from "./components/Section5Mobile/Section5Mobile";

//Links/Routes

import Download from "./components/Download/Download";
import Team from "./components/Team/Team";
import Tutorials from "./components/Tutorials/Tutorials";
import Login from "./components/Login/Login";
import Privacy from "./components/Privacy/Privacy";
import PollwithCalendar from "./components/PollwithCalendar/PollwithCalendar";
import StudentPollSI from "./components/StudentPollSI/StudentPollSI";
import StuTimeSelection from "./components/SessionTimeSelection/StuTimeSelection";
import StuLeaderTimeSelection from "./components/SessionTimeSelection/StuLeaderTimeSelection";
import Graph from "./components/Graph/Graph";
import Postings from "./components/Postings/Postings";
import SessionItinerary from "./components/SessionItinerary/SessionItinerary";
import Blog from "./components/Blog/Blog";
import RouteChangeTracker from "./components/RouteChangeTracker/RouteChangeTracker";
import Messaging from "./components/Messaging/Messaging.jsx";

// import StudyPoll from './components/StudyGroupQuestionnaire/StudyGroupQuestions';
//path was not working, changed import component name to correct
import StudyGroupQuestions from "./components/studyGroupQuestionnaire/StudyGroupQuestions";

import CanvasPage from "./components/Canvas/Canvas";
import WelcomePage from "./components/WelcomePage/WelcomePage";
import Engineering from "./components/Engineering/Engineering";
import EngineeringDashboard from "./components/Engineering/EngineeringDashboard";

import Routes from "./components/Routes/Routes";
import Error from "./components/ErrorPage/ErrorPage";

// Temp Engineer components
import EngineerPage from "./components/Engineer/Engineer";

import Demo from "./components/Demo/Demo";
import Register from "./components/Register/Register";
// import Student from './components/Student/Student';

// import Section1Stu from "./components/Section1/Section1Stu";
import Section2Stu from "./components/Section2/Section2Stu";
import Section3Stu from "./components/Section3/Section3Stu";
import Section4Stu from "./components/Section4/Section4Stu";
import Section5Stu from "./components/Section5/Section5Stu";
import Section6Stu from "./components/Section6/Section6Stu";

import Contact from "./components/Contact/Contact";

import Section4MobileStu from "./components/Section4Mobile/Section4MobileStu";
import Section5MobileStu from "./components/Section5Mobile/Section5MobileStu";

// import { defaults } from "react-chartjs-2";

const TRACKING_ID = "UA-194618568-1"; // YOUR_OWN_TRACKING_ID
ReactGA.initialize(TRACKING_ID);
ReactGA.pageview(window.location.pathname + window.location.search);

function App() {
  const [darkMode, setDarkMode] = React.useState(getInitialMode());
  React.useEffect(() => {
    localStorage.setItem("dark", JSON.stringify(darkMode));
  }, [darkMode]);

  function getInitialMode() {
    const isReturningUser = "dark" in localStorage;
    const savedMode = JSON.parse(localStorage.getItem("dark"));
    const userPrefersDark = getPrefColorScheme();
    // if mode was saved --> dark / light
    if (isReturningUser) {
      return savedMode;
      // if preferred color scheme is dark --> dark
    } else if (userPrefersDark) {
      return true;
      // otherwise --> light
    } else {
      return false;
    }
    // return savedMode || false;
  }

  function getPrefColorScheme() {
    if (!window.matchMedia) return;

    return window.matchMedia("(prefers-color-scheme: dark)").matches;
  }

  /*COMMENTED OUT FOR PDF EXPORT READABILITY PURPOSES, MAY CHANGE LATER */

  // if (darkMode) {
  //   defaults.global.defaultFontColor = "white";
  //   defaults.scale.gridLines.color = "rgba(255,255,255, 0.3)";
  // }

  return (
    <Router>
      <div className={darkMode ? "dark-mode" : "light-mode"}>
        <main>
          <div className="App">
            {/* <Nav /> */}
            <RouteChangeTracker />
            <div className="neo-navbar">
              <div className="row">
                <div className="col-md-1"></div>
                <div className="col-md-10">
                  <div className="row">
                    <div className="col-md-3">
                      <div className="logo-container">
                        <Link to="/">
                          <img
                            src={
                              darkMode
                                ? require("./assets/3/Neoboard Wordmark Logo 2020 Large_DARKMODE.png")
                                : require("./assets/3/Neoboard Wordmark Logo 2020 Large.png")
                            }
                            alt="neoboard logo"
                            className="neoboard-logo"
                          ></img>
                        </Link>
                      </div>
                    </div>
                    <div className="col-md-1"></div>
                    <div className="col-md-8 align-self-center">
                      <div className="nav-links nav-ul">
                        {/* <Mailto
                          email="classroomquestions@neoboardapp.com"
                          obfuscate={true}
                        >
                          <div className="link">CONTACT</div>
                        </Mailto> */}
                        <Link to="/contact">
                          <div className="link">CONTACT</div>
                        </Link>

                        <Link to="/demo">
                          <div className="link">DEMO</div>
                        </Link>

                        
                        {/*<Link to="/login">
                          <div className="link">LOGIN</div>
                      </Link>*/}
                        {/*<Link to="/postings">
                          <div className="link">CAREERS</div>
                      </Link>*/}
                        {/*<Link to="/download">
                          <div className="nav-button link">
                            DOWNLOAD THE APP
                          </div>
                    </Link>*/}

                        {/* Dark Mode Slider Start */}
                        <div>
                          {" "}
                          <div className="darkModeSlider">
                            <span
                              style={{
                                color: darkMode ? "#bcd4d9" : "#172e3f",
                              }}
                            >
                              ☀︎
                            </span>
                            <span className="toggle">
                              <input
                                checked={darkMode}
                                onChange={() => {
                                  if (
                                    window.location.pathname === "/graph" &&
                                    darkMode === true
                                  ) {
                                    window.location.reload();
                                  }
                                  if (
                                    window.location.pathname === "/graph" &&
                                    darkMode === false
                                  ) {
                                    window.location.reload();
                                  }
                                  setDarkMode((prevMode) => !prevMode);
                                }}
                                id="checkbox"
                                className="checkbox"
                                type="checkbox"
                              />
                              <label htmlFor="checkbox">.</label>
                            </span>
                            <span
                              style={{
                                color: darkMode ? "#bcd4d9" : "#172e3f",
                              }}
                            >
                              ☾
                            </span>
                          </div>
                        </div>
                        {/* Dark Mode Slider End */}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-1"></div>
              </div>

              {/* Displays returned Facebook User Info  */}
              {/* <div id="user-info"></div> */}
            </div>
            <Switch>
              <Route exact path="/" component={Home} />
              <Route path="/download" component={Download} />
              <Route path="/team" component={Team} />
              <Route path="/tutorials" component={Tutorials} />
              <Route path="/login" component={Login} />
              <Route path="/contact" component={Contact} />
              <Route path="/engineering" component={Engineering} />
              <Route path="/routes" component={Routes} />

              {/* <Route path='/email' component={Email} /> */}
              <Route
                path="/engineering/dashboard"
                component={EngineeringDashboard}
              />

              {/* Temp Engineer component */}
              <Route path="/engineer" component={EngineerPage} />

              {/* Changed to Study Group Questions path */}
              <Route path="/poll" component={StudyGroupQuestions} />

              {/* New component with study group poll and calendar options */}
              <Route
                path="/studygrouppoll"
                component={PollwithCalendar}
                dark={darkMode}
              />
              <Route path="/studentpollsi" component={StudentPollSI} />

              <Route path="/canvas" component={CanvasPage} />
              <Route path="/demo" component={Demo} />
              <Route path="/register" component={Register} />
              <Route exact path="/student" component={Student} />
              <Route
                exact
                path="/privacy"
                component={Privacy}
                darkMode={darkMode}
              />
              <Route exact path="/welcome" component={WelcomePage} />
              <Route
                exact
                path="/student_sessiontime"
                component={StuTimeSelection}
              />
              <Route
                exact
                path="/leader_sessiontime"
                component={StuLeaderTimeSelection}
              />
              <Route exact path="/graph" component={Graph} />
              <Route exact path="/postings" component={Postings} />
              <Route
                exact
                path="/session_itinerary"
                component={SessionItinerary}
              />
              <Route exact path="/blog" component={Blog} />

              <Route path="/messaging" component={Messaging} />
              {/* Error route must be last Route in switch */}
              <Route path="*" component={Error} />
              {/* Error route must be last Route in switch */}
            </Switch>
            <Footer />
            <Footer2 />
          </div>
        </main>
      </div>
    </Router>
  );
}

const Home = (props) => (
  <div>
    {/* <Section1 darkMode={props}/> */}
    <div className="section1-body">
      {/*<Link to="/student" className="drop-notification">
        <div id="note">Not an Program Director? Click Here for Students.</div>
</Link>*/}
      <div className="row">
        <div className="col-md-5">
          <div className="row">
            <div className="col-md-3"></div>
            <div className="col-md-9">
              <h1 className="main-header">Learn & Succeed Together</h1>
            </div>
          </div>
          <div className="row">
            <div className="col-md-3"></div>
            <div className="col-md-7">
              <p className="sub-header h4">
              Detect at-risk students early by expanding student access 
              to study teams and academic support using alerts and automatic actions
              </p>
            </div>
            <div className="col-md-2"></div>
          </div>
          <br />
          <div className="row">
            <div className="col-md-3"></div>
            <div className="col-md-6">
              <Link to="/demo">
                <div className="sub-button">CHECKOUT THE DEMO</div>
              </Link>
            </div>
            <div className="col-md-3"></div>
          </div>
          <br />
          <div className="row margin-top">
            <div className="col-md-3"></div>
            <div className="col-md-8">
              {/* <Link to="/whiteboard">
                <img
                  // src={require('./assets/3/whiteboard-banner.png')} alt="whiteboard link" className="whiteboard-img"
                  src={
                    props.darkMode
                      ? require("./assets/3/whiteboard-banner_DARKMODE.png")
                      : require("./assets/3/whiteboard-banner.png")
                  }
                  alt="whiteboard link"
                  className="whiteboard-img"
                ></img>
              </Link> */}
            </div>
            <div className="col-md-1"></div>
          </div>
        </div>

        <div className="col-md-6">
          <div className="phonemock">
            <img
              src={
                props.darkMode
                  ? require("./assets/3/main-image-desk@2x.png")
                  : require("./assets/3/main-image-desk@2x.png")
              }
              // src={require("../../assets/2/phone-angled-mock@2x.jpg")}
              alt="phone mock img"
            ></img>
          </div>
        </div>
        <div className="col-md-1"></div>
      </div>
    </div>
    <Section2 />
    <Section3 />
    <Section4 />
    <Section4Mobile />
    {/* <Section5 />
    <Section5Mobile /> */}
    <Section6 />
  </div>
);

const Student = (props) => (
  <div>
    {/* <Section1Stu /> */}
    <div className="section1-body">
      <Link to="/">
        <div id="note">Not a Student? Click Here for Instructor Version.</div>
      </Link>
      <div className="row">
        <div className="col-md-5">
          <div className="row">
            <div className="col-md-3"></div>
            <div className="col-md-9">
              <h1 className="main-header">Online studying made easier</h1>
            </div>
          </div>
          <div className="row">
            <div className="col-md-3"></div>
            <div className="col-md-7">
              <p className="sub-header h4">
                Match and collaborate with classmates to study remotely, at a
                time that works for everyone!
              </p>
            </div>
            <div className="col-md-2"></div>
          </div>
          <br />
          <div className="row">
            <div className="col-md-3"></div>
            <div className="col-md-6">
              <Link to="/demo">
                <div className="sub-button">CHECKOUT THE DEMO</div>
              </Link>
            </div>
            <div className="col-md-3"></div>
          </div>
          <br />
          <div className="row margin-top">
            <div className="col-md-3"></div>
            <div className="col-md-8">
              {/* <Link to="/whiteboard">
                <img
                  // src={require('./assets/3/whiteboard-banner.png')} alt="whiteboard link" className="whiteboard-img"
                  src={
                    props.darkMode
                      ? require("./assets/3/whiteboard-banner_DARKMODE.png")
                      : require("./assets/3/whiteboard-banner.png")
                  }
                  alt="whiteboard link"
                  className="whiteboard-img"
                ></img>
              </Link> */}
            </div>
            <div className="col-md-1"></div>
          </div>
        </div>

        <div className="col-md-6">
          <div className="phonemock">
            <img
              src={
                props.darkMode
                  ? require("./assets/3/phone-angled-mock@2x_DARKMODE.png")
                  : require("./assets/3/phone-angled-mock@2x.png")
              }
              // src={require("../../assets/2/phone-angled-mock@2x.jpg")}
              alt="phone mock img"
            ></img>
          </div>
        </div>
        <div className="col-md-1"></div>
      </div>
    </div>
    <Section2Stu />
    <Section3Stu />
    <Section4Stu />
    <Section4MobileStu />
    {/* <Section5Stu />
    <Section5MobileStu /> */}
    <Section6Stu />
  </div>
);

export default App;
