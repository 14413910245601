import React from "react";

import metrics from "../../../assets/Demo Neo 5.png";

function AdminMetrics() {
  return (
    <div className="admin">
      <div className="admin__left">
        <h1>Get confident reports</h1>
        <h3>
          Create reports at anytime using BI tools like: Power BI, Excel, Tableau...
        </h3>
      </div>

      <div className="admin__center"></div>

      <div className="admin__right">
        <div className="admin__right__container">
          <img src={metrics} alt="" className="eval" />
        </div>
      </div>
    </div>
  );
}

export default AdminMetrics;
