import React, { Fragment } from 'react';

const EditableCell = ({ cellData, onTableUpdate, placeholder }) => {
  const isBoolean = (val) => {
    return val === true || val === false;
  };
  //console.log(isBoolean(cellData.value));
  return (
    <Fragment>
      {/* Toggle switch cell if bool value */}
      {isBoolean(cellData.value) ? (
        <div className='editable-table__cell'>
          <input
            className='editable-table__cell editable-table__cell--required'
            type='checkbox'
            id={`${cellData.id}${cellData.type}`}
            onChange={onTableUpdate}
            defaultChecked={cellData.value}
          />
          <div className='toggle-switch unselectable'>
            <label
              className='toggle-switch__button'
              htmlFor={`${cellData.id}${cellData.type}`}
            >
              <div className='toggle-switch__indicator'>&#x2B24;</div>
              <div className='toggle-switch__blank'></div>
            </label>
          </div>
        </div>
      ) : cellData.value === null ? (
        <div className='editable-table__cell' />
      ) : typeof cellData.value === 'object' ? (
        <select
          className='editable-table__cell'
          id={cellData.id}
          name={cellData.type}
        >
          {cellData.value.map((option) => (
            <option value={option}>{option}</option>
          ))}
        </select>
      ) : (
        <input
          className='editable-table__cell'
          type='text'
          name={cellData.type}
          id={cellData.id}
          value={cellData.value}
          onChange={onTableUpdate}
          placeholder={placeholder}
        />
      )}
    </Fragment>
  );
};

export default EditableCell;
