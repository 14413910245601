import React from 'react';
import { BrowserRouter as Switch, Route } from 'react-router-dom';
import './Engineer.css';

import Login from './EngineerLogin';
import Dashboard from './EngineerDashboard';

// Tab data
import institutionData from '../../dummyData/EngineerInstitutionInfo.json';
import adminData from '../../dummyData/engineerAdminInfo.json';

// All the institutions
import institutionsData from '../../dummyData/engineerFindInstitutionInfo.json';

// All the institutions institution data + admin data
import allInstitutionsData from '../../dummyData/engineerAllInstitutionsData.json';

const Engineer = () => {
  return (
    <div className='engineer'>
      <Switch>
        {/* Login Page */}
        <Route exact path='/engineer/login' component={Login} />
        {/* Engineer Dashboard */}
        <Route
          exact
          path='/engineer/dashboard'
          render={(props) => (
            <Dashboard
              {...props}
              institutionData={institutionData}
              adminData={adminData}
              institutionsData={institutionsData}
              allInstitutionsData={allInstitutionsData}
            />
          )}
        />
      </Switch>
    </div>
  );
};

export default Engineer;

/* 

    <div className='canvas-container greyscale-background container-padding'>
    <div className='welcome-box top-border-grey login-box'>

*/
