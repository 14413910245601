import React, { useState, useEffect } from 'react';

import Table from '../components/Table/NeoboardTable';

let CoursesTab = ({ data }) => {
  console.log(data); // The data imported from json file (later to be from database)
  const [tableData, setTableData] = useState(data);

  const [alert, setAlert] = useState(''); // No alert to start
  const [instructors, setInstructors] = useState(0);
  const [courses, setCourses] = useState(0);

  const submitTable = () => {
    setAlert(
      'Emails or reminders have been sent out to all the instructors and tutors below, you may now log out'
    );
    // Later this will also send emails to each instructor/tutor in the table
  };

  useEffect(() => {
    let dataArr = Object.values(data);
    let instructorArr = [];
    let courseArr = [];

    for (let i = 0; i < dataArr.length; i++) {
      if (!instructorArr.includes(dataArr[i].instructor_name)) {
        instructorArr.push(dataArr[i].instructor_name);
      }
      if (!courseArr.includes(dataArr[i].course_name)) {
        courseArr.push(dataArr[i].course_name);
      }
    }
    setInstructors(instructorArr.length);
    setCourses(courseArr.length);
  }, [instructors, courses]);

  return (
    <div className='welcome-box top-border width-full'>
      <div className='heading'>Courses</div>
      <div className='description'>
        Your courses that you have input or uploaded are shown below:
      </div>
      <div className='description-instruction font18'>
        {instructors} instructors in {courses} courses
      </div>
      <div className='description-instruction alert-space'>{alert}</div>
      {/*  */}

      <div className='neoboard-table-container'>
        <Table
          editable={true}
          onClickableCell={console.log('poopatoopa')}
          headers={[
            'Instructor',
            'Email Address',
            'Course Name',
            'Required',
            'Student Leader',
            'Tutor Email',
          ]}
          popups={[
            'The name of the instructor for the course participating in your program.  May be auto-filled from your LMS when the course is selected.',
            'The email address where the instructor can be reached.  If your institution is using an LMS, it must match the instructor’s email address in their LMS account. May be auto-filled from your LMS when the course is selected.',
            'The name of the class that is participating in your program.  Selecting a course from the drop-down menu allows you to link a course form your institution’s catalog.  Courses with a green dot indicate that this course has been enrolled in your academic program in the past, and data from this course will be linked with past data.',
            'If yes, then all students in the course are required to be enrolled in your program, and are automatically placed into a group',
            'The name of the student leader that will run the sessions in your program.  Their name will display as this when messaging.',
            'The email address where the student leader can be reached.  If your institution is using an LMS, it must match the student leader’s email address in their LMS account. Selecting the name from the drop-down menu selects students that the instructor recommended.  Leaders with green dots indicate that this student has been a leader in past courses and data from this leader will be linked with past student leader data.',
          ]}
          items={tableData}
          addButton={'+ Add Course'}
          delButton={true}
          bgColor={'bg-color-blue'}
          onTableUpdate={(event) => {
            let dataCopy = tableData; // Copy of the table data

            // item is the name of the variable for an item
            console.log(event.target);
            let item = {
              id: event.target.id,
              name: event.target.name,
              value: event.target.value,
            };
            let rows = dataCopy.slice();
            let newRows = rows.map((row) => {
              // for each key in row
              for (let key in row) {
                // if key is the same as item name AND id is the same as item id, then set the key to the item value
                if (key === item.name && row.id === item.id) {
                  row[key] = item.value;
                }
              }
              return row;
            });
            setTableData([...newRows]);
            console.log(tableData);
          }}
          onRowAdd={(event) => {
            let dataCopy = tableData; // Copy of the table data
            let id = (new Date() + Math.floor(Math.random() * 999999)).toString(
              36
            ); // Generate random id to associate to each item (this should be unnessecary and removable once there is a database that automatically assigns unique ID's)

            // Array of the keys of each key/value pair we want for each row
            // Dynamically based off the data we put into the table
            let itemKeys = Object.keys(dataCopy[0]);

            // Empty container that will become the object a new row is based on
            let itemToAdd = {};

            // For each key (in a key/value pair) for a rows data...
            itemKeys.map((key, index) => {
              // For the first key which is always id, set it to a random id
              if (index === 0) {
                itemToAdd[`${key}`] = id;
              } else {
                // Else, dynamically generate a key with an empty value, as a new row will not have values input yet
                itemToAdd[`${key}`] = '';
              }
            });
            // Push the object of keys with empty values to our data copy
            dataCopy.push(itemToAdd);
            // Set state so that it updates on the front end with an empty row
            setTableData([...dataCopy]);
          }}
          onRowDel={(event) => {
            let dataCopy = tableData; // Copy of the table data
            let rowDataCopy = tableData[0]; // Copy of the table data

            let index = dataCopy
              .map((item) => {
                return item.id;
              })
              .indexOf(event.target.name); // Index of the row to delete

            console.log(rowDataCopy);
            dataCopy.splice(index, 1); // Splice it from the array of table rows

            // If array would not exist, is not an array, or is empty
            if (!Array.isArray(dataCopy) || !dataCopy.length) {
              ////////////////////////////////////////////////////////////////////
              console.log('Non array case');
              console.log(rowDataCopy);

              let id = (
                new Date() + Math.floor(Math.random() * 999999)
              ).toString(36); // Generate random id to associate to each item (this should be unnessecary and removable once there is a database that automatically assigns unique ID's)

              // Array of the keys of each key/value pair we want for each row
              // Dynamically based off the data we put into the table
              let itemKeys = Object.keys(rowDataCopy);
              console.log(itemKeys);

              // Empty container that will become the object a new row is based on
              let itemToAdd = {};

              // For each key (in a key/value pair) for a rows data...
              itemKeys.map((key, index) => {
                // For the first key which is always id, set it to a random id
                if (index === 0) {
                  itemToAdd[`${key}`] = id;
                } else {
                  // Else, dynamically generate a key with an empty value, as a new row will not have values input yet
                  itemToAdd[`${key}`] = '';
                }
              });
              console.log(itemToAdd);
              // Push the object of keys with empty values to our data copy
              /*                     rowDataCopy.push(itemToAdd); */
              // Set state so that it updates on the front end with an empty row
              setTableData([itemToAdd]);

              ////////////////////////////////////////////////////////////////////
            } else {
              setTableData([...dataCopy]); // Set the state to the new current table
            }
          }}
        />
      </div>

      {/*  */}
      <div onClick={submitTable} className='dashboard-button'>
        Submit
      </div>
    </div>
  );
};

export default CoursesTab;
