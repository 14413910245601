import React from "react";
import ContactForm from "../Contact/ContactForm";
import "./Contact.css";

export default class Contact extends React.Component {
  render() {
    return (
      <div className="contact">
        <div className="contact-container">
          <div className="row">
            <div className="col-md-3"></div>
            {/* Card row */}
            <div className="col-md-6">
              <div className="row stu-card-container">
                <div className="col-md-12">
                  <div className="contact-form-box  stu-card-border">
                    <ContactForm />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-3"></div>
          </div>
        </div>
      </div>
    );
  }
}
