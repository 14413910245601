export const TagList = [
    'Study Session',
    'Study Group',
    'Class Messaging',
    'Group Messaging',
    'Professor',
    'Student Leader',
    'TA',
    'Tutor',
    'Mentor',
    'Counselor',
    'SI Program',
    'Session Location',
    'Zoom',
    'Messaging Issues',
    'Scheduling',
    'Video Issues',
    'Audio Issues',
    'Location Services',
    'Notification',
    'App Issues',
    'File Sharing',
    'Study Poll',
    'Login Issues',
    'SI Session',
    'Group',
    'Class Chat',
    'Group Chat',
    'Instructor',
    'SI Leader',
    'Coach',
    'Teacher',
    'Lecturer'

  ];
  