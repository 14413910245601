import React from 'react';

const MetricsTab = () => {
  return (
    <div className='welcome-box top-border width-full'>
      <div className='heading'>Study Session Metrics</div>
      <div className='description'>
        Lorem ipsum dolor, sit amet consectetur adipisicing elit. Error at cum
        molestiae nulla aliquid illo accusantium quisquam sunt ad architecto
        officia nisi ab unde exercitationem, neque excepturi reiciendis minus
        quae?
      </div>
    </div>
  );
};

export default MetricsTab;
