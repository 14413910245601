import React, { Fragment, useState, useEffect } from 'react';
import '../Canvas.css';

import { Link } from 'react-router-dom';

// For the Program Roster tab
import Course from './instructorTabs/CourseTab';
// For the SI Evaluations tab
import Recommendations from './instructorTabs/RecommendationTab';
// For the Session Metrics tab
import Metrics from './instructorTabs/MetricsTab';

// Settings Box
/* import Settings from './components/SettingsBox';
import { render } from '@testing-library/react'; */
/* // Modal for when upload is completed
import Modal from './components/UploadCompleteModal'; */

const CanvasDashboardPreUpload = ({
  uploadState,
  courseData,
  recommendationData,
}) => {
  console.log(recommendationData);
  const [tabState, setTabState] = useState('course-roster');

  const changeTab = (event) => {
    // Passing in the value of the value attribute for the tab which this is called from
    setTabState(event.target.value);
  };

  const renderTab = () => {
    switch (tabState) {
      case 'course-roster':
        return <Course data={courseData} uploadState={uploadState} />;
      case 'si-recommendations':
        return <Recommendations students={recommendationData} />;
      case 'session-metrics':
        return <Metrics /* courseData={metricData} */ />;
    }
  };

  // Dummy data for a potentially logged in user
  const [loggedInUser, setLoggedInUser] = useState('Dr. Hughes');
  return (
    <div className='canvas-container dashboard'>
      {/* {modalVisible ? <Modal func={modalState} /> : null} */}

      <div className='welcome-box top-border width-full no-padding-bottom no-padding-sides'>
        <div className='heading'>{loggedInUser} Dashboard</div>
        <div className='description'>
          Please upload your roster of classes, or manually add instructors here
        </div>

        <div className='tab-bar unselectable'>
          <input
            type='radio'
            id='course-roster'
            name='tab-nav'
            value='course-roster'
            className='tab-radio-button'
            defaultChecked
            onChange={changeTab}
          />
          <label for='course-roster' className='tab-navigation-button'>
            <i class='fas fa-book tab-icon'></i>
            Course Roster
          </label>
          <input
            type='radio'
            id='si-recommendations'
            name='tab-nav'
            value='si-recommendations'
            className='tab-radio-button'
            onChange={changeTab}
          />
          <label for='si-recommendations' className='tab-navigation-button'>
            <i class='fas fa-star tab-icon'></i>
            SI Recommendations
          </label>
          <input
            type='radio'
            id='session-metrics'
            name='tab-nav'
            value='session-metrics'
            className='tab-radio-button'
            onChange={changeTab}
          />
          <label for='session-metrics' className='tab-navigation-button'>
            <i class='fas fa-chart-line tab-icon'></i>
            Study Session Metrics
          </label>
        </div>
      </div>

      {renderTab()}

      {/* <Settings /> */}
    </div>
  );
};

export default CanvasDashboardPreUpload;
