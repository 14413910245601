import React, { Fragment } from 'react';
import './Tutorial1.css';

function Tutorials() {
  return (
    <Fragment>
      <div className='header-container'>
        <h1 className='header'>Welcome to Neoboard!</h1>
      </div>
      <p className='information'>
        The study platform designed by students for students, swipe left to
        continue 👈
      </p>
    </Fragment>
  );
}

export default Tutorials;
