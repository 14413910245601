import React from "react";
import "./Section5.css";

function Section5Stu() {
  return (
    <div className="section5-body">
      <div className="row">
        <div className="col-md-1"></div>
        <div className="col-md-10">
          <h1 className="section5-title">Why your classmates love Neoboard</h1>
        </div>
        <div className="col-md-1"></div>
      </div>

      <div className="row">
        <div className="col-md-2"></div>
        {/* Card row */}
        <div className="col-md-8">
          <div className="row stu-card-container">
            <div className="col-md-4">
              <div className="student-card border">
                {/* Icon row */}
                <div className="row">
                  <div className="col-md-4"></div>
                  <div className="col-md-4 section5-icon-container">
                    <img
                      src={require("../../assets/3/silvia-snow@3x.png")}
                      alt="download icon"
                      className="section5-icon-img"
                    ></img>
                  </div>
                  <div className="col-md-4"></div>
                </div>

                <div className="row">
                  <div className="col-md-12">
                    {/* This is a student quote */}
                    <p className="stu-quote">
                    As a commuter, I appreciate a way to study from home. Using Neoboard I stay connected with my friends working on assignments from the comfort of home!
                    </p>
                  </div>
                </div>

                {/* Student Name and city section */}
                <div className="parent-container">
                  <div className="name-container">
                    <div className="row">
                      <div className="col-md-12">
                        {/* This is the student name and location */}
                        <p className="stu-name h6">Sylvia Snow</p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <p className="stu-location h6">Irvine, CA</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="student-card border">
                {/* Icon row */}
                <div className="row">
                  <div className="col-md-4"></div>
                  <div className="col-md-4 section5-icon-container">
                    <img
                      src={require("../../assets/3/jeremy-sladen@3x.png")}
                      alt="download icon"
                      className="section5-icon-img"
                    ></img>
                  </div>
                  <div className="col-md-4"></div>
                </div>

                <div className="row">
                  <div className="col-md-12">
                    {/* This is a student quote */}
                    <p className="stu-quote">
                      This is the best way to have everyone work together to solve problems, this app has the entire class become a team that helps one another out. This made all the difference between aceing and dropping the course!
                    </p>
                  </div>
                </div>
                {/* Student Name and city section */}
                <div className="parent-container">
                  <div className="name-container">
                    <div className="row">
                      <div className="col-md-12">
                        {/* This is the student name and location */}
                        <p className="stu-name h6">Jeremy Sladen</p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <p className="stu-location h6">Corona, CA</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="student-card border">
                {/* Icon row */}
                <div className="row">
                  <div className="col-md-4"></div>
                  <div className="col-md-4 section5-icon-container">
                    <img
                      src={require("../../assets/3/ashley-harris@3x.png")}
                      alt="download icon"
                      className="section5-icon-img"
                    ></img>
                  </div>
                  <div className="col-md-4"></div>
                </div>

                <div className="row">
                  <div className="col-md-12">
                    {/* This is a student quote */}
                    <p className="stu-quote">
                      I studied on my own before Neoboard becuase it was easier than coordinating schedules. It is an incredible feeling to know you have a study group you can rely on to meet up each week with no scheduling conflicts!
                    </p>
                  </div>
                </div>
                {/* Student Name and city section */}
                <div className="parent-container">
                  <div className="name-container">
                    <div className="row">
                      <div className="col-md-12">
                        {/* This is the student name and location */}
                        <p className="stu-name h6">Ashley Harris</p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <p className="stu-location h6">Riverside, CA</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-2"></div>
      </div>
    </div>
  );
}

export default Section5Stu;
