import React from "react";
import "./Team.css";
// import Mailto from "reactv16-mailto";
// import { Link } from "react-router-dom";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import Card from "react-bootstrap/Card";

import Gavin from "../../assets/Gavin.jpg";

let mq = window.matchMedia("(max-width: 1000px) and (min-device-width: 718px)");

class Team extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    if (mq.matches) {
      return (
        <div className="team-body blur-box">
          <div className="row">
            <div className="col-md-1"></div>
            <div className="col-md-10">
              <div className="border team-card">
                {" "}
                <div className="row">
                  <div className="col-md-12">
                    <div className="team-title h1">Meet the Neoboard Team</div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="tabs">
                      <Tabs
                        defaultActiveKey="current"
                        id="uncontrolled-tab-example"
                      >
                        <Tab eventKey="past" title="Past Team"></Tab>
                        <Tab eventKey="current" title="Current Team">
                          {/* Start Team Card Horizontal */}
                          <div className="row">
                            <div className="team-card-hz">
                              <div className="team-card-hz-left">
                                <Card.Img
                                  variant="top"
                                  src="https://ca.slack-edge.com/T6CJZ9YTU-U6CLPHHNG-c986593ca5e1-512"
                                />
                              </div>
                              <div className="team-card-hz-right">
                                <Card.Body className="card-body-hz">
                                  <Card.Title>Kevin Osorno</Card.Title>
                                  <div className="h6 job-title">
                                    CEO & Founder
                                  </div>
                                  <Card.Text className="card-text-hz">
                                    Empowering students to succeed in their
                                    online class through building a school-wide
                                    learning community!
                                  </Card.Text>
                                  <div className="socials">
                                    <a
                                      href="https://www.linkedin.com/in/the-educator/"
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      <i class="fab fa-linkedin fa-gradient"></i>
                                    </a>
                                  </div>
                                </Card.Body>
                              </div>
                            </div>
                          </div>
                          {/* End Team Card Horizontal */}
                          <br />
                          {/* Start Team Card Horizontal */}
                          <div className="row">
                            <div className="team-card-hz">
                              <div className="team-card-hz-left">
                                <Card.Img
                                  variant="top"
                                  src="https://ca.slack-edge.com/T6CJZ9YTU-UU4F5M9EJ-6e632b4edc3f-512"
                                />
                              </div>
                              <div className="team-card-hz-right">
                                <Card.Body className="card-body-hz">
                                  <Card.Title>Phil Laub</Card.Title>
                                  <div className="h6 job-title">
                                    Front End Developer
                                  </div>
                                  <Card.Text className="card-text-hz">
                                    Background in electronics and business
                                    management, utilizing skills to build a
                                    responsive and friendly user experience.
                                  </Card.Text>
                                  <div className="socials">
                                    <a
                                      href="https://www.facebook.com/phil.laub/"
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      <i class="fab fa-facebook-square fa-gradient"></i>
                                    </a>
                                    <a
                                      href="https://www.linkedin.com/in/phillip-laub/"
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      <i class="fab fa-linkedin fa-gradient"></i>
                                    </a>
                                    <a
                                      href="https://twitter.com/LaubPhil"
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      <i class="fab fa-twitter-square fa-gradient"></i>
                                    </a>
                                  </div>
                                </Card.Body>
                              </div>
                            </div>
                          </div>
                          {/* End Team Card Horizontal */}
                          <br />
                          {/* Start Team Card Horizontal */}
                          <div className="row">
                            <div className="team-card-hz">
                              <div className="team-card-hz-left">
                                <Card.Img
                                  variant="top"
                                  src="https://ca.slack-edge.com/T6CJZ9YTU-UUCM8U6HF-2b6dc5703982-512"
                                />
                              </div>
                              <div className="team-card-hz-right">
                                <Card.Body className="card-body-hz">
                                  <Card.Title>Thomas Nelson</Card.Title>
                                  <div className="h6 job-title">
                                    Front End Developer
                                  </div>
                                  <Card.Text className="card-text-hz">
                                    Front-End Web Developer specializing in
                                    JavaScript, React, and Sass with attention
                                    to responsive design.
                                  </Card.Text>
                                  <div className="socials">
                                    <a
                                      href="https://www.linkedin.com/in/thnlsn/"
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      <i class="fab fa-linkedin fa-gradient"></i>
                                    </a>
                                  </div>
                                </Card.Body>
                              </div>
                            </div>
                          </div>
                          {/* End Team Card Horizontal */}
                          <br />
                          {/* Start Team Card Horizontal */}
                          <div className="row">
                            <div className="team-card-hz">
                              <div className="team-card-hz-left">
                                <Card.Img variant="top" src={Gavin} />
                              </div>
                              <div className="team-card-hz-right">
                                <Card.Body className="card-body-hz">
                                  <Card.Title>Gavin Dent</Card.Title>
                                  <div className="h6 job-title">
                                    BackEnd Developer
                                  </div>
                                  <Card.Text className="card-text-hz">
                                    Software Developer with experience in
                                    computer network development, focused on
                                    building a smooth and safe space for user
                                    data.
                                  </Card.Text>
                                  <div className="socials">
                                    <a
                                      href="https://www.linkedin.com/in/gavin-dent/"
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      <i class="fab fa-linkedin fa-gradient"></i>
                                    </a>
                                  </div>
                                </Card.Body>
                              </div>
                            </div>
                          </div>
                          {/* End Team Card Horizontal */}
                        </Tab>

                        <Tab eventKey="advisors" title="Advisors"></Tab>
                      </Tabs>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-1"></div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="team-body blur-box">
          <div className="row">
            <div className="col-md-1"></div>
            <div className="col-md-10">
              <div className="border team-card">
                {" "}
                <div className="row">
                  <div className="col-md-12">
                    <div className="team-title h1">Meet the Neoboard Team</div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="tabs">
                      <Tabs
                        defaultActiveKey="current"
                        id="uncontrolled-tab-example"
                      >
                        <Tab eventKey="past" title="Past Team"></Tab>
                        <Tab eventKey="current" title="Current Team">
                          {/* Team Member Card Start */}
                          <Card className="team-member">
                            <Card.Img
                              variant="top"
                              src="https://ca.slack-edge.com/T6CJZ9YTU-U6CLPHHNG-c986593ca5e1-512"
                            />
                            <Card.Body>
                              <Card.Title>Kevin Osorno</Card.Title>
                              <div className="h6">CEO & Founder</div>
                              <Card.Text>
                                Empowering students to succeed in their online
                                class through building a school-wide learning
                                community!
                              </Card.Text>
                            </Card.Body>
                            <div className="socials">
                              {/* <a
                            href="#"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <i class="fab fa-facebook-square fa-gradient"></i>
                          </a> */}
                              <a
                                href="https://www.linkedin.com/in/the-educator/"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <i class="fab fa-linkedin fa-gradient"></i>
                              </a>
                              {/* <a
                            href="#"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <i class="fab fa-twitter-square fa-gradient"></i>
                          </a> */}
                            </div>
                          </Card>
                          {/* Team Member Card End */}
                          {/* Team Member Card Start */}
                          <Card className="team-member">
                            <Card.Img
                              variant="top"
                              src="https://ca.slack-edge.com/T6CJZ9YTU-UU4F5M9EJ-6e632b4edc3f-512"
                            />
                            <Card.Body>
                              <Card.Title>Phil Laub</Card.Title>
                              <div className="h6">Front End Developer</div>
                              <Card.Text>
                                Background in electronics and business
                                management, utilizing skills to build a
                                responsive and friendly user experience.
                              </Card.Text>
                            </Card.Body>
                            <div className="socials">
                              <a
                                href="https://www.facebook.com/phil.laub/"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <i class="fab fa-facebook-square fa-gradient"></i>
                              </a>
                              <a
                                href="https://www.linkedin.com/in/phillip-laub/"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <i class="fab fa-linkedin fa-gradient"></i>
                              </a>
                              <a
                                href="https://twitter.com/LaubPhil"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <i class="fab fa-twitter-square fa-gradient"></i>
                              </a>
                            </div>
                          </Card>
                          {/* Team Member Card End */}
                          {/* Team Member Card Start */}
                          <Card className="team-member">
                            <Card.Img
                              variant="top"
                              src="https://ca.slack-edge.com/T6CJZ9YTU-UUCM8U6HF-2b6dc5703982-512"
                            />
                            <Card.Body>
                              <Card.Title>Thomas Nelson</Card.Title>
                              <div className="h6">Front End Developer</div>
                              <Card.Text>
                                Front-End Web Developer specializing in
                                JavaScript, React, and Sass with attention to
                                responsive design.
                              </Card.Text>
                            </Card.Body>
                            <div className="socials">
                              {/* <a
                            href="#"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <i class="fab fa-facebook-square fa-gradient"></i>
                          </a> */}
                              <a
                                href="https://www.linkedin.com/in/thnlsn/"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <i class="fab fa-linkedin fa-gradient"></i>
                              </a>
                              {/* <a
                            href="#"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <i class="fab fa-twitter-square fa-gradient"></i>
                          </a> */}
                            </div>
                          </Card>
                          {/* Team Member Card End */}

                          {/* Team Member Card Start */}
                          <Card className="team-member">
                            <Card.Img variant="top" src={Gavin} />
                            <Card.Body>
                              <Card.Title>Gavin Dent</Card.Title>
                              <div className="h6">BackEnd Developer</div>
                              <Card.Text>
                                Software Developer with experience in computer
                                network development, focused on building a
                                smooth and safe space for user data.
                              </Card.Text>
                            </Card.Body>
                            <div className="socials">
                              {/* <a
                            href="#"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <i class="fab fa-facebook-square fa-gradient"></i>
                          </a> */}
                              <a
                                href="https://www.linkedin.com/in/gavin-dent/"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <i class="fab fa-linkedin fa-gradient"></i>
                              </a>
                              {/* <a
                            href="#"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <i class="fab fa-twitter-square fa-gradient"></i>
                          </a> */}
                            </div>
                          </Card>
                          {/* Team Member Card End */}
                        </Tab>

                        <Tab eventKey="advisors" title="Advisors"></Tab>
                      </Tabs>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-1"></div>
          </div>
        </div>
      );
    }
  }
}

export default Team;
