const chat = [
  {
    image: require('./images/users/arjun.jpg'),
    username: 'Arjun',
    reactions: {
      thumbsUp: 0,
      handPaper: 4,
      check: 0,
      question: 0,
      angry: 0,
      flag: 0,
      wrench: false,
      checkDouble: false,
      link: 0,
    },
    comment:
      'So Warner, does the McLaurin series for sin(x) have the signs alternating?',
    timestamp: 'Yesterday 7:46 PM',
    id: 0,
  },
  {
    image: require('./images/users/warner.jpg'),
    username: 'Warner',
    reactions: {
      thumbsUp: 2,
      handPaper: 0,
      check: 0,
      question: 0,
      angry: 0,
      flag: 0,
      wrench: false,
      checkDouble: false,
      link: 3,
    },
    comment:
      'The notes from his lecture slides shows that the signs alternate for this series.',
    timestamp: '8:02 PM',
    id: 1,
  },
  {
    image: require('./images/users/cindy.jpg'),
    username: 'Cindy L.',
    reactions: {
      thumbsUp: 0,
      handPaper: 0,
      check: 0,
      question: 0,
      angry: 0,
      flag: 0,
      wrench: false,
      checkDouble: false,
      link: 0,
    },
    comment:
      'Thanks Warner, I did not realize the notes were posted online. I wish the professor would inform us if the notes will be placed online.',
    timestamp: '11:08 PM',
    id: 2,
  },
  {
    image: require('./images/users/lebaron.jpg'),
    username: 'Lebaron',
    reactions: {
      thumbsUp: 0,
      handPaper: 9,
      check: 0,
      question: 0,
      angry: 3,
      flag: 0,
      wrench: false,
      checkDouble: true,
      link: 0,
    },
    comment:
      "Is anyone else having problems accessing the online lab for Thursday's assignment?",
    timestamp: '10:12 AM',
    id: 3,
  },
  {
    image: require('./images/users/arjun.jpg'),
    username: 'Arjun',
    reactions: {
      thumbsUp: 0,
      handPaper: 0,
      check: 0,
      question: 0,
      angry: 0,
      flag: 0,
      wrench: false,
      checkDouble: false,
      link: 0,
    },
    comment:
      'The professor did not address it in class today Lebaron, but it seems the server is down today for the entire school. Any announcements on when things will be restored?',
    timestamp: '10:30 AM',
    id: 4,
  },
  {
    image: require('./images/users/itzel.jpg'),
    username: 'Itzel',
    reactions: {
      thumbsUp: 0,
      handPaper: 0,
      check: 0,
      question: 0,
      angry: 0,
      flag: 0,
      wrench: false,
      checkDouble: false,
      link: 0,
    },
    comment:
      'No word on when the server will be up, I just messaged the professor about it. Has anyone begun problem #4 from the homework? If anyone plans on working on it today, please let me know, would like to put our heads together to get it done.',
    timestamp: ' 12:20 PM',
    doc:
      'https://docs.google.com/document/d/1yd6wsPKCyUTbDCPzxGt0RJRvgZEca1B3QqtW2K33-nc/edit',
    id: 5,
  },
  {
    image: require('./images/users/arjun.jpg'),
    username: 'Arjun',
    reactions: {
      thumbsUp: 0,
      handPaper: 0,
      check: 0,
      question: 1,
      angry: 0,
      flag: 0,
      wrench: false,
      checkDouble: false,
      link: 0,
    },
    comment:
      'So Warner, does the McLaurin series for sin(x) have the signs alternating?',
    timestamp: 'Yesterday 7:46 PM',
    quote: {
      user: 'Warner',
      time: '8:02 PM',
      comment:
        'The notes from his lecture slides shows that the signs alternate for this series.',
    },
    id: 6,
  },
  {
    image: require('./images/users/warner.jpg'),
    username: 'Warner',
    reactions: {
      thumbsUp: 0,
      handPaper: 0,
      check: 0,
      question: 0,
      angry: 0,
      flag: 0,
      wrench: false,
      checkDouble: false,
      link: 0,
    },
    comment:
      'The notes from his lecture slides shows that the signs alternate for this series.',
    timestamp: '8:02 PM',
    id: 7,
  },
  {
    image: require('./images/users/warner.jpg'),
    username: 'Warner',
    reactions: {
      thumbsUp: 14,
      handPaper: 0,
      check: 0,
      question: 0,
      angry: 0,
      flag: 0,
      wrench: false,
      checkDouble: false,
      link: 0,
    },
    comment:
      'By the way, I am starting to apply to some new jobs! If anyone could check out my resume and give me feedback that would be super helpful!',
    timestamp: '8:03 PM',
    pdf: './test.pdf',
    id: 8,
  },
  {
    image: require('./images/users/cindy.jpg'),
    username: 'Cindy L.',
    reactions: {
      thumbsUp: 0,
      handPaper: 0,
      check: 0,
      question: 0,
      angry: 0,
      flag: 0,
      wrench: false,
      checkDouble: false,
      link: 0,
    },
    comment:
      'Thanks Warner, I did not realize the notes were posted online. I wish the professor would inform us if the notes will be placed online.',
    timestamp: '11:08 PM',
    id: 9,
  },
  {
    image: require('./images/users/lebaron.jpg'),
    username: 'Lebaron',
    reactions: {
      thumbsUp: 0,
      handPaper: 0,
      check: 0,
      question: 0,
      angry: 0,
      flag: 0,
      wrench: false,
      checkDouble: false,
      link: 0,
    },
    comment:
      "Is anyone else having problems accessing the online lab for Thursday's assignment?",
    timestamp: '10:12 AM',
    id: 10,
  },
  {
    image: require('./images/users/arjun.jpg'),
    username: 'Arjun',
    reactions: {
      thumbsUp: 0,
      handPaper: 0,
      check: 0,
      question: 0,
      angry: 0,
      flag: 0,
      wrench: false,
      checkDouble: false,
      link: 0,
    },
    comment:
      'The professor did not address it in class today Lebaron, but it seems the server is down today for the entire school. Any announcements on when things will be restored?',
    timestamp: '10:30 AM',
    id: 11,
  },
  {
    image: require('./images/users/itzel.jpg'),
    username: 'Itzel',
    reactions: {
      thumbsUp: 100,
      handPaper: 1,
      check: 1,
      question: 74,
      angry: 1,
      flag: 1,
      wrench: false,
      checkDouble: false,
      link: 0,
    },
    comment:
      'No word on when the server will be up, I just messaged the professor about it. Has anyone begun problem #4 from the homework? If anyone plans on working on it today, please let me know, would like to put our heads together to get it done.',
    timestamp: ' 12:20 PM',
    id: 12,
  },
  {
    image: require('./images/users/lebaron.jpg'),
    username: 'Lebaron',
    reactions: {
      thumbsUp: 0,
      handPaper: 0,
      check: 0,
      question: 3,
      angry: 0,
      flag: 0,
      wrench: false,
      checkDouble: false,
      link: 0,
    },
    comment:
      "Is anyone else struggling on question 5? Here it is if you haven't checked it out yet: \\[ \\int_0^1 \\frac{dx}{e^x} =  \\frac{e-1}{e} \\]",
    timestamp: ' 13:30 PM',
    id: 13,
  },
  {
    image: require('./images/users/arjun.jpg'),
    username: 'Arjun',
    reactions: {
      thumbsUp: 0,
      handPaper: 1,
      check: 0,
      question: 0,
      angry: 0,
      flag: 0,
      wrench: false,
      checkDouble: false,
      link: 0,
    },
    comment:
      "I haven't attempted that one yet, but did you happen to solve this one? Here it is: $$(3\\times 4) \\div (5-3)$$",
    timestamp: '13:35 PM',
    id: 14,
  },
];

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const chatTwo = [
  {
    image: require('./images/users/arjun.jpg'),
    username: 'Arjun',
    reactions: {
      thumbsUp: 0,
      handPaper: 1,
      check: 0,
      question: 0,
      angry: 0,
      flag: 0,
      wrench: false,
      checkDouble: false,
      link: 0,
    },
    comment:
      'Did anyone get $\\frac{1}{cos(\\frac{x}{2})}$ for the answer to #3?  When I plugged in 0 for $x$ I got $1$ for the answer and $2$ for $x=\\frac{pi}{6}$.',
    timestamp: 'Yesterday 7:46 PM',
    id: 0,
  },
  {
    image: require('./images/users/warner.jpg'),
    username: 'Warner',
    reactions: {
      thumbsUp: 0,
      handPaper: 0,
      check: 0,
      question: 0,
      angry: 0,
      flag: 0,
      wrench: false,
      checkDouble: false,
      link: 0,
    },
    comment:
      'The answer I found online said $y=3.87*10^9$ but in the textbook it says $y=3.87*10^{-7}$, did anyone find another answer?  Sorry, I meant $y =3.87*10^{-9}$',
    timestamp: '8:02 PM',
    id: 1,
  },
  {
    image: require('./images/users/cindy.jpg'),
    username: 'Cindy L.',
    reactions: {
      thumbsUp: 0,
      handPaper: 0,
      check: 0,
      question: 0,
      angry: 0,
      flag: 0,
      wrench: false,
      checkDouble: false,
      link: 0,
    },
    comment:
      'The equation for mass energy equivalence is $\\begin{equation} E = mc^2 end{equation}$.  This is why fission bombs release so much energy, because of the c^2 term  ',
    timestamp: '11:08 PM',
    id: 2,
  },
  {
    image: require('./images/users/lebaron.jpg'),
    username: 'Lebaron',
    reactions: {
      thumbsUp: 0,
      handPaper: 0,
      check: 0,
      question: 0,
      angry: 0,
      flag: 0,
      wrench: false,
      checkDouble: false,
      link: 0,
    },
    comment:
      'Sorry I did not see your equation, did you mean $E = mc^2$.  This is why fission bombs release so much energy, because of the c^2 term.  I do not think equations are parsed in this messaging.',
    timestamp: '10:12 AM',
    id: 3,
  },
  {
    image: require('./images/users/arjun.jpg'),
    username: 'Arjun',
    reactions: {
      thumbsUp: 0,
      handPaper: 0,
      check: 0,
      question: 0,
      angry: 0,
      flag: 0,
      wrench: false,
      checkDouble: false,
      link: 0,
    },
    comment:
      'Just for the sake of testing, here are the functional types we can use in messaging: $\\binom{n}{k}$, $ \\forall$, $\\bigoplus$, $geq$, $Exp$, $ell$, $\\notin$, $zo$, and -Omega(c^2 k)$.  Try this too: $ sum_{i=1}^infty \\frac{1}{2^i} = 1$',
    timestamp: '10:30 AM',
    id: 4,
  },
  {
    image: require('./images/users/itzel.jpg'),
    username: 'Itzel',
    reactions: {
      thumbsUp: 0,
      handPaper: 0,
      check: 0,
      question: 0,
      angry: 0,
      flag: 0,
      wrench: false,
      checkDouble: false,
      link: 0,
    },
    comment:
      '$\\binom{n}{k}$, $ \\forall$, $\\bigoplus$, $geq$, $Exp$, $ell$, $\\notin$, $zo$, and -Omega(c^2 k)$.  Try this too: $ sum_{i=1}^infty \\frac{1}{2^i} = 1$',
    timestamp: ' 12:20 PM',
    id: 5,
  },
];

const otherUserData = [
  {
    reactionsLeft: '',
    reactionsRight: '',
    image: require('./images/users/warner.jpg'),
    name: 'Warner Brown',
    username: 'DrFreeze459',
    joinDate: 'February 1st, 2020',
    college: 'James Webb College',
    major: 'Cellular Biology',
    grade: 'Junior',
    city: 'Franklin, ST',
  },
];

const privateUserData = [
  {
    image: require('./images/users/itzel.jpg'),
    firstName: 'Itzel',
    lastName: 'Lopez',
    joinDate: 'February 1st, 2020',
    college: 'James Webb College',
  },
];

const userData = [
  {
    reactionsLeft: '',
    reactionsRight: '',
    image: require('./images/users/mira.jpg'),
    firstName: 'Mira',
    lastName: 'Moulin',
    username: 'MiraMajesty',
    joinDate: 'March 13th, 2020',
    college: 'James Webb College',
    major: 'Psychobiology',
    grade: 'Senior',
    city: 'Springfield, ST',
    calender: ['Google, ', 'Canvas'],
    schedule: [
      { class: 'Biology', days: 'MWF', time: '9:00 - 10:00 AM' },
      { class: 'Calculus AB', days: 'MWF', time: '2:00 - 3:00 PM' },
      { class: 'Biology', days: 'TR', time: '10:30 - 12:00 PM' },
    ],
    matchingPoll: 92,
    privacySettings: '',
  },
];

const allClassrooms = [
  { name: 'Biology 110', icon: 'fas fa-leaf', color: 'primary' },
  { name: 'Calculus AB', icon: 'fas fa-calculator', color: 'primary' },
  { name: 'British Literature', icon: 'fas fa-book', color: 'primary' },
  { name: 'Campus', icon: 'fas fa-graduation-cap', color: 'secondary' },
];

const allChannels = [
  {
    channel: 'Biology 110',
    name: 'Biology Class',
    notifications: { green: 7, yellow: 0, red: false },
    // icons: {},
  },
  {
    channel: 'Biology 110',
    name: 'Study Group 11',
    notifications: { green: 0, yellow: 1, red: false },
    // icons: {},
  },
  {
    channel: 'Biology 110',
    name: 'SI Evaluation',
    notifications: { green: 0, yellow: 0, red: true },
    // icons: {},
  },
  {
    channel: 'Calculus AB',
    name: 'Calculus Class',
    notifications: { green: 2, yellow: 0, red: false },
    // icons: {},
  },
  {
    channel: 'Calculus AB',
    name: 'Study Group 3',
    notifications: { green: 0, yellow: 0, red: false },
    // icons: {},
  },
  {
    channel: 'Calculus AB',
    name: 'SI Evaluation',
    notifications: { green: 0, yellow: 0, red: false },
    // icons: {},
  },
  {
    channel: 'British Literature',
    name: 'British Literature Class',
    notifications: { green: 1, yellow: 0, red: false },
    // icons: {},
  },
  {
    channel: 'British Literature',
    name: 'Study Group 2',
    notifications: { green: 0, yellow: 1, red: false },
    // icons: {},
  },
  {
    channel: 'British Literature',
    name: 'SI Evaluation',
    notifications: { green: 0, yellow: 0, red: false },
    // icons: {},
  },
  {
    channel: 'Campus',
    name: 'General',
    notifications: { green: 31, yellow: 0, red: false },
    // icons: {},
  },
  {
    channel: 'Campus',
    name: 'Help',
    notifications: { green: 11, yellow: 0, red: false },
    // icons: {},
  },
  {
    channel: 'Campus',
    name: 'Q & A',
    notifications: { green: 0, yellow: 0, red: false },
    // icons: {},
  },
  {
    channel: 'Campus',
    name: 'Counselors',
    notifications: { green: 2, yellow: 1, red: false },
    // icons: {},
  },
  {
    channel: 'Campus',
    name: 'Clubs & Organizations',
    notifications: { green: 0, yellow: 0, red: false },
    // icons: {},
  },
  {
    channel: 'Campus',
    name: 'Tutoring',
    notifications: { green: 0, yellow: 0, red: false },
    // icons: {},
  },
  {
    channel: 'Campus',
    name: 'Events',
    notifications: { green: 0, yellow: 0, red: false },
    // icons: {},
  },
  {
    channel: 'Campus',
    name: 'Networking',
    notifications: { green: 0, yellow: 0, red: false },
    // icons: {},
  },
  {
    channel: 'Campus',
    name: 'News',
    notifications: { green: 0, yellow: 0, red: false },
    // icons: {},
  },
  {
    channel: 'Campus',
    name: 'Careers',
    notifications: { green: 0, yellow: 0, red: false },
    // icons: {},
  },
  {
    channel: 'Campus',
    name: 'Job Postings',
    notifications: { green: 0, yellow: 0, red: false },
    // icons: {},
  },
  {
    channel: 'Campus',
    name: 'Study Room Vacancies',
    notifications: { green: 0, yellow: 0, red: true },
    // icons: {},
  },
  {
    channel: 'Campus',
    name: 'Technical Support',
    notifications: { green: 0, yellow: 0, red: false },
    // icons: {},
  },
  {
    channel: 'Campus',
    name: 'Movie Screenings',
    notifications: { green: 0, yellow: 0, red: false },
    // icons: {},
  },
  {
    channel: 'Campus',
    name: 'Chitchat',
    notifications: { green: 0, yellow: 0, red: false },
    // icons: {},
  },
  {
    channel: 'Campus',
    name: 'Off-Topic',
    notifications: { green: 9, yellow: 0, red: false },
    // icons: {},
  },
];

export {
  chat,
  chatTwo,
  otherUserData,
  privateUserData,
  userData,
  allClassrooms,
  allChannels,
};
