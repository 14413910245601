import React from 'react';
import '../Canvas.css';

import { Link } from 'react-router-dom';

const CanvasCompletePage = () => {
  return (
    <div className='canvas-container container-padding'>
      <div className='welcome-box top-border login-box'>
        <div className='heading'>
          <div className='neoboard-logo double-margin-buffer'></div>
          <div className='canvas-logo double-margin-buffer' />
        </div>
        <div className='description'>
          Upload complete, your students will now be emailed their poll
          questions momentarily. Groups will be assigned 7 days from today based
          on ther responses.
        </div>
        <div className='permission-submit double-margin-buffer unselectable'>
          Another Upload
        </div>
      </div>
    </div>
  );
};

export default CanvasCompletePage;
