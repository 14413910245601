import React from "react";

import "./SessionItinerary.css";
import ItinerarySection from "./ItinerarySection";
function SessionItinerary() {
  return (
    <div className="sessionItinerary-body blur-box">
      <div className="row">
        <div className="col-md-1"></div>
        <div className="col-md-10">
          <div className="row">
            <div className="col-md-4"></div>
            <div className="col-md-4 h1">Session Itinerary</div>
            <div className="col-md-4"></div>
          </div>
          {/* <div className="itinerary-section">
            <div className="row">
              <div className="col-md-4">Topics / Material Covered</div>
              <div className="col-md-4">Session Plan</div>
              <div className="col-md-4">Session Goal</div>
            </div>
          </div> */}
          <ItinerarySection />
        </div>
        <div className="col-md-1"></div>
      </div>
    </div>
  );
}

export default SessionItinerary;
