import React from "react";

class Map extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      latitude: null,
      longitude: null,
    };

    this.getLocation = this.getLocation.bind(this);
    this.getCoordinates = this.getCoordinates.bind(this);
  }

  getLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        this.getCoordinates,
        this.handleLocationError
      );
    } else {
      alert("Geolocation is not supported by this browser.");
    }
  }

  getCoordinates(position) {
    this.setState({
      latitude: position.coords.latitude,
      longitude: position.coords.longitude,
    });
    this.sendData(this.state);
    console.log(this.state);
  }

  sendData = () => {
    this.props.parentCallback(this.state);
  };

  handleLocationError(error) {
    switch (error.code) {
      case error.PERMISSION_DENIED:
        alert("User denied the request for Geolocation.");
        break;
      case error.POSITION_UNAVAILABLE:
        alert("Location information is unavailable.");
        break;
      case error.TIMEOUT:
        alert("The request to get user location timed out.");
        break;
      case error.UNKNOWN_ERROR:
        alert("An unknown error occurred.");
        break;
      default:
        alert("An unknown error occurred.");
    }
  }

  render() {
    return (
      <div>
        {this.state.latitude && this.state.longitude ? (
          <div className="box">
            <img
              src={`https://maps.googleapis.com/maps/api/staticmap?center=${this.state.latitude},${this.state.longitude}&zoom=10&scale=1&size=600x300&maptype=roadmap&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`}
              alt=""
              style={{ width: "80%" }}
            />
            <div className="circle"></div>
          </div>
        ) : (
          <img
            src={
              "https://www.google.com/maps/vt/data=Py8f9EwHDjB8QjuVjDiRkIwz08WaBbNJH8Xgnln4ISuXOmK6uX683bgwboYnKO3A1EFeORAI7e7g5gsQ8Rsm5NqY2WbWq0eESR3Fu505dooSSKThRr9WNZmi-RuZo18amVjhnNcw3RzlTvvoRncaSGNGviSTbXroEozezL8wn9Mok9Hdn7xAwYyAAFjgJsJoJarUiIrygY5r9Xmjit-9tF0gmzPjxdb2EnmTujqJ-YYnz88"
            }
            alt=""
            style={{ width: "80%" }}
          />
        )}
        <br />
        <label>
          <input
            name="useLocation"
            type="checkbox"
            onClick={this.getLocation}
            className="useLocationBtn"
          />
          Use my approximate location for study groups
        </label>
      </div>
    );
  }
}

export default Map;
