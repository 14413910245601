import React, { Fragment, useState, useEffect, useCallback } from 'react';
import { useLongPress } from 'use-long-press';

// import { pdfjs } from 'react-pdf';
// import 'react-pdf/dist/esm/Page/AnnotationLayer.css';

import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';
import './messaging.css';

import Navbar from './components/Navigation/Navbar';
import Sidebar from './components/Navigation/Sidebar';
import Bar from './components/Messaging/MessagingBar';
import Chat from './components/Chat/Chat';
import Menu from './components/Chat/CommentMenu';
import Document from './components/Chat/CommentDocument';

import {
  chat,
  chatTwo,
  otherUserData,
  privateUserData,
  userData,
  allClassrooms,
  allChannels,
} from '../../dummyData/messagingDatabase';

const camelToKebab = (str) =>
  str
    .split(/(?=[A-Z])/)
    .map((s) => s.toLowerCase())
    .join('-');

const Messaging = () => {
  const instructorOrAdmin = true;

  const [sidebar, setSidebar] = useState(false); // Sidebar starts closed
  const [selectedChannel, setSelectedChannel] = useState(''); // No channel selected by default
  const [chatrooms, setChatrooms] = useState([]); // No channel selected by default
  const [selectedChatroom, setSelectedChatroom] = useState(); // No channel selected by default
  const [loading, setLoading] = useState(false); // No channel selected by default

  const [chatMenu, setChatMenu] = useState({
    visible: false,
    comment: '',
    reactions: [],
    user: '',
    time: '',
    document: null,
  });

  useEffect(() => {
    [
      document.querySelector('.neo-navbar'),
      document.querySelector('.footer-body'),
      document.querySelector('.footer2-body'),
    ].forEach((el) => (el.style.display = 'none'));
  }, []);

  const [documentFull, setDocumentFull] = useState(null);

  const [quote, setQuote] = useState(
    // If visible, put on top of messaging bar and move all comments up by that same height
    { visible: false, user: '', time: '', comment: '' }
  );

  const [darkMode, setDarkMode] = useState(false);

  // Taking in a string version of the database key, generate a fontawesome icon with data-attribute
  const renderReaction = (reaction) => {
    return (
      <i
        // Because database keys cant be kebab-case, first convert it to match the fontawesome class
        className={`fas fa-${camelToKebab(reaction)}`}
        data-reaction={reaction}
      ></i>
    );
  };

  const determineReactionType = (reaction) =>
    reaction === 'wrench' || reaction === 'checkDouble' || reaction === 'link'
      ? 'important'
      : 'normal';

  const closeChatMenu = () =>
    setChatMenu({
      visible: false,
      comment: '',
      reactions: [],
    });

  const filterChatrooms = (chatrooms, channel) => {
    // ###### In the future this will pull from the database all chatrooms associated with the channel clicked
    return chatrooms.filter((chatroom) => chatroom.channel === channel); // Pull all chatrooms that have the same channel value as the clicked channel
  };

  const toggleDarkMode = () => {
    setDarkMode(darkMode ? false : true);
    document.documentElement.setAttribute(
      'data-theme',
      darkMode ? 'light' : 'dark'
    );
    // Set preference in localStorage too
  };

  const handleBurgerClick = () => {
    console.log(allClassrooms);
    setSidebar(!sidebar); // Flip the boolean

    // Close chat menu if open
    closeChatMenu();

    // If there is not a channel selected at all, set the selectedChannel to the first one in the array of channels and set the chatrooms equal to those associated with that default selectedChannel
    if (!selectedChannel) {
      setSelectedChannel(allClassrooms[0].name);
      setChatrooms(filterChatrooms(allChannels, allClassrooms[0].name)); // Set state of chatrooms to the result of filtering
    }
  };

  const handleChannelClick = (e) => {
    // When we click on a channel...
    const channel = e.target.closest('.channel').dataset.channel; // Retrieve channel value of the clicked channel
    if (channel === selectedChannel) return; // Don't do anything if the same channel is clicked
    setSelectedChannel(channel);
    setSelectedChatroom(''); // ###### Bad solution!!!!!!!!!!! It make's it so browsing channels will always kick you out of your chatroom

    setChatrooms(filterChatrooms(allChannels, channel)); // Set state of chatrooms to the result of filtering
  };

  const handleChatroomClick = (e) => {
    // When we click on a channel...
    const chatroom = e.target.closest('.chatroom').dataset.chatroom; // Retrieve chatroom value of the clicked chatroom
    const channel = e.target.closest('.chatroom').dataset.channel; // Retrieve channel value of the clicked channel
    setSelectedChatroom(chatroom);
  };

  const addComment = () => {
    // Switch loading on and off
    // In the future this will set loading to true until POST request to add comment finishes, then it will be turned to false
    setLoading(loading ? false : true);

    // Remove quote on sending a message
    setQuote({
      visible: false,
      comment: '',
      reactions: [],
      user: '',
      time: '',
    });
  };
  const addReaction = () => {
    console.log(
      `Clicked the reaction. When a database is set up, this should post to this comment that the user who is logged in has added a reaction. It needs to have a reference that this user did so, because we do not want the same user to be able to add multiple of the same reaction. This background-color should also get darker to visualize that a reaction been given already. A click when a reaction is already given should instead remove the reference that this user has clicked this reaction on this comment, and thus removes the reaction and return the background-color back to the lighter color.`
    );
  };
  const handleUserClick = () => {
    console.log(
      `Clicked a user image/avatar. This should hit a GET request endpoint that will show all the users data and whatever information should be on the user profile screen, which will be different depending on their privacy settings`
    );
  };

  // LONG PRESS COMMENT
  const handleCommentPress = function (e) {
    // If the image or a reaction is clicked, don't do anything
    /*     if (
      e.target.closest('.comment__reaction') ||
      e.target.closest('.comment__image')
    )
      return; */
    // The parent comment instead of the specific element within the comment clicked
    const target = e.target.closest('.comment');
    const comment = target.querySelector('.comment__comment').textContent;
    const user = target.querySelector('.comment__heading').textContent;
    const time = target.querySelector('.comment__time').textContent;
    const document = target.querySelector('iframe');
    // Convert Nodelist of reactions into array and map only for reaction data attribute
    const currentReactions = [...target.querySelectorAll('i')].map(
      (reaction) => reaction.dataset.reaction
    );
    // Close sidebar if open
    setSidebar(false);
    setChatMenu({
      visible: !chatMenu.visible,
      comment,
      reactions: currentReactions,
      user,
      time,
      document,
    });
  };

  // HandleExportClick
  const copyToClipboard = (e) => {
    console.log(chatMenu.comment);
    console.log(chatMenu.comment);
    const el = document.createElement('textarea');
    el.value = chatMenu.comment;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
  };

  const handleCommentClick = function (e) {
    /*     e.target
      .closest('.comment')
      .querySelector('.comment__comment')
      .querySelector('span'); */
    console.log('Clicked, this should highlight the comment');
  };

  // Do handleCommentPress if it is a long press, but otherwise do handleCommentClick
  const handleCommentEvent = useLongPress(handleCommentPress, {
    onCancel: handleCommentClick,
    threshold: 350,
    captureEvent: true,
    detect: 'both',
  });

  const handleQuoteClick = function (e) {
    // Set quote menu state to these values held by the comment popup menu
    setQuote({
      visible: true,
      user: chatMenu.user,
      time: chatMenu.time,
      comment: chatMenu.comment,
      document: chatMenu.document,
    });
    // Close chat menu if open
    closeChatMenu();
  };

  const handleAnnotateClick = function (e) {
    // console.log(chatMenu.document);
    setDocumentFull(chatMenu.document);
    // Close chat menu if open
    closeChatMenu();
  };

  // Get height of quote to determine the padding bottom of chat content
  const getQuoteHeight = function (e) {
    const height = document.querySelector('.quote')?.offsetHeight;
    return height;
  };

  const handleDocumentClick = function (e) {
    setDocumentFull(null);
  };

  return (
    <div className={`Messaging`}>
      <Router>
        <Navbar
          notifications={0}
          handleBurgerClick={handleBurgerClick}
          toggleDarkMode={toggleDarkMode}
        />
        <Sidebar
          open={sidebar}
          channels={allClassrooms}
          selectedChannel={selectedChannel}
          chatrooms={chatrooms}
          selectedChatroom={selectedChatroom}
          handleChannelClick={handleChannelClick}
          handleChatroomClick={handleChatroomClick}
        />
        <main className='content'>
          {/* <Switch></Switch> */}
          <Chat
            chatroom={selectedChatroom}
            notifications='8'
            chatHistory={chat}
            addReaction={addReaction}
            handleUserClick={handleUserClick}
            renderReaction={renderReaction}
            determineReactionType={determineReactionType}
            handleCommentEvent={handleCommentEvent}
            getQuoteHeight={getQuoteHeight}
          />
        </main>
        <Bar
          selectedChatroom={selectedChatroom}
          loading={loading}
          addComment={addComment}
          // Quote
          quote={quote}
        />
        <Menu
          instructorOrAdmin={instructorOrAdmin}
          visible={chatMenu.visible}
          reactions={chatMenu.reactions}
          closeChatMenu={closeChatMenu}
          renderReaction={renderReaction}
          determineReactionType={determineReactionType}
          copyToClipboard={copyToClipboard}
          handleQuoteClick={handleQuoteClick}
          handleAnnotateClick={handleAnnotateClick}
          document={chatMenu.document}
        />
        {documentFull && (
          <Document
            document={documentFull}
            handleDocumentClick={handleDocumentClick}
          />
        )}
      </Router>
    </div>
  );
};

export default Messaging;
