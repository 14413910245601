import React from "react";
import "./Register.css";

function Register() {
  return (
    <div className="register-body">
      <div className="login-body">
        <div className="row">
          <div className="col-md-4"></div>
          <div className="col-md-4">
            <div className="login-card border">
              {/* Icon row */}
              <div className="row">
                <div className="col-md-4"></div>
                <div className="col-md-4 section5-icon-container">
                  <img
                    src={require("../../assets/3/Neo_logo.png")}
                    alt="download icon"
                    className="login-icon-img"
                  ></img>
                </div>
                <div className="col-md-4"></div>
              </div>

              <div className="row">
                <div className="col-md-12">
                  <div className="login-title">
                    <h1>Enter your Email and Sync your Calendar</h1>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-12">
                  <div className="stu-quote">
                    <form>
                      <div className="form-group">
                        <input
                          type="email"
                          className="form-control"
                          id="exampleInputEmail1"
                          placeholder="Email"
                        />
                      </div>

                      <button type="submit" className="btn login-btn">
                        Submit
                      </button>
                    </form>
                  </div>

                  <div className="row">
                    <div className="col-md-12">
                      <h4 className="link-title">Sync your Calendar</h4>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                        <button>
                      <div className="logo-container">
                        <img
                          src={require("../../assets/3/demo images/apple.png")}
                          alt="apple icon"
                          className="login-icon-img"
                        ></img>
                      </div>
                      </button>
                    </div>
                    <div className="col-md-6">
                        <button>
                      <div className="logo-container">
                        <img
                          src={require("../../assets/3/demo images/samsung.png")}
                          alt="samsung icon"
                          className="login-icon-img"
                        ></img>
                      </div>
                      </button>
                    </div>
                  </div>
                  <br />
                  <div className="row">
                    <div className="col-md-6">
                        <button>
                      <div className="logo-container">
                        <img
                          src={require("../../assets/3/demo images/google.png")}
                          alt="google icon"
                          className="login-icon-img"
                        ></img>
                      </div>
                      </button>
                    </div>
                    <div className="col-md-6">
                        <button>
                      <div className="logo-container">
                        <img
                          src={require("../../assets/3/demo images/canvas.png")}
                          alt="canvas icon"
                          className="login-icon-img"
                        ></img>
                      </div>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-4"></div>
        </div>
      </div>
    </div>
  );
}

export default Register;
